import {
	createStyles,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Theme,
} from "@material-ui/core";
import React from "react";

export type ColumnHeaderProps = {
	label: string;
	name: string;
	[propName: string]: any;
};
export type DataTableSort = { columnName: string; order: "asc" | "desc" };
export interface DataTableProps {
	columns?: ColumnHeaderProps[];
	collapsible?: boolean | undefined;
	variant?: "table" | "condensed";
	sort?: DataTableSort;
	sortBy?: (sort: DataTableSort) => void;
	[propName: string]: any;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderCollapse: "separate",
			borderSpacing: theme.spacing(0, 2),
		},
		tableContainer: {
			height: "100%",
		},
		row: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			borderRadius: theme.shape.borderRadius,
			marginBottom: theme.spacing(2),
			"& td, & th": {
				backgroundColor: theme.palette.primary.main,
				border: "none",
				padding: theme.spacing(1),
				// "&:first-child": {
				// 	borderTopLeftRadius: theme.shape.borderRadius,
				// 	borderBottomLeftRadius: theme.shape.borderRadius,
				// },
				// "&:last-child": {
				// 	borderBottomRightRadius: theme.shape.borderRadius,
				// 	borderTopRightRadius: theme.shape.borderRadius,
				// },
			},
			"& th": {
				"&:first-child": {
					paddingLeft: theme.spacing(2),
				},
				"&:last-child": {
					paddingRight: theme.spacing(2),
				},
			},
		},
		visuallyHidden: {
			border: 0,
			clip: "rect(0 0 0 0)",
			height: 1,
			margin: -1,
			overflow: "hidden",
			padding: 0,
			position: "absolute",
			top: 20,
			width: 1,
		},
	})
);
const DataTable = (props: DataTableProps) => {
	const {
		columns,
		collapsible,
		variant = "table",
		sort,
		sortBy,
		children,
		...other
	} = props;
	const classes = useStyles();

	const handleSortLabelClick = (column: ColumnHeaderProps) => {
		if (sortBy) {
			const oppositeDirection = sort?.order === "desc" ? "asc" : "desc";
			const sortDirection =
				sort?.columnName === column.name ? oppositeDirection : "desc";
			sortBy({ columnName: column.name, order: sortDirection });
		}
	};

	return (
		<TableContainer className={classes.tableContainer}>
			<Table
				{...other}
				className={classes.root}
				size={(variant === "condensed" && "small") || undefined}
				stickyHeader
			>
				<TableHead>
					<TableRow className={classes.row}>
						{collapsible && <TableCell />}
						{columns?.map((column, index) => (
							<TableCell
								key={index}
								{...column}
								sortDirection={
									sort?.columnName === column.name
										? sort?.order
										: false
								}
							>
								<TableSortLabel
									active={sort?.columnName === column.name}
									direction={
										sort?.columnName === column.name
											? sort?.order
											: "desc"
									}
									onClick={() => handleSortLabelClick(column)}
								>
									{column.label}
									{sort?.columnName === column.name ? (
										<span
											className={classes.visuallyHidden}
										>
											{sort?.order === "desc"
												? "sorted descending"
												: "sorted ascending"}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody className={classes.row}>{children}</TableBody>
			</Table>
		</TableContainer>
	);
};

export default DataTable;
