const MetricColumns: { [key: number]: { label: string; name: string }[] } = {
	4: [
		{
			label: "Survey Date",
			name: "survey_date",
		},
		{
			label: "Call Date Answered",
			name: "call_date_answered",
		},
		{
			label: "Survey Score",
			name: "survey_score",
		},
	],
	3: [
		{
			name: "CallDateAnswered",
			label: "Call Date",
		},
		{
			name: "SurveyDate",
			label: "Survey Date",
		},
		{
			name: "SurveyScore",
			label: "Score",
		},
	],
};
const ActionColumn: { label: string; name: string } = {
	label: "Actions",
	name: "",
};

export { MetricColumns, ActionColumn };
