import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useState } from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import AppealRecords from "../../tables/AppealRecords/AppealRecords";

const MaintainAppeals: React.FC = () => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(
		new Date(Date.now())
	);

	const handleDateChange = (date: MaterialUiPickersDate) => {
		setSelectedDate(date);
	};

	return (
		<SectionCard
			title={`Appeals`}
			secondaryAction={
				<DatePicker
					variant='inline'
					openTo='month'
					views={["year", "month"]}
					label='Month and Year'
					value={selectedDate}
					onChange={handleDateChange}
				/>
			}
			scrolling
			fullWidth
		>
			<AppealRecords month={selectedDate?.toDateString()} />
		</SectionCard>
	);
};

export default MaintainAppeals;
