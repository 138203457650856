import {
	Button,
	ButtonGroup,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import { Add, Delete, Edit, Save, SyncDisabled } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { UserRolesContext } from "../../authentication/RoleProvider/RoleProvider";
import SectionCard from "../../components/SectionCard/SectionCard";
import IncentiveDetails from "./IncentiveDetails/IncentiveDetails";
import {
	createIncentiveCategory,
	deactivateIncentiveCategory,
	getIncentiveCategories,
	updateIncentiveCategory,
} from "./MaintainIncentive.api";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			width: 350,
		},
		infoContainer: {
			width: "100%",
			height: "100%",
			display: "grid",
			placeContent: "center",
		},
	})
);

type IncentiveDialogs =
	| "add_category"
	| "edit_category"
	| "delete_category"
	| null;

const MaintainIncentive: React.FC = () => {
	const classes = useStyles();
	const newCategoryRef = useRef<HTMLInputElement>(null);
	const editCategoryRef = useRef<HTMLInputElement>(null);

	const [activeDialog, setActiveDialog] = useState<IncentiveDialogs>(null);
	const [activeCategory, setActiveCategory] =
		useState<{ id: number; name: string } | undefined>();
	const [categories, setCategories] = useState<
		{ id: number; name: string }[]
	>([]);

	const handleSelectCategory = (category: any) => {
		setActiveCategory(category ?? undefined);
	};

	const handleNewCategorySave = (event: any) => {
		event.preventDefault();
		if (newCategoryRef.current?.value) {
			const name = newCategoryRef.current?.value;
			createIncentiveCategory({ name })
				.then((data) => {
					refreshCategories();
				})
				.finally(() => {
					setActiveDialog(null);
				});
		}
	};
	const handleCategoryUpdate = (event: any) => {
		event.preventDefault();
		if (activeCategory && editCategoryRef.current?.value) {
			const category_id = activeCategory.id;
			const name = editCategoryRef.current?.value;
			updateIncentiveCategory({ category_id, name })
				.then((data) => {
					refreshCategories();
				})
				.finally(() => {
					setActiveDialog(null);
				});
		}
	};
	const handleCategoryDeactivate = (event: any) => {
		event.preventDefault();
		if (activeCategory) {
			const category_id = activeCategory.id;
			deactivateIncentiveCategory({ category_id })
				.then((data) => {
					refreshCategories();
				})
				.finally(() => setActiveDialog(null));
		}
	};

	/** @MM Difficulty managing state of active category; Workaround for now; */
	const refreshCategories = () => {
		setActiveCategory(undefined);
		getIncentiveCategories().then((data) => {
			setCategories(data);
		});
	};

	useEffect(() => {
		let isActive = true;
		getIncentiveCategories().then((data) => {
			setCategories(data);
		});

		return () => {
			isActive = false;
		};
	}, []);

	return (
		<>
			<SectionCard
				title='Maintain Incentive Category'
				fullWidth
				primaryAction={
					<Autocomplete
						value={activeCategory}
						options={categories}
						onChange={(_, value) => handleSelectCategory(value)}
						getOptionLabel={(option) => option.name}
						getOptionSelected={(option) =>
							option?.id === activeCategory?.id
						}
						renderInput={(params) => (
							<TextField
								{...params}
								className={classes.formControl}
								label='Select Category'
							/>
						)}
					/>
				}
				secondaryAction={
					activeCategory && (
						<ButtonGroup>
							<IconButton
								onClick={() => setActiveDialog("edit_category")}
							>
								<Edit />
							</IconButton>
							<IconButton
								onClick={() =>
									setActiveDialog("delete_category")
								}
							>
								<Delete />
							</IconButton>
						</ButtonGroup>
					)
				}
			>
				{!activeCategory && (
					<div className={classes.infoContainer}>
						<Typography>
							Please select an incentive category.
						</Typography>
						<UserRolesContext.Consumer>
							{({ survey_admin }) => (
								<Button
									disabled={!survey_admin}
									startIcon={<Add />}
									onClick={() =>
										setActiveDialog("add_category")
									}
								>
									Create New
								</Button>
							)}
						</UserRolesContext.Consumer>
					</div>
				)}
				{activeCategory && (
					<IncentiveDetails incentiveCategoryID={activeCategory.id} />
				)}
				<Dialog
					open={Boolean(activeDialog)}
					onClose={() => setActiveDialog(null)}
				>
					{activeDialog === "add_category" && (
						<>
							<DialogTitle>New Incentive Category</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Enter the new category's name below.
								</DialogContentText>
								<TextField
									inputRef={newCategoryRef}
									label='Name'
									className={classes.formControl}
									variant='outlined'
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setActiveDialog(null)}>
									Cancel
								</Button>
								<Button
									startIcon={<Add />}
									onClick={handleNewCategorySave}
								>
									Create
								</Button>
							</DialogActions>
						</>
					)}
					{activeCategory && activeDialog === "edit_category" && (
						<>
							<DialogTitle>Edit Incentive Category</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Enter the category's new name below.
								</DialogContentText>
								<TextField
									inputRef={editCategoryRef}
									defaultValue={activeCategory.name}
									label='Name'
									className={classes.formControl}
									variant='outlined'
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setActiveDialog(null)}>
									Cancel
								</Button>
								<Button
									startIcon={<Save />}
									onClick={handleCategoryUpdate}
								>
									Update
								</Button>
							</DialogActions>
						</>
					)}
					{activeCategory && activeDialog === "delete_category" && (
						<>
							<DialogTitle>Delete Incentive Category</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Are you sure you want to delete this
									category?
								</DialogContentText>
								<List>
									<ListItem>
										<ListItemText>
											{activeCategory.name}
										</ListItemText>
									</ListItem>
								</List>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setActiveDialog(null)}>
									Cancel
								</Button>
								<Button
									startIcon={<SyncDisabled />}
									onClick={handleCategoryDeactivate}
								>
									Deactivate
								</Button>
							</DialogActions>
						</>
					)}
				</Dialog>
			</SectionCard>
		</>
	);
};

export default MaintainIncentive;
