import { Tab, Tabs, TextField } from "@material-ui/core";
import { Dashboard, List } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import UserPerformance from "../../components/UserPerformance/UserPerformance";
import { usePerformanceContext } from "../../Performance.ctx";
import TeamMembers from "../../tables/TeamMembers/TeamMembers";
import {
	getTeamPerformance,
	t_TeamMember,
} from "../../tables/TeamMembers/TeamMembers.api";
import teamPerformanceStyles from "./TeamPerformance.style";
interface Props {}
const TeamPerformance: React.FC<Props> = () => {
	const classes = teamPerformanceStyles();

	const [tabValue, setTabValue] = useState<number>(0);
	const [teamMembers, setTeamMembers] = useState<t_TeamMember[]>([]);
	const [{ viewUser }, performanceDispatch] = usePerformanceContext();

	const handleTeamMemberSelect = (member: t_TeamMember) => {
		performanceDispatch({ type: "view", viewUser: member });
		setTabValue(1);
	};

	useEffect(() => {
		getTeamPerformance({}).then((data: t_TeamMember[]) => {
			if (data) {
				/** @MM Set view user to first item in  */
				setTeamMembers(data);
				data.length > 0 &&
					performanceDispatch({ type: "view", viewUser: data[0] });
			}
		});
	}, []);
	return (
		<SectionCard
			title={tabValue === 1 ? "View Member" : undefined}
			titleProps={{ variant: "h6" }}
			primaryAction={
				tabValue === 1 && (
					<Autocomplete
						className={classes.autoComplete}
						getOptionLabel={(option) => option.user.name}
						value={viewUser}
						options={teamMembers}
						onChange={(_, value) =>
							value &&
							performanceDispatch({
								type: "view",
								viewUser: value,
							})
						}
						renderInput={(params) => (
							<TextField {...params} label='Member' />
						)}
					/>
				)
			}
			secondaryAction={
				<Tabs
					value={tabValue}
					onChange={(_, value) => setTabValue(value)}
				>
					<Tab value={0} label={<List />} />
					<Tab value={1} label={<Dashboard />} disabled={!viewUser} />
				</Tabs>
			}
			fullWidth
		>
			{tabValue === 0 && (
				<TeamMembers
					teamMembers={teamMembers}
					selectUser={handleTeamMemberSelect}
				/>
			)}
			{tabValue === 1 && <UserPerformance />}
		</SectionCard>
	);
};

export default TeamPerformance;
