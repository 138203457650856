import environment from "./env.api";
import { httpClient, rawHttpClient } from "./httpClient.api";

const userAuthEndpoints = {
	login: `${environment.base_url}/${environment.api_version}/login`,
};
type LoginPayload = {};
const login = async (
	payload: {
		data: {
			entity_no: string;
		};
	}
) => {
	const url = userAuthEndpoints.login;
	const data = payload.data;

    const response = await rawHttpClient.post(url, data, {
        headers: {
            "Content-type": "application/json",
            Accept: "application/json",
        },
    });
    return response.data;
};

const getUserMetrics = async () => {
	const response = await httpClient.post("/metrics/summary/me");
	return response.data.data.metric_values
};
const getUserProfile = async () => {
	const response = await httpClient.get("/profile");
	return response.data.data.profile;
};

export { login, getUserMetrics, getUserProfile };
