import {
	Avatar,
	CardHeader,
	FormControl,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	List,
	ListItem,
	TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { getUserBy } from "../../../sections/MaintainUser/MaintainUser.api";

const Search: React.FC = () => {
	const [userSearch, setUserSearch] = useState("");
	const [users, setUsers] = useState<any[]>([]);
	const [selectedUser, setSelectedUser] = useState<any>(null);
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserSearch(event.target.value);
	};
	const handleSearch = () => {
		if (userSearch.length > 0) {
			getUserBy({ search: userSearch }).then((data) => {
				setUsers(data);
			});
		}
	};
	return (
		<SectionCard
			title='Search'
			primaryAction={<TextField label='Search for something' />}
		>
			<Grid container spacing={2}>
				<Grid item sm={5}>
					<SectionCard
						title='Search'
						titleProps={{ variant: "h6" }}
						primaryAction={
							<FormControl>
								<Input
									value={userSearch}
									onChange={handleSearchChange}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton onClick={handleSearch}>
												<Search />
											</IconButton>
										</InputAdornment>
									}
								></Input>
							</FormControl>
						}
						scrolling
					>
						<List>
							{users.map((user) => (
								<ListItem
									key={`user-${user.id}`}
									button
									onClick={() => setSelectedUser(user)}
									selected={user.id === selectedUser?.id}
								>
									<CardHeader
										avatar={
											<Avatar src={user.profile_img} />
										}
										title={user.name}
										subheader={`${user.job_title} - ${user.region}`}
									/>
								</ListItem>
							))}
						</List>
					</SectionCard>
				</Grid>
			</Grid>
		</SectionCard>
	);
};

export default Search;
