import { Avatar, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import React, { useState } from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SectionedDialog from "../../../../components/SectionedDialog/SectionedDialog";
import { UserProfile } from "../../../../globalContext/userObject.ctx";
import UserDetails from "../../../MaintainUser/UserDetails/UserDetails";
import UserAttributes from "../../../User/forms/UserAttributes.form";
import { MemberMetricColumns, t_TeamMember } from "./TeamMembers.api";
import teamMembersStyles from "./TeamMembers.style";

interface TeamMembersProps {
	teamMembers: t_TeamMember[];
	selectUser: (row: { user: UserProfile; metrics: any[] }) => void;
	tableRef?: React.RefObject<any>;
}

const TeamMembers: React.FC<TeamMembersProps> = ({
	teamMembers,
	selectUser,
	tableRef,
}) => {
	const classes = teamMembersStyles();

	/** @MM Find unique metrics so that table columns are only the required; */
	const unique_metrics = new Set<string>();
	teamMembers.map(({ metrics }) =>
		Object.keys(metrics).map((name) => unique_metrics.add(name))
	);

	const columns = MemberMetricColumns(Array.from(unique_metrics).sort());

	const [dialogMember, setDialogMember] = useState<t_TeamMember>();

	return (
		<>
			<BUMaterialTable
				tableRef={tableRef}
				columns={[
					{
						title: "Member",
						field: "user.name",
						render: (rowData) => (
							<CardHeader
								title={rowData.user.name}
								style={{
									padding: 0,
								}}
								avatar={
									<Avatar
										className={classes.condensedAvatar}
										src={rowData.user.profile_img}
									/>
								}
							/>
						),
					},
					...columns,
				]}
				actions={[
					{
						icon: "open_in_new",
						tooltip: "View Member",
						onClick: (event, data) =>
							selectUser(data as t_TeamMember),
					},
					{
						icon: "manage_accounts",
						tooltip: "Member Attributes",
						onClick: (event, data) =>
							setDialogMember(data as t_TeamMember),
					},
				]}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
				}}
				data={teamMembers}
				title='Team Members'
			/>
			<SectionedDialog
				open={Boolean(dialogMember)}
				onClose={() => setDialogMember(undefined)}
				title={dialogMember?.user.name}
				fullWidth
				maxWidth='md'
			>
				<Grid container spacing={2}>
					{dialogMember && (
						<>
							<Grid item sm={6}>
								<Card>
									<CardContent>
										<UserDetails
											entity_no={
												dialogMember.user.entity_no
											}
										/>
									</CardContent>
								</Card>
							</Grid>
							<Grid item sm={6}>
								<SectionCard
									title='User Attributes'
									titleProps={{ variant: "h6" }}
									hasBackground
								>
									<UserAttributes
										entity_no={dialogMember.user.entity_no}
									/>
								</SectionCard>
							</Grid>
						</>
					)}
				</Grid>
			</SectionedDialog>
		</>
	);
};
export default TeamMembers;
