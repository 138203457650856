import {
	CardHeader,
	createStyles,
	Grid,
	InputBase,
	makeStyles,
	Paper,
	Theme,
} from "@material-ui/core";
import React, { ReactNode, useRef, useState } from "react";
import ListLoader from "../ListLoader/ListLoader";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		inputPaper: {
			padding: theme.spacing(0.5, 1.5),
		},
	})
);

export interface Comment {
	id: number;
	content: string;
	author: string;
	avatar: ReactNode;
	action?: ReactNode;
}

interface LiveCommentsProps {
	comments: Comment[];
	commentWrapperRef?: React.RefObject<HTMLElement>;
	inputStyle?: any;
	onComment?: (comment: string) => any;
	loadRecent: () => void;
	loadHistoric: () => void;
	[propName: string]: any;
}

const LiveComments: React.FC<LiveCommentsProps> = ({
	comments,
	onComment,
	inputStyle,
	commentWrapperRef,
	loadRecent,
	loadHistoric,
	...other
}) => {
	const classes = useStyles();
	const [comment, setComment] = useState<string>("");
	const [scrollTo, setScrollTo] = useState<"bottom" | "top">("bottom");
	const inputRef = useRef<HTMLInputElement>(null);

	const handleCommentBlur = () => {
		if (onComment && comment !== "") onComment(comment);
		setComment("");
	};
	const handleOnCommentChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setComment(event.target.value);
	};
	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter") inputRef.current?.blur();
		inputRef.current?.focus();
	};

	const handleLoadHistoric = () => {
		setScrollTo("top");
		loadHistoric();
	};
	const handleLoadRecent = () => {
		setScrollTo("bottom");
		loadRecent();
	};
	return (
		<Grid container spacing={2} direction='column' {...other}>
			<Grid item>
				<Paper className={classes.inputPaper} style={inputStyle}>
					<InputBase
						inputRef={inputRef}
						fullWidth
						placeholder='Leave a comment...'
						value={comment}
						onKeyPress={handleKeyPress}
						onChange={handleOnCommentChange}
						onBlur={handleCommentBlur}
					/>
				</Paper>
			</Grid>
			<Grid
				innerRef={commentWrapperRef}
				item
				container
				direction='column'
			>
				<ListLoader
					bottomLoaderProps={{
						onLoad: handleLoadRecent,
						active: true,
					}}
					topLoaderProps={{ onLoad: handleLoadHistoric }}
					keepScrollTo={scrollTo}
				>
					{comments.map((commentItem) => (
						<Grid item xs={12} key={`comment-${commentItem.id}`}>
							<CardHeader
								avatar={commentItem.avatar}
								title={commentItem.author}
								subheader={commentItem.content}
								action={commentItem.action}
							/>
						</Grid>
					))}
				</ListLoader>
			</Grid>
		</Grid>
	);
};

export default LiveComments;
