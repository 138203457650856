import DateFnsUtils from "@date-io/date-fns";
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	useTheme,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import RoleProvider from "../../authentication/RoleProvider/RoleProvider";
import { ErrorHandlerProvider } from "../../globalContext/errorHandler.ctx";
import { UserProvider } from "../../globalContext/userObject.ctx";
import ProtectedRoute from "../../routers/ProtectedRoute";
import NavSideBar from "../../sections/NavSideBar/NavSideBar";
import { PerformanceContextProvider } from "../../sections/Performance/Performance.ctx";
import Admin from "./Admin/Admin";
import Challenge from "./Challenge/Challenge";
import Dashboard from "./Dashboard/Dashboard";
import Login from "./Login/Login";
import Performance from "./Performance/Performance";
import Profile from "./Profile/Profile";
import Reports from "./Reports/Reports";
import Search from "./Search/Search";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			padding: theme.spacing(2),
			color: theme.palette.secondary.contrastText,
			overflow: "auto",
			height: () => {
				if (theme.mixins.toolbar.minHeight)
					return `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`;
				return "100vh";
			},
		},
	})
);

type Attributes = {
	id: string;
	value: number;
	name: string;
};

const Home = () => {
	const classes = useStyles();
	const [sideNavWidth, setSideNavWidth] = useState(0);
	const theme = useTheme();

	const sideNav = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (sideNav.current != null && sideNav.current.offsetWidth != null)
			setSideNavWidth(sideNav.current.offsetWidth);
	}, [sideNav]);
	return (
		<div
			style={{
				backgroundColor: theme.palette.background.default,
			}}
		>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<BrowserRouter>
					<ErrorHandlerProvider>
						<UserProvider>
							<RoleProvider>
								<NavSideBar containerref={sideNav} />
								<Box
									className={classes.content}
									style={{ marginLeft: sideNavWidth }}
								>
									<Switch>
										<Route path='/' exact>
											<Redirect to='/dashboard' />
										</Route>
										<Route path='/search'>
											<Search />
										</Route>
										<ProtectedRoute path='/performance'>
											<PerformanceContextProvider>
												<Performance />
											</PerformanceContextProvider>
										</ProtectedRoute>
										<ProtectedRoute path='/reports'>
											<Reports />
										</ProtectedRoute>
										<ProtectedRoute path='/profile'>
											<Profile />
										</ProtectedRoute>
										<ProtectedRoute path='/challenge'>
											<Challenge />
										</ProtectedRoute>
										<ProtectedRoute path='/dashboard'>
											<Dashboard />
										</ProtectedRoute>
										<ProtectedRoute path='/admin'>
											<Admin />
										</ProtectedRoute>
										<Route path='/login'>
											<Login />
										</Route>
									</Switch>
								</Box>
							</RoleProvider>
						</UserProvider>
					</ErrorHandlerProvider>
				</BrowserRouter>
			</MuiPickersUtilsProvider>
		</div>
	);
};

export default Home;
