import {
	Avatar,
	CardHeader,
	createStyles,
	makeStyles,
	TableCell,
	TableRow,
	Theme,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DataTable, {
	ColumnHeaderProps,
	DataTableSort,
} from "../../components/DataTable/DataTable";
import RefreshLoader from "../../components/RefreshLoader/RefreshLoader";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import environment from "../../globalApi/env.api";
import {
	getLeaderBoardCategories,
	getLeaderBoardData,
} from "./LeaderBoard.api";

interface LeaderBoardData {
	_id: any;
	name: string;
	profile_img: string;
	points: number;
	position: number;
	tier: {
		colour: string;
		label: string;
	};
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		noFormatRow: {
			"& td": {
				padding: 0,
			},
		},
		divider: {
			height: 2,
			backgroundColor: theme.palette.info.main,
		},
		card: {
			background: "none",
		},
	})
);

const LeaderBoard = () => {
	const classes = useStyles();

	const tableRef = useRef<HTMLElement>(null);
	const [tableData, setTableData] = useState<LeaderBoardData[]>([]);
	const [tableSort, setTableSort] = useState<DataTableSort | undefined>(
		undefined
	);
	const [tabList, setTabList] = useState<{ label: string; _id: any }[]>([]);
	const [tabValue, setTabValue] = useState<number>(-1);
	const [loading, setLoading] = useState<{
		timeout: boolean;
		tableData: boolean;
	}>({ timeout: false, tableData: false });

	const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabValue(newValue);
	};

	const handleSort = (sort: DataTableSort) => {
		fetchLeaderboard(true, sort).finally(() => {
			setTableSort(sort);
		});
	};

	const fetchLeaderboard = useCallback(
		(
			isActive: boolean = true,
			sort: DataTableSort | undefined = undefined
		) => {
			const sortColumns: { [key: string]: any } = {
				Name: "DisplayName",
				Position: "Rank",
				Points: "Elo",
				Tier: "Display Name",
			};
			let payload: { category_id: any; [key: string]: any } = {
				category_id: tabValue >= 0 ? tabList[tabValue]?._id : 1,
			};
			if (sort)
				payload = {
					...payload,
					sort: {
						column_name: sortColumns[sort.columnName],
						order: sort.order,
					},
				};
			return getLeaderBoardData(payload).then((data) => {
				// Promises can't be cancelled; Avoid state setting if inactive;
				if (!isActive) return;
				setTableData(data);
				// @MM Set tableData loading on data;
				setLoading((state) => ({ ...state, tableData: false }));
			});
		},
		[tabList, tabValue]
	);

	// @MM Fetch initial tab list & Load first tab data
	useEffect(() => {
		getLeaderBoardCategories().then((categories) => {
			setTabList(categories);
			setTabValue(0);
		});
	}, [setTabList]);

	// @MM On tab change fetch data
	useEffect(() => {
		let isActive = true;
		// @MM Loading timeout - standardize loading "feel" (remain < 200ms);
		isActive &&
			setLoading((state) => ({
				...state,
				tableData: true,
				timeout: true,
			}));
		setTimeout(
			() =>
				isActive &&
				setLoading((state) => ({ ...state, timeout: false })),
			environment.dataLoadingTimeout
		);

		// @MM Load data;
		fetchLeaderboard(isActive).finally(
			() => isActive && setTableSort(undefined)
		);

		return () => {
			isActive = false;
		};
	}, [tabValue, fetchLeaderboard]);

	const tableColumns: ColumnHeaderProps[] = [
		{
			label: "Name",
			name: "Name",
		},
		{
			label: "Position",
			name: "Position",
		},
		{
			label: "Points",
			name: "Points",
		},
		{
			label: "Tier",
			name: "Tier",
		},
	];

	return (
		<SectionCard
			title='Be You Leaderboard'
			primaryAction={
				<TabGroup
					tabValue={tabValue}
					tabList={tabList}
					tabChange={tabChange}
				/>
			}
			fullWidth
		>
			<DataTable
				rows={tableData}
				columns={tableColumns}
				sort={tableSort}
				sortBy={handleSort}
			>
				<TableRow className={classes.noFormatRow}>
					<TableCell colSpan={tableColumns.length}>
						<RefreshLoader
							parentRef={tableRef}
							onRefresh={fetchLeaderboard}
						/>
					</TableCell>
				</TableRow>

				{loading.timeout || loading.tableData ? (
					<TableRow>
						<TableCell>
							<CardHeader
								style={{ padding: 0 }}
								avatar={
									<Skeleton
										variant='circle'
										width={40}
										height={40}
									/>
								}
								title={<Skeleton variant='text' />}
							/>
						</TableCell>
						<TableCell>
							<Skeleton variant='text' />
						</TableCell>
						<TableCell>
							<Skeleton variant='text' />
						</TableCell>
						<TableCell>
							<Skeleton variant='text' />
						</TableCell>
					</TableRow>
				) : (
					<React.Fragment>
						{tableData.map((row: LeaderBoardData) => (
							<TableRow key={row._id}>
								<TableCell>
									<CardHeader
										style={{ padding: 0 }}
										avatar={
											<Avatar src={row.profile_img} />
										}
										title={row.name}
									/>
								</TableCell>
								<TableCell>{row.position}</TableCell>
								<TableCell>{row.points}</TableCell>
								<TableCell>{row.tier.label}</TableCell>
							</TableRow>
						))}
					</React.Fragment>
				)}
			</DataTable>
		</SectionCard>
	);
};

export default LeaderBoard;
