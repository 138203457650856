import { Badge, Button, ButtonProps } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import surveyButtonStyles from "../Surveys.components.style";

interface Props {
	appeal_status: number | null;
}

const AppealButton: React.FC<Props & ButtonProps> = ({
	appeal_status,
	...props
}) => {
	const classes = surveyButtonStyles();

	return (
		<Button disabled={appeal_status === null} {...props}>
			<Badge
				classes={{
					dot: clsx({
						[classes.incomplete]: appeal_status === 3,
						[classes.partial]: appeal_status === 1,
						[classes.valid]: appeal_status === 2,
					}),
				}}
				variant={"dot"}
			>
				APPEAL
			</Badge>
		</Button>
	);
};

export default AppealButton;
