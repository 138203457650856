import { createMuiTheme } from "@material-ui/core/styles";
import { default as overrides } from "./overrides/overrides";
import { darkPalette, lightPalette, shape } from "./variables/variables";

const darkTheme = createMuiTheme({
	shape,
	palette: darkPalette,
	overrides: overrides(darkPalette),
});

const lightTheme = createMuiTheme({
	shape,
	palette: lightPalette,
	overrides: overrides(lightPalette),
});
export { darkTheme, lightTheme };
