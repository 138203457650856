import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Column } from "material-table";
import React, { useEffect, useState } from "react";
import SectionCard from "../../components/SectionCard/SectionCard";
import { SurveyReports } from "../PerceptionReport/PerceptionReport";
import { getSurveyPeriods } from "../PerceptionReport/PerceptionReport.api";
import { t_SurveyPeriod } from "../Surveys/Surveys.types";
import TeamLeaderSummary from "./TeamLeaderSummary/TeamLeaderSummary";

const SurveyReport: React.FC<{
	survey: SurveyReports;
	columns: (base: Column<any>[]) => Column<any>[];
}> = ({ survey, columns, children }) => {
	const [period, setPeriod] = useState<t_SurveyPeriod>();
	const [selectedDate, setSelectedDate] = useState<Date | null>(
		new Date(Date.now())
	);

	const handleDateChange = (date: MaterialUiPickersDate) => {
		setSelectedDate(date);
	};

	useEffect(() => {
		if (selectedDate !== null) {
			let for_period = "";
			switch (survey) {
				case "mbr":
				case "fcr":
					for_period = survey;
					break;

				default:
					for_period = "mbr";
					break;
			}
			getSurveyPeriods({
				for_month: selectedDate.toDateString(),
				surveys: [for_period],
			}).then((data) => {
				if (data[for_period]) {
					setPeriod(data[for_period]);
				}
			});
		}
	}, [selectedDate]);

	return (
		<SectionCard
			title={`${
				survey.length > 3 ? survey : survey.toUpperCase()
			} Report`}
			secondaryAction={
				<DatePicker
					variant='inline'
					openTo='month'
					views={["year", "month"]}
					label='Month and Year'
					value={selectedDate}
					onChange={handleDateChange}
				/>
			}
			scrolling
			fullWidth
		>
			<Grid container spacing={1}>
				<Grid item sm={8}>
					<SectionCard
						title='Survey Period'
						titleProps={{ variant: "subtitle1" }}
						fullWidth
					>
						<TableContainer component={Paper}>
							<Table size='small'>
								<TableHead>
									<TableRow>
										<TableCell>Survey Type</TableCell>
										<TableCell>Start Date</TableCell>
										<TableCell>End Date</TableCell>
										<TableCell>Last Capture</TableCell>
										<TableCell>Appeal Cut-Off</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{period && (
										<TableRow>
											<TableCell>
												{survey.toUpperCase()}
											</TableCell>
											<TableCell>
												{period.start_date}
											</TableCell>
											<TableCell>
												{period.end_date}
											</TableCell>
											<TableCell>
												{period.last_capture}
											</TableCell>
											<TableCell>
												{period.appeal_cutoff}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</SectionCard>
				</Grid>
				{/* {survey === "mbr" ||
					(survey === "fcr" && period && (
						<Grid item sm={12}>
							<PerceptionSurveySummary
								survey={"mbr"}
								period={period}
							/>
						</Grid>
					))} */}
				{period && (
					<Grid item sm={12}>
						<TeamLeaderSummary
							period={period}
							report={survey}
							columns={columns}
						/>
					</Grid>
				)}
			</Grid>
		</SectionCard>
	);
};

export default SurveyReport;
