import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import PerformanceTrend from "../PerformanceTrend/PerformanceTrend";

interface UserMetricTrendsProps {
	viewUser: any;
}

const UserMetricTrends = ({ viewUser }: UserMetricTrendsProps) => {
	return (
		<SectionCard
			title={`${viewUser.user?.name}'s Trends`}
			titleProps={{
				variant: "h6",
			}}
		>
			<PerformanceTrend />
		</SectionCard>
	);
};

export default UserMetricTrends;
