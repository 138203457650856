import { Badge, Button, ButtonProps } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import surveyButtonStyles from "../Surveys.components.style";

interface Props {
	lsa_status: number | null;
}

const LsaButton: React.FC<Props & ButtonProps> = ({ lsa_status, ...props }) => {
	const classes = surveyButtonStyles();

	return (
		<Button disabled={lsa_status === null}>
			<Badge
				classes={{
					dot: clsx({
						[classes.incomplete]: lsa_status === 1,
						[classes.partial]: lsa_status === 2,
						[classes.valid]: lsa_status === 3,
					}),
				}}
				variant={"dot"}
			>
				LSA
			</Badge>
		</Button>
	);
};

export default LsaButton;
