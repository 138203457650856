import { Button, Collapse, setRef } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";

type RefreshLoaderProps = {
	parentRef: React.RefObject<HTMLElement>;
	buttonContent?: any;
	onRefresh: () => void;
};

const RefreshLoader: React.FC<RefreshLoaderProps> = ({
	parentRef,
	buttonContent,
	onRefresh,
}: RefreshLoaderProps) => {
	const [display, setDisplay] = useState<boolean>(false);
	const timerInId = useRef<NodeJS.Timeout>(null);
	const timerOutId = useRef<NodeJS.Timeout>(null);

	useEffect(() => {
		parentRef.current?.addEventListener("mouseover", () => {
			if (timerOutId.current) clearTimeout(timerOutId.current);
			if (timerInId.current) clearTimeout(timerInId.current);
			setRef(
				timerInId,
				setTimeout(() => setDisplay(true), 2000)
			);
		});
		parentRef.current?.addEventListener("mouseout", () => {
			if (timerInId.current) clearTimeout(timerInId.current);
			if (timerOutId.current) clearTimeout(timerOutId.current);
			setRef(
				timerOutId,
				setTimeout(() => setDisplay(false), 1500)
			);
		});
	}, [parentRef]);

	const handleRefreshClick = () => {
		if (timerInId.current) clearTimeout(timerInId.current);
		if (timerOutId.current) clearTimeout(timerOutId.current);
		setDisplay(false);
		onRefresh();
	};
	return (
		<Collapse in={display} collapsedHeight={0}>
			<Button fullWidth onClick={handleRefreshClick}>
				{buttonContent ?? <Refresh />}
			</Button>
		</Collapse>
	);
};

export default RefreshLoader;
