import MaterialTable, { MaterialTableProps } from "material-table";
import React from "react";

const BUMaterialTable = <RowData extends object>({
	...props
}: MaterialTableProps<RowData>) => {
	const columns = props.columns.map((column) => ({
		...column,
		width: "auto",
	}));
	return <MaterialTable {...props} columns={columns} />;
};

export default BUMaterialTable;
