import {
	Button,
	Checkbox,
	FormControlLabel,
	List,
	ListItem,
	TextField,
} from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { UserProfile } from "../../../globalContext/userObject.ctx";
import { getUserDetails } from "../../MaintainUser/UserDetails/UserDetails.api";
import { t_UserAttributes } from "../User.types";
import userAttributesStyles from "./UserAttributes.style";

interface Props {
	entity_no: UserProfile["entity_no"];
}

const UserAttributes: React.FC<Props> = ({ entity_no }) => {
	const classes = userAttributesStyles();

	const [editUser, setEditUser] = useState<t_UserAttributes>();

	const handleSelectChange = (name: string, value: any) => {
		if (editUser !== undefined && value !== null) {
			setEditUser({
				...editUser,
				[name]: value,
			});
		}
	};
	const handle2ICChange = (checked: boolean) => {
		if (editUser !== undefined) {
			setEditUser({
				...editUser,
				roles: checked ? ["2IC"] : [],
			});
		}
	};

	useEffect(() => {
		getUserDetails({ entity_no }).then((data) => {
			setEditUser(data);
		});
	}, [entity_no]);
	return (
		<List className={classes.formList}>
			{editUser ? (
				<>
					<ListItem>
						<Autocomplete
							className={classes.autocomplete}
							value={editUser.service_team}
							options={[]}
							onChange={(_, value) =>
								handleSelectChange("service_team", value)
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select Service Team'
									variant='outlined'
								/>
							)}
						/>
					</ListItem>
					<ListItem>
						<Autocomplete
							className={classes.autocomplete}
							value={editUser.skill}
							options={[]}
							onChange={(_, value) =>
								handleSelectChange("skill", value)
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select Skill'
									variant='outlined'
								/>
							)}
						/>
					</ListItem>
					<ListItem>
						<FormControlLabel
							control={
								<Checkbox
									checked={editUser.roles.length > 0}
									onChange={(e) =>
										handle2ICChange(e.target.checked)
									}
									color='default'
								/>
							}
							label='Second In Command'
						/>
					</ListItem>
					<Button className={classes.submit}>Save</Button>
				</>
			) : (
				<>
					<ListItem>
						<Skeleton width='100%' height={60} />
					</ListItem>
					<ListItem>
						<Skeleton width='100%' height={60} />
					</ListItem>
					<ListItem>
						<Skeleton width='100%' height={60} />
					</ListItem>
				</>
			)}
		</List>
	);
};

export default UserAttributes;
