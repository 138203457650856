import { Button, Collapse, Grid, makeStyles } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles({
	container: {
		all: "inherit",
		maxHeight: 600,
		overflowY: "auto",
		flexDirection: "unset",
	},
});
type LoaderProps = {
	active?: boolean | true;
	onLoad: () => void;
	buttonContent?: any;
};
type ListLoaderProps = {
	topLoaderProps?: LoaderProps;
	bottomLoaderProps?: LoaderProps;
	keepScrollTo: "bottom" | "top";
};

const Loader: React.FC<LoaderProps> = ({ active, onLoad, buttonContent }) => {
	return (
		<Collapse in={true} collapsedHeight={0}>
			<Button fullWidth onClick={onLoad}>
				{buttonContent ?? <Refresh />}
			</Button>
		</Collapse>
	);
};

const ListLoader: React.FC<ListLoaderProps> = ({
	topLoaderProps,
	bottomLoaderProps,
	keepScrollTo,
	children,
}) => {
	const classes = useStyles();
	const containerRef = useRef<HTMLDivElement>(null);
	const [showState, setShowState] = useState<{
		showTop: boolean;
		showBottom: boolean;
	}>({
		showTop: false,
		showBottom: false,
	});
	const [scrollState, setScrollState] = useState<number>(0);

	useEffect(() => {
		containerRef.current?.addEventListener("scroll", () => {
			// @MM Nonsense oneliner just for being a developer;
			const { scrollTop, scrollHeight } = containerRef.current ?? {
				...[undefined, undefined],
			};

			// @MM scrollTop is type: number | undefined; Catch the undefined and force false;
			if ((scrollTop ?? 5) < 5) {
				setShowState((state) => ({ ...state, showTop: true }));
			}
			// @MM distance_from_bottom = height - distance_from_top;
			if ((scrollHeight ?? 5) - (scrollTop ?? 0) < 5) {
				setShowState((state) => ({ ...state, showBottom: true }));
			}
		});
	}, []);

	useEffect(() => {
		if (containerRef.current) {
			const { scrollHeight } = containerRef.current;
			containerRef.current.scrollTo(
				0,
				{
					top: scrollState,
					bottom: scrollState || scrollHeight,
				}[keepScrollTo]
			);
		}
	}, [children, keepScrollTo, scrollState]);

	const handleTopLoad = () => {
		const scrollValue = {
			top: 0,
			bottom: containerRef.current?.scrollTop ?? 0,
		}[keepScrollTo];
		setScrollState(scrollValue);
		topLoaderProps && topLoaderProps.onLoad();
	};

	const handleBottomLoad = () => {
		const scrollValue = {
			top: containerRef.current?.scrollTop ?? 0,
			bottom: 0,
		}[keepScrollTo];
		setScrollState(scrollValue);
		bottomLoaderProps && bottomLoaderProps.onLoad();
	};

	return (
		<Grid
			ref={containerRef}
			className={classes.container}
			container
			wrap='nowrap'
		>
			{topLoaderProps && (topLoaderProps.active ?? showState.showTop) && (
				<Grid item xs={12}>
					<Loader {...topLoaderProps} onLoad={handleTopLoad} />
				</Grid>
			)}
			{children}
			{bottomLoaderProps &&
				(bottomLoaderProps.active ?? showState.showBottom) && (
					<Grid item xs={12}>
						<Loader
							{...bottomLoaderProps}
							onLoad={handleBottomLoad}
						/>
					</Grid>
				)}
		</Grid>
	);
};

export default ListLoader;
