import React, { createContext, useEffect, useState } from "react";
import { useUserState } from "../../globalContext/userObject.ctx";

type t_UserRoles = {
	rdc: boolean;
	human_resource: boolean;
	survey_admin: boolean;
	quality: boolean;
	report: boolean;
	workforce: boolean;
	survey_consultant: boolean;
};

const base_UserRoles = {
	rdc: false,
	human_resource: false,
	survey_admin: false,
	quality: false,
	report: false,
	workforce: false,
	survey_consultant: false,
};

const UserRolesContext = createContext<t_UserRoles>(base_UserRoles);

const RoleProvider: React.FC = ({ children }) => {
	const { profile } = useUserState();
	const [roles, setRoles] = useState<t_UserRoles>(base_UserRoles);

	useEffect(() => {
		if (profile?.admin_roles) {
			var newRoles = base_UserRoles;
			profile.admin_roles.map((role) => {
				switch (role.id) {
					case 1:
						newRoles.rdc = true;
						break;
					case 2:
						newRoles.human_resource = true;
						break;
					case 3:
					case 7:
						newRoles.survey_admin = true;
						break;
					case 4:
						newRoles.quality = true;
						break;
					case 5:
						newRoles.report = true;
						break;
					case 6:
						newRoles.workforce = true;
						break;
					case 8:
						newRoles.survey_consultant = true;
						break;
					default:
						break;
				}
			});
			setRoles(newRoles);
		}
	}, [profile]);

	return (
		<UserRolesContext.Provider value={roles}>
			{children}
		</UserRolesContext.Provider>
	);
};

export { UserRolesContext };
export default RoleProvider;
