import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	createStyles,
	Divider,
	Grid,
	makeStyles,
	Theme,
	Typography,
	TypographyVariant,
} from "@material-ui/core";
import clsx from "clsx";
import React, { createRef, useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) => {
	const border = `1px solid ${theme.palette.secondary.main}`;
	const cardDefault = {
		border,
	};
	return createStyles({
		card: {
			height: "100%",
		},
		cardContentScroll: {
			overflow: "hidden scroll",
		},
		elevatedCard: {
			...cardDefault,
		},
		flatCard: {
			...cardDefault,
			background: "none",
		},
		flatHeader: {
			borderBottom: `1px solid ${theme.palette.primary.main}`,
		},
		elevatedHeader: {
			borderBottom: `1px solid ${theme.palette.secondary.main}`,
		},
		capitalize: {
			textTransform: "capitalize",
		},
		titleSection: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-evenly",
		},
		flatDivider: {
			backgroundColor: theme.palette.primary.main,
		},
		elevatedDivider: {
			backgroundColor: theme.palette.secondary.main,
		},
		headerActions: {
			alignSelf: "flex-end",
		},
	});
});

interface SectionCardProps {
	title?: string;
	titleProps?: {
		variant?: TypographyVariant;
	};
	subtitle?: string;
	primaryAction?: any;
	secondaryAction?: any;
	bottomActions?: any;
	hasBackground?: boolean;
	fullWidth?: boolean;
	scrolling?: boolean;
	className?: string;
}

const SectionCard: React.FC<SectionCardProps> = ({
	children,
	title,
	titleProps = { variant: "h5" },
	subtitle,
	primaryAction,
	secondaryAction,
	bottomActions,
	hasBackground = false,
	fullWidth = false,
	scrolling = false,
	className,
}) => {
	const classes = useStyles({ hasBackground });
	const [bodyHeight, setBodyHeight] = useState("100%");
	const CardHeaderRef = createRef<HTMLDivElement>();
	const CardActionsRef = createRef<HTMLDivElement>();

	useEffect(() => {
		setBodyHeight(
			`calc(100% - ${
				(CardHeaderRef.current?.offsetHeight ?? 0) +
				(CardActionsRef?.current?.offsetHeight ?? 0)
			}px)`
		);
	}, [CardHeaderRef.current, CardActionsRef.current]);
	return (
		<Card
			className={clsx(classes.card, className)}
			classes={{
				root: hasBackground ? classes.elevatedCard : classes.flatCard,
			}}
			elevation={hasBackground ? 2 : 0}
		>
			<CardHeader
				ref={CardHeaderRef}
				title={
					<Grid container spacing={2}>
						<Grid item className={classes.titleSection}>
							<Typography
								className={classes.capitalize}
								{...titleProps}
							>
								{title}
							</Typography>
							{subtitle && (
								<Typography
									className={classes.capitalize}
									variant='subtitle2'
								>
									{subtitle}
								</Typography>
							)}
						</Grid>
						{primaryAction && (
							<React.Fragment>
								<Grid item>
									<Divider
										orientation='vertical'
										className={
											!hasBackground
												? classes.flatDivider
												: classes.elevatedDivider
										}
									/>
								</Grid>
								<Grid item>{primaryAction}</Grid>
							</React.Fragment>
						)}
					</Grid>
				}
				action={secondaryAction}
				classes={{
					root: hasBackground
						? classes.elevatedHeader
						: classes.flatHeader,
					action: classes.headerActions,
				}}
			/>
			<CardContent
				className={scrolling ? classes.cardContentScroll : undefined}
				style={{
					padding: fullWidth ? 0 : undefined,
					height: bodyHeight,
				}}
			>
				{children}
			</CardContent>
			{bottomActions && (
				<CardActions
					ref={CardActionsRef}
					style={{
						padding: 8,
					}}
				>
					{bottomActions}
				</CardActions>
			)}
		</Card>
	);
};

export default SectionCard;
