import {
	Avatar,
	Box,
	createStyles,
	Divider,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import React from "react";

export type SummaryBadge = {
	label: string;
	value: string;
};
export interface SummaryBadgesProps {
	badges: [SummaryBadge, SummaryBadge, SummaryBadge];
	[propName: string]: any;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		badgeInner: {
			"& p": {
				fontSize: "0.55rem",
			},
		},
		badgeValue: {
			fontWeight: "bold",
		},
		badgeDivider: {
			padding: theme.spacing(0, 2),
			margin: 0,
		},
	})
);
const SummaryBadges = (props: SummaryBadgesProps) => {
	const { badges, ...other } = props;
	const classes = useStyles();

	return (
		<Grid container alignItems='center' {...other}>
			{badges.map((badge, index) => (
				<React.Fragment key={index}>
					<Grid item>
						<Avatar aria-label={`${badge.label} badge`}>
							<Box className={classes.badgeInner}>
								<Typography
									variant='caption'
									align='center'
									component='p'
								>
									{badge.label}
								</Typography>
								<Typography
									variant='caption'
									align='center'
									component='p'
									className={classes.badgeValue}
								>
									{badge.value}
								</Typography>
							</Box>
						</Avatar>
					</Grid>
					{index < badges.length - 1 && (
						<Grid item>
							<Divider
								variant='middle'
								light
								className={classes.badgeDivider}
							/>
						</Grid>
					)}
				</React.Fragment>
			))}
		</Grid>
	);
};

export default SummaryBadges;
