import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useEffect, useState } from "react";
import SectionCard from "../../components/SectionCard/SectionCard";
import { t_SurveyPeriod } from "../Surveys/Surveys.types";
import { getSurveyPeriods } from "./PerceptionReport.api";
import PerceptionSurveySummary from "./PerceptionSurveySummary/PerceptionSurveySummary";

type SurveyReports = "mbr" | "fcr" | "appeal" | "lsa";
const PerceptionReport: React.FC = () => {
	const [periods, setPeriods] = useState<{ [key: string]: t_SurveyPeriod }>(
		{}
	);
	const [selectedDate, setSelectedDate] = useState<Date | null>(
		new Date(Date.now())
	);

	const handleDateChange = (date: MaterialUiPickersDate) => {
		setSelectedDate(date);
	};

	useEffect(() => {
		if (selectedDate !== null) {
			getSurveyPeriods({
				for_month: selectedDate.toDateString(),
				surveys: ["mbr", "fcr"],
			}).then((data) => {
				setPeriods(data);
			});
		}
	}, [selectedDate]);
	return (
		<SectionCard
			title='Perception Report'
			secondaryAction={
				<DatePicker
					variant='inline'
					openTo='month'
					views={["year", "month"]}
					label='Month and Year'
					value={selectedDate}
					onChange={handleDateChange}
				/>
			}
			scrolling
			fullWidth
		>
			<Grid container spacing={1}>
				<Grid item sm={8}>
					<SectionCard
						title='Survey Period'
						titleProps={{ variant: "subtitle1" }}
						fullWidth
					>
						<TableContainer component={Paper}>
							<Table size='small'>
								<TableHead>
									<TableRow>
										<TableCell>Survey Type</TableCell>
										<TableCell>Start Date</TableCell>
										<TableCell>End Date</TableCell>
										<TableCell>Last Capture</TableCell>
										<TableCell>Appeal Cut-Off</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(periods).map((key: string) => (
										<TableRow key={`survey_period-${key}}`}>
											<TableCell>
												{key.toUpperCase()}
											</TableCell>
											<TableCell>
												{periods[key].start_date}
											</TableCell>
											<TableCell>
												{periods[key].end_date}
											</TableCell>
											<TableCell>
												{periods[key].last_capture}
											</TableCell>
											<TableCell>
												{periods[key].appeal_cutoff}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</SectionCard>
				</Grid>
				{periods["mbr"] && (
					<Grid item sm={6}>
						<PerceptionSurveySummary
							survey={"mbr"}
							period={periods["mbr"]}
						/>
					</Grid>
				)}
				{periods["fcr"] && (
					<Grid item sm={6}>
						<PerceptionSurveySummary
							survey={"fcr"}
							period={periods["fcr"]}
						/>
					</Grid>
				)}
			</Grid>
		</SectionCard>
	);
};

export type { SurveyReports };
export default PerceptionReport;
