import {
	Box,
	Card,
	CardContent,
	CardHeader,
	IconButton,
} from "@material-ui/core";
import { ChatBubble, Favorite, FavoriteBorder } from "@material-ui/icons";
import React, { ReactElement, useEffect, useState } from "react";

export interface ActivityCardProps {
	avatar: any;
	name: any;
	activity: any;
	timestamp?: Date;
	children?: ReactElement<any, any> | undefined;
	heartActive?: boolean;
	onHeartClick?: () => any;
	onProfileClick?: () => any;
	[propName: string]: any;
}

const ActivityCard = (props: ActivityCardProps) => {
	const {
		avatar,
		name,
		activity,
		timestamp,
		children,
		heartActive,
		onHeartClick,
		onProfileClick,
		...other
	} = props;
	const [timestampString, setTimestampString] = useState<string>("");

	useEffect(() => {
		setTimestampString(
			(() => {
				const current = new Date(Date.now());
				let time = 0;
				if (!timestamp) return "";
				if (
					(time = current.getFullYear() - timestamp.getFullYear()) > 0
				)
					return `${time} years ago`;
				if ((time = current.getMonth() - timestamp.getMonth()) > 0)
					return `${time} months ago`;
				if ((time = current.getDate() - timestamp.getDate()) > 0)
					return `${time} days ago`;
				if ((time = current.getMinutes() - timestamp.getMinutes()) > 0)
					return `${time} minutes ago`;
				if ((time = current.getSeconds() - timestamp.getSeconds()) > 0)
					return `1 minute ago`;
				return "Just now";
			})()
		);
	}, [timestamp]);

	return (
		<Card {...other}>
			<CardHeader
				avatar={avatar}
				action={
					<Box>
						<IconButton
							aria-label='Profile'
							onClick={onProfileClick}
						>
							<ChatBubble />
						</IconButton>
						<IconButton aria-label='Heart' onClick={onHeartClick}>
							{heartActive ? <Favorite /> : <FavoriteBorder />}
						</IconButton>
					</Box>
				}
				title={
					<Box>
						<Box display='inline'>{name}</Box>
						{activity && (
							<>
								&nbsp;
								<Box display='inline'>{activity}</Box>
							</>
						)}
					</Box>
				}
				subheader={timestampString}
			/>
			{children && (
				<CardContent>
					<Card elevation={0}>{children}</Card>
				</CardContent>
			)}
		</Card>
	);
};

export default ActivityCard;
