import { Column, Query, QueryResult } from "material-table";
import { httpClient } from "../../globalApi/httpClient.api";
import { UserProfile } from "../../globalContext/userObject.ctx";
import { convertQuery } from "../../misc/helpers";
import { SurveyReports } from "../PerceptionReport/PerceptionReport";
import { t_SurveyPeriod, t_SurveyRecord } from "./Surveys.types";

const SurveyColumns: Column<t_SurveyRecord>[] = [
	{ title: "Survey ID", field: "SurveyID" },
	{ title: "Survey Type ID", field: "SurveyTypeID" },
	{ title: "Call ID", field: "CallID" },
	{ title: "Call Date Answered", field: "CallDateAnswered" },
	{ title: "Survey Date", field: "SurveyDate" },
	{ title: "Survey Score", field: "SurveyScore" },
];

const getSurveyRecords = (payload: {
	query: Query<t_SurveyRecord>;
	period?: t_SurveyPeriod;
	report: SurveyReports;
	entity_no: UserProfile["entity_no"];
}) =>
	new Promise<QueryResult<t_SurveyRecord>>((resolve, reject) => {
		const body = {
			for_report: payload.report,
			...payload.period,
			agent_entity_no: payload.entity_no,
			...convertQuery(payload.query),
		};

		httpClient
			.post("/reports/survey/agent_records", body)
			.then(({ data }) =>
				resolve({
					data: data.data.agent_records.data,
					page: data.pagination.page - 1,
					totalCount: data.total_results,
				})
			);
	});

export { SurveyColumns, getSurveyRecords };
