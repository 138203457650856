import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { AccordionItem } from "../../../components/AccordionList/AccordionList";
import ProtectedRoute from "../../../routers/ProtectedRoute";
import PerceptionReport from "../../../sections/PerceptionReport/PerceptionReport";
import SurveyReport from "../../../sections/SurveyReport/SurveyReport";
import {
	WithAppealsColumns,
	WithLsaColumns,
	WithSurveyColumns,
} from "../../../sections/SurveyReport/TeamLeaderSummary/TeamLeaderSummary.api";
import UsageReport from "../../../sections/UsageReport/UsageReport";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const Reports = () => {
	const classes = useStyles();
	const { path } = useRouteMatch();
	const [reportItems, setReportItems] = useState<AccordionItem[]>([
		{
			title: "Perception",
			path: "/reports/perception",
			subroutes: [{ title: "FCR", path: "/reports/fcr" }],
		},
		{ title: "Usage Report", path: "/reports/usage" },
	]);

	return (
		<>
			<ProtectedRoute path={`${path}/perception`} exact>
				<PerceptionReport />
			</ProtectedRoute>
			<ProtectedRoute path={`${path}/perception/mbr`}>
				<SurveyReport
					key={"mbr-survey-report"}
					survey={"mbr"}
					columns={WithSurveyColumns}
				/>
			</ProtectedRoute>
			<ProtectedRoute path={`${path}/perception/fcr`}>
				<SurveyReport
					key={"fcr-survey-report"}
					survey={"fcr"}
					columns={WithSurveyColumns}
				/>
			</ProtectedRoute>
			<ProtectedRoute path={`${path}/perception/appeals`}>
				<SurveyReport
					key={"appeals-survey-report"}
					survey={"appeal"}
					columns={WithAppealsColumns}
				/>
			</ProtectedRoute>
			<ProtectedRoute path={`${path}/perception/lsa`}>
				<SurveyReport
					key={"lsa-survey-report"}
					survey={"lsa"}
					columns={WithLsaColumns}
				/>
			</ProtectedRoute>
			<ProtectedRoute path={`${path}/usage`}>
				<UsageReport />
			</ProtectedRoute>
			<ProtectedRoute path={`${path}/`} exact>
				<Redirect to={`${path}/perception`} />
			</ProtectedRoute>
		</>
	);
};

export default Reports;
