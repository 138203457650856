import { Button, Grid } from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import { DateRange, DateRangePicker } from "materialui-daterange-picker";
import React, { useEffect, useState } from "react";
import SectionCard from "../../../components/SectionCard/SectionCard";
import TabGroup from "../../../components/TabGroup/TabGroup";
import UserPerformance from "../../../sections/Performance/components/UserPerformance/UserPerformance";
import { usePerformanceContext } from "../../../sections/Performance/Performance.ctx";
import TeamPerformance from "../../../sections/Performance/sections/TeamPerformance/TeamPerformance";
import performanceStyles from "./Performance.style";

type MetricType = {
	id: number;
	name: string;
	value: number;
	details: { target: number; comparison: number };
};

const Performance: React.FC = () => {
	const classes = performanceStyles();
	const [{ dates }, performanceDispatch] = usePerformanceContext();
	const [togglePicker, setTogglePicker] = useState(false);

	const [tab, setTab] = useState<number>(0);
	const tabList = [
		{ _id: 0, label: "My Performance" },
		{ _id: 1, label: "My Team" },
	];

	const handleDateChange = (range: DateRange) => {
		if (range.startDate && range.endDate) {
			performanceDispatch({
				type: "update_dates",
				dates: {
					start_date: range.startDate,
					end_date: range.endDate,
				},
			});
		}
	};

	useEffect(() => {
		if (tab === 0) {
			performanceDispatch({ type: "refresh" });
		}
	}, [tab]);

	return (
		<Grid item xs={12}>
			<SectionCard
				title='Performance Dashboard'
				fullWidth
				primaryAction={
					<TabGroup
						tabList={tabList}
						tabChange={(_, newValue) => setTab(newValue)}
						tabValue={tab}
					/>
				}
				secondaryAction={
					<div className={classes.dateSelection}>
						<Button
							variant='outlined'
							className={classes.dateToggleButton}
							onClick={() => setTogglePicker((state) => !state)}
						>
							{dates.start_date}
							<ArrowRightAlt />
							{dates.end_date}
						</Button>
						<div className={classes.dateRangeWrapper}>
							<DateRangePicker
								open={togglePicker}
								toggle={() =>
									setTogglePicker((state) => !state)
								}
								initialDateRange={{
									startDate: new Date(dates.start_date),
									endDate: new Date(dates.end_date),
								}}
								onChange={handleDateChange}
							/>
						</div>
					</div>
				}
			>
				{/* Currently logged in user: */}
				<Grid item xs={12}>
					{tab === 0 && <UserPerformance />}
					{/* Team view: */}
					{tab === 1 && (
						<TeamPerformance />
						// <TeamMembers selectUser={handleUserSelected} />
					)}
				</Grid>
			</SectionCard>
		</Grid>
	);
};

export type { MetricType };
export default Performance;
