import { Avatar, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ChallengeCard, {
	ChallengeCardProps,
} from "../../components/ChallengeCard/ChallengeCard";
import { challengeEndpoints } from "../../misc/endpoints";
import { getDateString, groupBy } from "../../misc/helpers";

const useStyles = makeStyles({
	challengeCard: {
		width: "100%",
	},
});

const ChallengeHistory = () => {
	const classes = useStyles();

	const [challengeGroups, setChallengeGroups] = useState<
		{ challengers: ChallengeCardProps[]; timestamp: number }[]
	>([]);

	useEffect(() => {
		challengeEndpoints.getChallengeHistory().then((data) => {
			const edited = data.map((challenge) => {
				const challengeDate = new Date(challenge.timestamp);
				return {
					lhs: {
						...challenge.users[0],
						avatar: <Avatar src={challenge.users[0].profile_img} />,
					},
					rhs: {
						...challenge.users[1],
						avatar: <Avatar src={challenge.users[0].profile_img} />,
					},
					timestamp: challengeDate,
					group: new Date(
						challengeDate.getFullYear(),
						challengeDate.getMonth()
					).getTime(),
				};
			});
			let groupsObj = groupBy(edited, "group");
			let groups = [];
			for (let key in groupsObj) {
				console.log(key);
				groups.push({
					challengers: groupsObj[key] as ChallengeCardProps[],
					timestamp: Number(key),
				});
			}
			console.log(groups);
			setChallengeGroups(groups);
		});
	}, [setChallengeGroups]);
	return (
		<Grid container spacing={2}>
			<Grid item sm={12}>
				<Typography variant='h5'>Previous Challenges</Typography>
			</Grid>
			{challengeGroups.map((group) => (
				<React.Fragment>
					<Grid item sm={12} container justify='flex-end'>
						<Typography variant='h6'>
							{getDateString({
								timestamp: group.timestamp,
								month: "full",
								year: "full",
							})}
						</Typography>
					</Grid>
					{group.challengers.map((challenger) => (
						<Grid item sm={12}>
							<Paper className={classes.challengeCard}>
								<ChallengeCard {...challenger} />
							</Paper>
						</Grid>
					))}
				</React.Fragment>
			))}
		</Grid>
	);
};

export default ChallengeHistory;
