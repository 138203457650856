import axios, { AxiosRequestConfig } from "axios";
import { InternalError } from "../globalContext/errorHandler.ctx";
import environment from "./env.api";

const httpClient = axios.create();

const authInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
	const token_type = localStorage.getItem("token_type");
	const api_access_token = localStorage.getItem("api_access_token");

	if (!(token_type && api_access_token) ?? false) {
		throw new InternalError("NOT_LOGGED_IN");
	}
	config.headers = {
		...config.headers,
		Authorization: `${token_type} ${api_access_token}`,
	};

	config.baseURL = `${environment.base_url}/${environment.api_version}`;
	return config;
};

httpClient.interceptors.request.use(authInterceptor);

export { httpClient, axios as rawHttpClient };
