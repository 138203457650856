import { DetailedChallenge } from "../sections/CurrentChallenge/CurrentChallenge";
import { ActivityComment, ActivityData } from "../sections/NewsFeed/NewsFeed";
import { UserDetails } from "../sections/ProfileBio/ProfileBio";

export const LeaderBoardData = [
	{
		name: "John Doe",
		profile_img:
			"https://img.icons8.com/color/72/circled-user-female-skin-type-1-2.png",

		points: 3500,
		tier: "Bronze",
	},
	{
		name: "John Doe",
		profile_img:
			"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",

		points: 3500,
		tier: "Bronze",
	},
	{
		name: "John Doe",
		profile_img:
			"https://img.icons8.com/color/2x/circled-user-male-skin-type-3.png",

		points: 3500,
		tier: "Bronze",
	},
	{
		name: "John Doe",
		profile_img:
			"https://img.icons8.com/color/2x/circled-user-female-skin-type-6.png",
		points: 3500,
		tier: "Bronze",
	},
];
export const LeaderBoardTabs = [
	{
		_id: "LBT_G",
		label: "Global",
	},
	{
		_id: "LBT_F",
		label: "Friends",
	},
	{
		_id: "LBT_T",
		label: "Team",
	},
];
export const ActivityCategories = [
	{
		_id: "ACT_F",
		label: "Friends Activity",
	},
	{
		_id: "ACT_T",
		label: "Team Activity",
	},
];
export const ActivityTestData: ActivityData[] = [
	{
		_id: 0x001,
		profile_img:
			"https://img.icons8.com/color/2x/circled-user-female-skin-type-6.png",
		name: "John Doe",
		timestamp: new Date(new Date().setMinutes(2)).getTime(),
		featured_comment: {
			id: 1,
			profile_img:
				"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
			name: "John Doe",
			comment: "Well done, so happy for you!",
			like_count: 0,
		},
		activityTierChange: {
			label: "gold",
			colour: "#FFD700",
		},
		comments: [
			{
				id: 1,
				profile_img:
					"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
				name: "John Doe",
				comment: "Well done, so happy for you!",
				like_count: 0,
			},
		],
	},
	{
		_id: 0x002,
		profile_img:
			"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
		timestamp: new Date(new Date().setMinutes(2)).getTime(),
		featured_comment: {
			id: 1,
			profile_img:
				"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
			name: "John Doe",
			comment: "Well done, so happy for you!",
			like_count: 0,
		},
		name: "Jane Doe",
		comments: [],
		activityNewBadge: {
			name: "Best in the biz",
			description: "Win 5 Challenges in a Row",
			badge_img: "https://img.icons8.com/color/2x/verified-badge.png",
		},
	},
	{
		_id: 0x003,
		profile_img:
			"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
		timestamp: new Date(new Date().setMinutes(2)).getTime(),
		featured_comment: {
			id: 1,
			profile_img:
				"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
			name: "John Doe",
			comment: "Well done, so happy for you!",
			like_count: 0,
		},
		name: "Jono Doe",
		activityChallenge: {
			_id: "1x1",
			timestamp: new Date().setMonth(2),
			users: [
				{
					profile_img:
						"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
					name: "John Doe",
					tier: { label: "Gold", colour: "#FFD700" },
					location: "Sandton",
					progress: 0.6,
				},
				{
					profile_img:
						"https://img.icons8.com/color/2x/circled-user-male-skin-type-3.png",
					name: "John Doe 2",
					tier: { label: "Gold", colour: "#FFD700" },
					location: "Sandton",
					progress: 0.8,
				},
			],
		},
		comments: [],
	},
];
export const ActivityCommentsData: {
	_id: any;
	comments: ActivityComment[];
}[] = [
	{
		_id: 0x001,
		comments: [
			{
				id: 1,
				profile_img:
					"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
				name: "John Doe",
				comment: "Here's another comment!",
				like_count: 0,
			},
		],
	},
	{
		_id: 0x003,
		comments: [
			{
				id: 1,
				profile_img:
					"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
				name: "John Doe",
				comment: "Mighty good job!",
				like_count: 0,
			},
			{
				id: 2,
				profile_img:
					"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
				name: "John Doe",
				comment: "For that awesome achievement...",
				like_count: 0,
			},
		],
	},
];
export const ReportItems = [
	{ title: "Metrics", children: [{ title: "FCR" }] },
	{ title: "Usage" },
	{ title: "TWT" },
	{ title: "Survey" },
];
export const ReportDateOptions = [
	{
		timestamp: new Date(new Date().setMonth(0)).getTime(),
	},
	{
		timestamp: new Date(new Date().setMonth(1)).getTime(),
	},
	{
		timestamp: new Date(new Date().setMonth(2)).getTime(),
	},
];
export const ReportGraphData = [
	{
		category: "Category 1",
		value: 5,
	},
	{
		category: "Category 2",
		value: 8,
	},
	{
		category: "Category 3",
		value: 7,
	},
	{
		category: "Category 4",
		value: 12,
	},
	{
		category: "Category 5",
		value: 10,
	},
];
export const ReportTableData = [
	{
		_id: 1,
		type: "Systems Digital",
		percentage: 0.25,
		volume: 2,
	},
	{
		_id: 2,
		type: "Systems Digital",
		percentage: 0.25,
		volume: 2,
	},
	{
		_id: 3,
		type: "Systems Digital",
		percentage: 0.25,
		volume: 2,
	},
	{
		_id: 4,
		type: "Systems Digital",
		percentage: 0.25,
		volume: 2,
	},
	{
		_id: 5,
		type: "Systems Digital",
		percentage: 0.25,
		volume: 2,
	},
];
export const OfficePointsData = [
	{
		_id: "B0",
		label: "Bloemfontein",
		lat: -28.14,
		lng: 26.14,
		data: [
			{
				_id: "1",
				acronym: "TST1",
				value: 25,
				label: "TEST",
			},
			{
				_id: "2",
				acronym: "TST2",
				value: 65,
				label: "TEST",
			},
			{
				_id: "3",
				acronym: "TST3",
				value: 120,
				label: "TEST",
			},
		],
	},
	{
		_id: "D0",
		label: "Durban",
		lat: -29.88,
		lng: 30.9,
		data: [
			{
				_id: "1",
				acronym: "TST1",
				value: 5,
				label: "TEST",
			},
			{
				_id: "2",
				acronym: "TST2",
				value: 45,
				label: "TEST",
			},
			{
				_id: "3",
				acronym: "TST3",
				value: 140,
				label: "TEST",
			},
		],
	},
];

export const PerformanceData = [
	{
		products_sold: 7000,
		total_views: 4000,
		timestamp: new Date(new Date().setMonth(0)).getTime(),
	},
	{
		products_sold: 8000,
		total_views: 9000,
		timestamp: new Date(new Date().setMonth(0)).getTime(),
	},
	{
		products_sold: 10000,
		total_views: 8000,
		timestamp: new Date(new Date().setMonth(0)).getTime(),
	},
	{
		products_sold: 7000,
		total_views: 4000,
		timestamp: new Date(new Date().setMonth(0)).getTime(),
	},
	{
		products_sold: 8000,
		total_views: 9000,
		timestamp: new Date(new Date().setMonth(1)).getTime(),
	},
	{
		products_sold: 10000,
		total_views: 8000,
		timestamp: new Date(new Date().setMonth(1)).getTime(),
	},
	{
		products_sold: 7000,
		total_views: 4000,
		timestamp: new Date(new Date().setMonth(1)).getTime(),
	},
	{
		products_sold: 8000,
		total_views: 9000,
		timestamp: new Date(new Date().setMonth(1)).getTime(),
	},
	{
		products_sold: 10000,
		total_views: 8000,
		timestamp: new Date(new Date().setMonth(2)).getTime(),
	},
	{
		products_sold: 7000,
		total_views: 4000,
		timestamp: new Date(new Date().setMonth(2)).getTime(),
	},
	{
		products_sold: 8000,
		total_views: 9000,
		timestamp: new Date(new Date().setMonth(2)).getTime(),
	},
	{
		products_sold: 10000,
		total_views: 8000,
		timestamp: new Date(new Date().setMonth(2)).getTime(),
	},
	{
		products_sold: 7000,
		total_views: 4000,
		timestamp: new Date(new Date().setMonth(3)).getTime(),
	},
	{
		products_sold: 8000,
		total_views: 9000,
		timestamp: new Date(new Date().setMonth(3)).getTime(),
	},
	{
		products_sold: 10000,
		total_views: 8000,
		timestamp: new Date(new Date().setMonth(3)).getTime(),
	},
	{
		products_sold: 7000,
		total_views: 4000,
		timestamp: new Date(new Date().setMonth(3)).getTime(),
	},
	{
		products_sold: 8000,
		total_views: 9000,
		timestamp: new Date(new Date().setMonth(4)).getTime(),
	},
	{
		products_sold: 10000,
		total_views: 8000,
		timestamp: new Date(new Date().setMonth(4)).getTime(),
	},
];

export const TeamSummaryPerformanceCols = [
	{
		label: "Name",
	},
	{
		label: "Points",
	},
	{
		label: "Tier",
	},
	{
		label: "AHT",
	},
	{
		label: "DPMO",
	},
	{
		label: "FCR",
	},
	{
		label: "MBR",
	},
	{
		label: "Production",
	},
	{
		label: "Attendance",
	},
	{
		label: "Engagement",
	},
];
export const TeamSummaryPerformance = [
	{
		user: {
			name: "John Doe",
			profile_img:
				"https://img.icons8.com/color/72/circled-user-female-skin-type-1-2.png",
			points: 3500,
			tier: {
				_id: "01x01",
				label: "Bronze",
				colour: "bronze",
			},
		},

		metrics: [
			{
				label: "AHT",
				acronym: "aht",
				value: 0,
				_id: "00x01",
			},
			{
				label: "DPMO",
				acronym: "dpmo",
				value: 0,
				_id: "00x02",
			},
			{
				label: "FCR",
				acronym: "fcr",
				value: 0,
				_id: "00x03",
			},
			{
				label: "MRB",
				acronym: "mbr",
				value: 0,
				_id: "00x04",
			},
			{
				label: "Production",
				acronym: "prod",
				value: 0,
				_id: "00x11",
			},
			{
				label: "Attendance",
				acronym: "att",
				value: 0,
				_id: "00x12",
			},
			{
				label: "Engagement",
				acronym: "engag",
				value: 0,
				_id: "00x13",
			},
		],
	},
	{
		user: {
			name: "John Doe",
			profile_img:
				"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
			points: 3500,
			tier: {
				_id: "01x01",
				label: "Bronze",
				colour: "bronze",
			},
		},

		metrics: [
			{
				label: "AHT",
				acronym: "aht",
				value: 0,
				_id: "00x01",
			},
			{
				label: "DPMO",
				acronym: "dpmo",
				value: 0,
				_id: "00x02",
			},
			{
				label: "FCR",
				acronym: "fcr",
				value: 0,
				_id: "00x03",
			},
			{
				label: "MRB",
				acronym: "mbr",
				value: 0,
				_id: "00x04",
			},
			{
				label: "Production",
				acronym: "prod",
				value: 0,
				_id: "00x11",
			},
			{
				label: "Attendance",
				acronym: "att",
				value: 0,
				_id: "00x12",
			},
			{
				label: "Engagement",
				acronym: "engag",
				value: 0,
				_id: "00x13",
			},
		],
	},
	{
		user: {
			name: "John Doe",
			profile_img:
				"https://img.icons8.com/color/2x/circled-user-male-skin-type-3.png",
			points: 3500,
			tier: {
				_id: "01x01",
				label: "Bronze",
				colour: "bronze",
			},
		},

		metrics: [
			{
				label: "AHT",
				acronym: "aht",
				value: 0,
				_id: "00x01",
			},
			{
				label: "DPMO",
				acronym: "dpmo",
				value: 0,
				_id: "00x02",
			},
			{
				label: "FCR",
				acronym: "fcr",
				value: 0,
				_id: "00x03",
			},
			{
				label: "MRB",
				acronym: "mbr",
				value: 0,
				_id: "00x04",
			},
			{
				label: "Production",
				acronym: "prod",
				value: 0,
				_id: "00x11",
			},
			{
				label: "Attendance",
				acronym: "att",
				value: 0,
				_id: "00x12",
			},
			{
				label: "Engagement",
				acronym: "engag",
				value: 0,
				_id: "00x13",
			},
		],
	},
	{
		user: {
			name: "John Doe",
			profile_img:
				"https://img.icons8.com/color/2x/circled-user-female-skin-type-6.png",
			points: 3500,
			tier: {
				_id: "01x01",
				label: "Bronze",
				colour: "bronze",
			},
		},
		metrics: [
			{
				label: "AHT",
				acronym: "aht",
				value: 0,
				_id: "00x01",
			},
			{
				label: "DPMO",
				acronym: "dpmo",
				value: 0,
				_id: "00x02",
			},
			{
				label: "FCR",
				acronym: "fcr",
				value: 0,
				_id: "00x03",
			},
			{
				label: "MRB",
				acronym: "mbr",
				value: 0,
				_id: "00x04",
			},
			{
				label: "Production",
				acronym: "prod",
				value: 0,
				_id: "00x11",
			},
			{
				label: "Attendance",
				acronym: "att",
				value: 0,
				_id: "00x12",
			},
			{
				label: "Engagement",
				acronym: "engag",
				value: 0,
				_id: "00x13",
			},
		],
	},
];

export const UserProfileData: UserDetails = {
	name: "John Doe",
	profile_img:
		"https://img.icons8.com/color/144/circled-user-male-skin-type-5.png",
	position: "Head of Marketing",
	office: {
		_id: "05x01",
		label: "Sandton Offices",
	},
	summary: [
		{
			_id: "01x00",
			label: "Wins",
			value: 52,
		},
		{
			_id: "01x01",
			label: "Badges",
			value: 4,
		},
		{
			_id: "01x02",
			label: "Losses",
			value: 8,
		},
	],
	bio:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque porttitor, turpis sit amet efficitur egestas, magna arcu iaculis eros, quis aliquet massa nunc eu tortor. Sed id erat sem. Sed at tincidunt lacus. Mauris ac mattis quam. Sed posuere nisl lacus, eget consequat nisi efficitur et. Etiam vitae turpis at neque porttitor bibendum.",
};

export const UserProfileAttributesData = {
	total: 100,
	values: [
		{
			_id: "1",
			acronym: "TST1",
			value: 25,
			label: "TEST",
		},
		{
			_id: "2",
			acronym: "TST2",
			value: 65,
			label: "TEST",
		},
		{
			_id: "3",
			acronym: "TST3",
			value: 120,
			label: "TEST",
		},
	],
};

export const UserProfileBadgesData = [
	{
		_id: "00xb1",
		badge_img: "https://img.icons8.com/color/2x/verified-badge.png",
		name: "This is an award",
		description: "Absolute best in the biz.",
	},
	{
		_id: "00xb2",
		badge_img: "https://img.icons8.com/plasticine/2x/check-all.png",
		name: "Completionist",
		description: "Complete all tasks for 2 weeks.",
	},
	{
		_id: "00xb3",
		badge_img: "https://img.icons8.com/officel/2x/raspberry-pi.png",
		name: "Pi Eating Contest",
		description: "Successful micro-tech install.",
	},
];

export const CurrentChallengeData: DetailedChallenge = {
	user: {
		name: "John Doe",
		profile_img:
			"https://img.icons8.com/color/144/circled-user-male-skin-type-5.png",
		tier: {
			_id: "0x01",
			label: "Gold",
			colour: "gold",
		},
		office: {
			_id: "0x002",
			label: "Sandton",
		},
		progress: 0.6,
		summary: [
			{
				_id: "01x00",
				label: "Wins",
				value: 20,
			},
			{
				_id: "01x01",
				label: "Badges",
				value: 4,
			},
			{
				_id: "01x02",
				label: "Losses",
				value: 2,
			},
		],
		metrics: [
			{
				label: "AHT",
				acronym: "aht",
				value: 200,
				_id: "00x01",
			},
			{
				label: "DPMO",
				acronym: "dpmo",
				value: 400,
				_id: "00x02",
			},
			{
				label: "FCR",
				acronym: "fcr",
				value: 1100,
				_id: "00x03",
			},
			{
				label: "MRB",
				acronym: "mbr",
				value: 1300,
				_id: "00x04",
			},
			{
				label: "Production",
				acronym: "prod",
				value: 1050,
				_id: "00x11",
			},
			{
				label: "Attendance",
				acronym: "att",
				value: 12,
				_id: "00x12",
			},
			{
				label: "Engagement",
				acronym: "engag",
				value: 900,
				_id: "00x13",
			},
		],
		badges: [
			{
				_id: "00xb1",
				badge_img: "https://img.icons8.com/color/2x/verified-badge.png",
				name: "This is an award",
				description: "Absolute best in the biz.",
			},
			{
				_id: "00xb2",
				badge_img: "https://img.icons8.com/officel/2x/raspberry-pi.png",
				name: "Pi Eating Contest",
				description: "Successful micro-tech install.",
			},
			{
				_id: "00xb3",
				badge_img: "https://img.icons8.com/color/2x/verified-badge.png",
				name: "This is an award",
				description: "Absolute best in the biz.",
			},
			{
				_id: "00xb4",
				badge_img: "https://img.icons8.com/plasticine/2x/check-all.png",
				name: "Completionist",
				description: "Complete all tasks for 2 weeks.",
			},
			{
				_id: "00xb5",
				badge_img: "https://img.icons8.com/color/2x/verified-badge.png",
				name: "This is an award",
				description: "Absolute best in the biz.",
			},
			{
				_id: "00xb6",
				badge_img: "https://img.icons8.com/officel/2x/raspberry-pi.png",
				name: "Pi Eating Contest",
				description: "Successful micro-tech install.",
			},
			{
				_id: "00xb7",
				badge_img: "https://img.icons8.com/color/2x/verified-badge.png",
				name: "This is an award",
				description: "Absolute best in the biz.",
			},
			{
				_id: "00xb8",
				badge_img: "https://img.icons8.com/plasticine/2x/check-all.png",
				name: "Completionist",
				description: "Complete all tasks for 2 weeks.",
			},
		],
	},
	challenger: {
		name: "Jane Smith",
		profile_img:
			"https://img.icons8.com/color/144/circled-user-male-skin-type-5.png",
		tier: {
			_id: "00x01",
			label: "Silver",
			colour: "silver",
		},
		office: {
			_id: "0x001",
			label: "Sandton",
		},
		progress: 0.4,
		summary: [
			{
				_id: "01x00",
				label: "Wins",
				value: 5,
			},
			{
				_id: "01x01",
				label: "Badges",
				value: 2,
			},
			{
				_id: "01x02",
				label: "Losses",
				value: 5,
			},
		],
		metrics: [
			{
				label: "AHT",
				acronym: "aht",
				value: 200,
				_id: "00x01",
			},
			{
				label: "DPMO",
				acronym: "dpmo",
				value: 400,
				_id: "00x02",
			},
			{
				label: "FCR",
				acronym: "fcr",
				value: 1100,
				_id: "00x03",
			},
			{
				label: "MRB",
				acronym: "mbr",
				value: 1300,
				_id: "00x04",
			},
			{
				label: "Production",
				acronym: "prod",
				value: 1050,
				_id: "00x11",
			},
			{
				label: "Attendance",
				acronym: "att",
				value: 12,
				_id: "00x12",
			},
			{
				label: "Engagement",
				acronym: "engag",
				value: 900,
				_id: "00x13",
			},
		],
		badges: [
			{
				_id: "00xb2",
				badge_img: "https://img.icons8.com/plasticine/2x/check-all.png",
				name: "Completionist",
				description: "Complete all tasks for 2 weeks.",
			},
			{
				_id: "00xb3",
				badge_img: "https://img.icons8.com/officel/2x/raspberry-pi.png",
				name: "Pi Eating Contest",
				description: "Successful micro-tech install.",
			},
		],
	},
	timestamp: new Date().setMonth(1),
	metric_baseline: 1200,
	metric_total: 1500,
};

export const ChallengeHistoryData = [
	{
		_id: "1x1",
		timestamp: new Date().setMonth(2),
		users: [
			{
				profile_img:
					"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
				name: "John Doe",
				tier: { label: "Gold" },
				location: "Sandton",
				progress: 0.6,
			},
			{
				profile_img:
					"https://img.icons8.com/color/2x/circled-user-male-skin-type-3.png",
				name: "John Doe 2",
				tier: { label: "Gold" },
				location: "Sandton",
				progress: 0.8,
			},
		],
	},
	{
		_id: "1x2",
		timestamp: new Date().setMonth(2),
		users: [
			{
				profile_img:
					"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
				name: "John Doe",
				tier: { label: "Gold" },
				location: "Sandton",
				progress: 0.6,
			},
			{
				profile_img:
					"https://img.icons8.com/color/2x/circled-user-male-skin-type-3.png",
				name: "John Doe 2",
				tier: { label: "Gold" },
				location: "Sandton",
				progress: 0.8,
			},
		],
	},
	{
		_id: "1x3",
		timestamp: new Date().setMonth(3),
		users: [
			{
				profile_img:
					"https://img.icons8.com/color/72/circled-user-male-skin-type-5.png",
				name: "John Doe",
				tier: { label: "Gold" },
				location: "Sandton",
				progress: 0.6,
			},
			{
				profile_img:
					"https://img.icons8.com/color/2x/circled-user-male-skin-type-3.png",
				name: "John Doe 2",
				tier: { label: "Gold" },
				location: "Sandton",
				progress: 0.8,
			},
		],
	},
];

export const AttributeData = [
	{
		acronym: "TST",
		value: 25,
		label: "TEST",
	},
	{
		acronym: "TST",
		value: 65,
		label: "TEST",
	},
	{
		acronym: "TST",
		value: 120,
		label: "TEST",
	},
	{
		acronym: "TST",
		value: 25,
		label: "TEST",
	},
	{
		acronym: "TST",
		value: 65,
		label: "TEST",
	},
	{
		acronym: "TST",
		value: 120,
		label: "TEST",
	},
	{
		acronym: "TST",
		value: 25,
		label: "TEST",
	},
	{
		acronym: "TST",
		value: 65,
		label: "TEST",
	},
	{
		acronym: "TST",
		value: 120,
		label: "TEST",
	},
];
