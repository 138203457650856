import {
	Button,
	Chip,
	createStyles,
	Grid,
	IconButton,
	makeStyles,
	MenuItem,
	Select,
	Theme,
	Typography,
} from "@material-ui/core";
import { AddCircle, Edit } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import "date-fns";
import React, { useEffect, useState } from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import { getIncentiveMetrics } from "./IncentiveDetails.api";
import IncentiveMetricDialog from "./IncentiveMetricDialog/IncentiveMetricDialog";

const metricStyles = makeStyles((theme: Theme) =>
	createStyles({
		controlRow: {
			display: "flex",
			alignItems: "center",
		},
		metricActions: {
			marginLeft: "auto",
			display: "flex",
			alignItems: "center",
		},
		chipList: {
			display: "flex",
			gap: 4,
			paddingRight: 6,
			marginRight: 6,
			borderRight: `solid 1px ${theme.palette.divider}`,
		},
	})
);

type MetricBand = {
	id: number;
	band_date: string;
	from: number;
	to: number;
	multiplier: number;
	from_date: string;
	to_date: string;
};
type MetricIncentive = {
	metric_id: number;
	metric_name: string;
	game_metric: any;
	incentive_metric: any;
	metric_bands: { from_date: string; metric_bands: MetricBand[] }[];
};

const MetricBandColumns = [
	{
		title: "From",
		field: "from",
	},
	{
		title: "To",
		field: "to",
	},
	{
		title: "Multiplier",
		field: "multiplier",
	},
];
const IncentiveMetric: React.FC<{
	metric: MetricIncentive;
	editMetric: (metric: any) => void;
}> = ({ metric, editMetric }) => {
	const { metric_name, metric_bands, incentive_metric, game_metric } = metric;
	const classes = metricStyles();
	const [activeBand, setActiveBand] = useState<number>(0);

	const handleActiveBandChange = (event: any) => {
		if (event.currentTarget?.value) {
			setActiveBand(event.currentTarget.value);
		}
	};

	return (
		<Grid container>
			<Grid item xs={12} className={classes.controlRow}>
				<Typography>{metric_name}</Typography>
				<div className={classes.metricActions}>
					<div className={classes.chipList}>
						{Boolean(incentive_metric) && (
							<Chip size='small' label='Incentive' />
						)}
						{Boolean(game_metric) && (
							<Chip size='small' label='Game' />
						)}
					</div>
					<IconButton onClick={() => editMetric(metric)}>
						<Edit />
					</IconButton>
				</div>
			</Grid>
			<Grid item xs={12}>
				<Select
					label='Effective From Date'
					value={activeBand}
					onChange={handleActiveBandChange}
					fullWidth
				>
					{metric_bands.map((metric_band, index) => (
						<MenuItem
							key={`eff-option${metric_band.from_date}`}
							value={index}
						>
							{metric_band.from_date}
						</MenuItem>
					))}
				</Select>
			</Grid>
			{metric_bands[activeBand] && (
				<React.Fragment
					key={`display-${metric_bands[activeBand].from_date}`}
				>
					<Grid item xs={12}>
						<BUMaterialTable
							columns={MetricBandColumns}
							data={metric_bands[activeBand].metric_bands}
							options={{
								search: false,
								paging: false,
								toolbar: false,
								padding: "dense",
							}}
						/>
					</Grid>
				</React.Fragment>
			)}
		</Grid>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		controlRow: {
			display: "flex",
			alignItems: "center",
		},
		addMetric: {
			// marginLeft: "auto",
			// backgroundColor: theme.palette.info.main,
			// color: theme.palette.info.contrastText,
			// borderRadius: theme.shape.borderRadius,
		},
		container: {
			height: "100%",
			overflowY: "scroll",
			overflowX: "hidden",
			paddingTop: theme.spacing(2),
		},
	})
);
interface IncentiveDetailsProps {
	incentiveCategoryID: number;
}

const IncentiveDetails: React.FC<IncentiveDetailsProps> = ({
	incentiveCategoryID,
}) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [incentiveMetrics, setIncentiveMetrics] = useState<MetricIncentive[]>(
		[]
	);

	const [editMetric, setEditMetric] = useState<MetricIncentive | null>(null);
	const handleMetricEdit = (metric: any) => {
		setEditMetric({ ...metric });
	};
	const handleNewMetric = () => {
		setEditMetric({
			metric_id: -1,
			metric_name: "",
			game_metric: false,
			incentive_metric: false,
			metric_bands: [],
		});
	};

	useEffect(() => {
		let isActive = true;
		if (!incentiveCategoryID) {
			isActive && setIncentiveMetrics([]);
		}
		if (incentiveCategoryID) {
			setLoading(true);
			getIncentiveMetrics({ incentiveCategoryID })
				.then((data) => {
					isActive && setIncentiveMetrics(data);
				})
				.finally(() => setLoading(false));
		}
		return () => {
			isActive = false;
		};
	}, [incentiveCategoryID]);
	return (
		<div className={classes.container}>
			<Grid container spacing={2}>
				<Grid className={classes.controlRow} item sm={12}>
					<Button
						className={classes.addMetric}
						startIcon={<AddCircle />}
						onClick={handleNewMetric}
					>
						Add Metric
					</Button>
				</Grid>
				{!loading ? (
					incentiveMetrics.map((metric) => (
						<Grid key={metric.metric_name} item sm={4}>
							<IncentiveMetric
								metric={metric}
								editMetric={handleMetricEdit}
							/>
						</Grid>
					))
				) : (
					<>
						<Grid item sm={4}>
							<Skeleton width='40%' height={30} />
							<Skeleton width='100%' height={50} />
							<Skeleton width='100%' height={50} />
							<Skeleton width='100%' height={50} />
							<Skeleton width='100%' height={50} />
						</Grid>
						<Grid item sm={4}>
							<Skeleton width='40%' height={30} />
							<Skeleton width='100%' height={50} />
							<Skeleton width='100%' height={50} />
							<Skeleton width='100%' height={50} />
							<Skeleton width='100%' height={50} />
						</Grid>
						<Grid item sm={4}>
							<Skeleton width='40%' height={30} />
							<Skeleton width='100%' height={50} />
							<Skeleton width='100%' height={50} />
							<Skeleton width='100%' height={50} />
							<Skeleton width='100%' height={50} />
						</Grid>
					</>
				)}
			</Grid>
			{editMetric && (
				<IncentiveMetricDialog
					category_id={incentiveCategoryID}
					metric={editMetric}
					onClose={() => setEditMetric(null)}
				/>
			)}
		</div>
	);
};

export type { MetricIncentive, MetricBand };
export { MetricBandColumns };
export default IncentiveDetails;
