import {
	Checkbox,
	FormControlLabel,
	List,
	ListItem,
	TextField,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { t_SurveyRecord } from "../../../Surveys/Surveys.types";
import { getLsaRecord } from "../../LowScoreAnalysis.api";
import { t_LsaRecord } from "../../LowScoreAnalysis.type";
import lsaRecordStyles from "./LsaRecord.style";

interface Props {
	lsa_data_id: t_SurveyRecord["LsaDataID"];
}

const LsaRecord: React.FC<Props> = ({ lsa_data_id }) => {
	const classes = lsaRecordStyles();
	const [lsaRecord, setLsaRecord] = useState<t_LsaRecord | undefined>();

	useEffect(() => {
		var isActive = true;

		getLsaRecord({ lsa_data_id }).then(
			(data) => isActive && setLsaRecord(data)
		);

		return () => {
			isActive = false;
		};
	}, [lsa_data_id]);
	return (
		<List>
			{lsaRecord ? (
				<>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Stream'
							value={lsaRecord.stream?.name || "Not Completed"}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Primary Query Type'
							defaultValue={
								lsaRecord.primary_type?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Secondary Query Type'
							defaultValue={
								lsaRecord.secondary_type?.name ||
								"Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Low Score Grouping'
							defaultValue={
								lsaRecord.group?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Primary Low Score Cause'
							defaultValue={
								lsaRecord.primary_cause?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Secondary Low Score Cause'
							defaultValue={
								lsaRecord.secondary_cause?.name ||
								"Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Responsible Department'
							defaultValue={
								lsaRecord.scope?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Action Taken'
							defaultValue={
								lsaRecord.action?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<FormControlLabel
							control={
								<Checkbox
									name='landing'
									checked={lsaRecord.landing}
									disabled
								/>
							}
							label='Soft Landing'
						/>
					</ListItem>
				</>
			) : (
				<>
					<ListItem>
						<Skeleton className={classes.formControl} height={60} />
					</ListItem>
					<ListItem>
						<Skeleton className={classes.formControl} height={60} />
					</ListItem>
					<ListItem>
						<Skeleton className={classes.formControl} height={60} />
					</ListItem>
					<ListItem>
						<Skeleton className={classes.formControl} height={60} />
					</ListItem>
					<ListItem>
						<Skeleton className={classes.formControl} height={60} />
					</ListItem>
				</>
			)}
		</List>
	);
};

export default LsaRecord;
