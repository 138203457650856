import {
	createStyles,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Theme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ActivityCard from "../../components/ActivityCard/ActivityCard";
import RefreshLoader from "../../components/RefreshLoader/RefreshLoader";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import { useErrorHandler } from "../../globalContext/errorHandler.ctx";
import { useUserState } from "../../globalContext/userObject.ctx";
import {
	getNewsFeedCategories,
	getNewsFeedData,
	getNewsFeedItemComments,
	MiscBody,
	postNewsFeedComment,
	postNewsFeedCommentInteraction,
} from "./newsFeed.api";
import NewsFeedItem from "./NewsFeedItem";

export type ActivityComment = {
	id: number;
	name: string;
	profile_img: string;
	comment: string;
	like_count: number;
};
export type ActivityTier = {
	label: string;
	colour: string;
};
export type ActivityBadge = {
	name: string;
	description: string;
	badge_img: string;
};
export type ActivityChallenge = {
	_id: any;
	timestamp: number;
	users: {
		profile_img: string;
		name: string;
		tier: { label: string; colour: string };
		location: string;
		progress: number;
	}[];
};
export type ActivityData = {
	_id: any;
	name: string;
	profile_img: string;
	timestamp?: number;
	featured_comment: ActivityComment;
	activityTierChange?: ActivityTier;
	activityNewBadge?: ActivityBadge;
	activityChallenge?: ActivityChallenge;
	comments: ActivityComment[];
};
const LIKE_INTERACTION: number = 1;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			borderCollapse: "separate",
			borderSpacing: theme.spacing(0, 2),
		},
		row: {
			marginBottom: theme.spacing(2),
		},
		activityItem: {
			borderBottom: "none",
		},
	})
);

export type NewsFeedActions = {
	type:
		| "REFRESH_NEWSFEED"
		| "GET_COMMENTS"
		| "SUBMIT_COMMENT"
		| "COMMENT_INTERACTION"
		| "ITEM_INTERACTION";
	data: {
		itemId: any;
		payload?: any;
	} & MiscBody;
};

const NewsFeed = () => {
	const classes = useStyles();
	const userAuth = useUserState();
	const errorHandler = useErrorHandler();
	const tableRef = useRef<HTMLElement>(null);

	const [tabList, setTabList] = useState<{ label: string; _id: any }[]>([]);
	const [tabValue, setTabValue] = useState<number>(-1);

	const [tableData, setTableData] = useState<ActivityData[]>([]);

	const [loading, setLoading] = useState<boolean>(false);

	const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabValue(newValue);
	};
	const loadNewsfeed = useCallback(() => {
		if (tabValue >= 0) {
			setLoading(true);
			getNewsFeedData({
				category_id: tabList[tabValue]._id,
			})
				.then((data) => {
					setTableData(data ?? []);
				})
				.catch(errorHandler)
				.finally(() => setLoading(false));
		}
	}, [tabValue, tabList, errorHandler]);
	const loadNewsfeedCategories = useCallback(
		() =>
			getNewsFeedCategories({})
				.then((data) => {
					setTabList(data);
					data?.length > 0 && setTabValue(0);
				})
				.catch((error) => errorHandler(error)),
		[errorHandler]
	);
	const dispatch = (action: NewsFeedActions) => {
		const { itemId, ...misc } = action.data;

		const actions = {
			REFRESH_NEWSFEED: () => {
				setTableData([]);
				loadNewsfeed();
			},
			GET_COMMENTS: () => {
				getNewsFeedItemComments({
					itemId,
					...misc,
				})
					.then((data) => {
						const updated = tableData.map((row: ActivityData) => {
							if (row._id === itemId)
								row.comments = data.comments;
							return row;
						});
						setTableData(updated);
					})
					.catch(errorHandler);
			},
			SUBMIT_COMMENT: async (): Promise<{ success: boolean }> => {
				const { comment } = action.data.payload;
				let success: boolean = false;
				try {
					const res = await postNewsFeedComment({
						itemId,
						comment,
					});
					success = res.data.status === "success" ?? false;
				} catch (e) {
					errorHandler(e);
				}
				return { success };
			},
			COMMENT_INTERACTION: async (): Promise<{ success: boolean }> => {
				let success: boolean = false;
				try {
					const res = await postNewsFeedCommentInteraction({
						body: {
							itemId,
							interactionId: LIKE_INTERACTION,
						},
					});
					success = res.data.status === "success" ?? false;
				} catch (e) {
					errorHandler(e);
				}
				return { success };
			},
			ITEM_INTERACTION: () => {},
		};
		return actions[action.type]();
	};
	useEffect(() => loadNewsfeed(), [tabValue, loadNewsfeed]);
	useEffect(() => {
		!userAuth.status.loading && loadNewsfeedCategories();
	}, [userAuth.status.loading, loadNewsfeedCategories]);

	return (
		<SectionCard
			title='Activity'
			primaryAction={
				<TabGroup
					tabValue={tabValue}
					tabList={tabList}
					tabChange={tabChange}
				/>
			}
		>
			<RefreshLoader
				parentRef={tableRef}
				onRefresh={() =>
					dispatch({
						type: "REFRESH_NEWSFEED",
						data: { itemId: null },
					})
				}
			/>
			<Table className={classes.table}>
				<TableBody>
					{loading ? (
						<TableRow>
							<TableCell className={classes.activityItem}>
								<ActivityCard
									avatar={
										<Skeleton
											animation='wave'
											variant='circle'
											width={40}
											height={40}
										/>
									}
									name={
										<Skeleton
											animation='wave'
											height={10}
											width='20%'
											style={{
												marginBottom: 6,
											}}
										/>
									}
									activity={null}
								>
									<Skeleton
										animation='wave'
										height={30}
										/* width="80%" */ style={{
											marginBottom: 6,
										}}
									/>
								</ActivityCard>
							</TableCell>
						</TableRow>
					) : (
						tableData.map((data) => (
							<TableRow key={data._id}>
								<TableCell className={classes.activityItem}>
									<NewsFeedItem
										{...data}
										dispatch={dispatch}
									/>
								</TableCell>
							</TableRow>
						))
					)}
				</TableBody>
			</Table>
		</SectionCard>
	);
};

export default NewsFeed;
