import {
	CircularProgress,
	createStyles,
	DialogContent,
	DialogTitle,
	Grid,
	makeStyles,
	Paper,
	Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import { MetricType } from "../../pages/Home/Performance/Performance";
import { MetricRecord } from "../../pages/Home/Performance/UserMetricRecords/UserMetricRecords";
import ViewMetric from "../ViewMetric/ViewMetric";
import { getAppeal } from "./AppealDialogContent.api";
import AppealStepper from "./AppealStepper.tsx/AppealStepper";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		lsaDialog: {
			background: "none",
		},
		lsaDialogContent: {
			padding: "8px 0 0 0",
			overflow: "hidden",
		},
		lsaDialogHeader: {
			backgroundColor: theme.palette.background.paper,
		},
		lsaDialogPaper: {
			height: "100%",
		},
	})
);
type MetricAppeal = {
	appeal_data_id: number;
	data_id: number;
	expire_date: string;
	hasExpired: true;
	status_id: number;
};
interface LsaDialogProps {
	appeal: MetricAppeal;
	record: MetricRecord;
	viewMetric: MetricType;
	onClose: () => void;
}
const AppealDialogContent: React.FC<LsaDialogProps> = ({
	appeal,
	record,
	viewMetric,
	onClose,
}) => {
	const classes = useStyles();
	const [details, setDetails] = useState<any>(null);

	useEffect(() => {
		let isActive = true;
		/** Get Appeal details */
		getAppeal({ appeal_data_id: appeal.appeal_data_id }).then((data) => {
			isActive && setDetails(data);
		});
		return () => {
			isActive = false;
		};
	}, []);
	return (
		<>
			<DialogTitle
				id='form-dialog-title'
				className={classes.lsaDialogHeader}
			>
				Appeal
			</DialogTitle>
			<DialogContent
				classes={{
					root: classes.lsaDialogContent,
				}}
			>
				<Grid container spacing={2}>
					<Grid item sm={8}>
						<Paper classes={{ root: classes.lsaDialogPaper }}>
							{details ? (
								<AppealStepper
									details={details}
									appeal={appeal}
									onClose={onClose}
								/>
							) : (
								<CircularProgress />
							)}
						</Paper>
					</Grid>
					<Grid item sm={4}>
						{/* Set this section to only show the record details. The comment will be at the bottom. */}
						<SectionCard
							title='Info'
							hasBackground
							primaryAction={
								<TabGroup
									tabList={[
										{ _id: 0, label: "Survey Details" },
									]}
									tabChange={() => {}}
									tabValue={0}
								/>
							}
						>
							<ViewMetric metric={viewMetric} record={record} />
						</SectionCard>
					</Grid>
				</Grid>
			</DialogContent>
			{/* <DialogActions className={classes.lsaDialogPaper}>
						
					</DialogActions> */}
		</>
	);
};

export type { MetricAppeal };
export default AppealDialogContent;
