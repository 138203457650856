import {
	Button,
	createStyles,
	List,
	ListItem,
	makeStyles,
	Step,
	StepButton,
	StepContent,
	Stepper,
	TextField,
	Theme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useUserState } from "../../../globalContext/userObject.ctx";
import { MetricAppeal } from "../AppealDialogContent";
import { getCategories, getRemovalLevels } from "../AppealDialogContent.api";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		autoComplete: {
			width: 300,
		},
		textField: {
			width: "-webkit-fill-available",
		},
		listItem: {
			gap: 8,
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
			justifyContent: "flex-end",
			display: "flex",
			gap: "15px",
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
	})
);
interface AppealForm {
	appeal_id: any;
	appeal_date: any;
	appealed_by: any;
	comment: any;
	category: any;
	transfer_to: any;
	status_id: any;
	decision_date: any;
	decision_by: any;
	decision_comment: any;
	level: any;
	appeal_data_id: any;
	metric_id: any;
	response_id: any;
	expire_date: any;
	cost_center: any;
	entity_no: any;
}
const AppealFormDefault = {
	appeal_id: null,
	appeal_date: null,
	appealed_by: null,
	comment: null,
	category: null,
	transfer_to: null,
	status_id: null,
	decision_date: null,
	decision_by: null,
	decision_comment: null,
	level: null,
	appeal_data_id: null,
	metric_id: null,
	response_id: null,
	expire_date: null,
	cost_center: null,
	entity_no: null,
};

const AppealStepper: React.FC<{
	details: AppealForm;
	appeal: MetricAppeal;
	onClose: () => void;
}> = ({ details, appeal, onClose }) => {
	const classes = useStyles();
	const userState = useUserState();
	const [isEditing, setEditing] = useState(false);
	const [appealForm, setAppealForm] = useState<AppealForm>(details);
	const [selectLoading, setSelectLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [independent, setIndependent] = useState({
		categories: [] as any[],
		levels: [] as any[],
	});

	const jobLevel = userState.profile?.job_level ?? 1;
	const inputProps = {
		disabled: jobLevel !== 3,
		required: jobLevel === 3,
	};
	const selectProps = {
		className: classes.autoComplete,
		loading: selectLoading,
		getOptionLabel: (option: any) => option.name,
		// getOptionDisabled: (option: any) => !option.active,
	};

	const handleSelectChange = (name: string, value: any) => {
		setAppealForm((state) => ({
			...state,
			[name]: value,
		}));
	};

	/** Hooks */
	useEffect(() => {
		let isActive = true;
		Promise.all([
			getCategories({ metric_id: details.metric_id }),
			getRemovalLevels(),
		])
			.then((data) => {
				if (!isActive || data.length !== 2) return;
				setIndependent({
					categories: data[0],
					levels: data[1],
				});
			})
			.catch((error) => console.error(error));
		return () => {
			isActive = false;
		};
	}, [setIndependent]);
	return (
		<>
			{isEditing ? (
				<Stepper
					activeStep={activeStep}
					orientation='vertical'
					nonLinear
				>
					<Step completed={appealForm.category}>
						<StepButton
							onClick={() =>
								setActiveStep(0)
							} /* completed={completed[index]} */
						>
							Stream
						</StepButton>
						<StepContent>
							<Autocomplete
								{...selectProps}
								value={appealForm.category}
								options={independent.categories}
								onChange={(_, value) =>
									handleSelectChange("category", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										{...inputProps}
										label='Select Category'
										variant='outlined'
									/>
								)}
							/>
							<Autocomplete
								{...selectProps}
								value={appealForm.level}
								options={independent.levels}
								onChange={(_, value) =>
									handleSelectChange("level", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										{...inputProps}
										label='Select Removal Level'
										variant='outlined'
									/>
								)}
							/>
						</StepContent>
					</Step>
				</Stepper>
			) : (
				<List>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Appeal ID'
							value={appealForm.appeal_id}
						/>
						<TextField
							className={classes.textField}
							label='Appeal Date'
							value={appealForm.appeal_date}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Appealed By'
							value={appealForm.appealed_by?.name}
						/>
						<TextField
							className={classes.textField}
							label='Appeal Category'
							value={appealForm.category?.name}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Removal Level'
							value={appealForm.level.name}
						/>
						<TextField
							className={classes.textField}
							label='Transfer To'
							value={appealForm.transfer_to}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Decision Date'
							value={appealForm.decision_date}
						/>
						<TextField
							className={classes.textField}
							label='Decision By'
							value={appealForm.decision_by?.name}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Decision Comment'
							value={appealForm.decision_comment}
						/>
						<TextField
							className={classes.textField}
							label='Response ID'
							value={appealForm.response_id}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Cost Center'
							value={appealForm.cost_center?.name}
						/>
						<TextField
							className={classes.textField}
							label='Expiry Date'
							value={appealForm.expire_date}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField label='Comment' value={appealForm.comment} />
					</ListItem>
				</List>
			)}
			<div className={classes.actionsContainer}>
				{isEditing ? (
					<>
						<Button
							// onClick={handleClear}
							className={classes.button}
						>
							Clear
						</Button>
						<Button
							// disabled={!formEdited}
							// onClick={handleReset}
							className={classes.button}
						>
							Reset
						</Button>
						<Button
							variant='contained'
							color='primary'
							// onClick={handleSave}
							// className={classes.button}
							// disabled={!formEdited}
						>
							Save
						</Button>
					</>
				) : (
					!inputProps.disabled && (
						<>
							<Button
								onClick={() => onClose()}
								className={classes.button}
							>
								Close
							</Button>
							<Button
								variant='contained'
								color='primary'
								onClick={() => setEditing(true)}
								className={classes.button}
							>
								Edit
							</Button>
						</>
					)
				)}
			</div>
		</>
	);
};

export default AppealStepper;
