import { Avatar, Box, IconButton, Paper, useTheme } from "@material-ui/core";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import ActivityCard from "../../components/ActivityCard/ActivityCard";
import BadgeCard from "../../components/BadgeCard/BadgeCard";
import ChallengeCard from "../../components/ChallengeCard/ChallengeCard";
import LiveComments from "../../components/LiveComments/LiveComments";
import {
	ActivityBadge,
	ActivityChallenge,
	ActivityComment,
	ActivityData,
	ActivityTier,
	NewsFeedActions,
} from "./NewsFeed";

type NewsFeedItemProps = {
	dispatch: (action: NewsFeedActions) => any;
};
const NewsFeedItem: React.FC<ActivityData & NewsFeedItemProps> = (
	props: ActivityData & NewsFeedItemProps
) => {
	const {
		_id,
		name,
		profile_img,
		timestamp,
		featured_comment,
		activityTierChange,
		activityNewBadge,
		activityChallenge,
		comments,
		dispatch,
	} = props;

	const theme = useTheme();

	const [viewComments, setViewComments] = useState<{
		section: boolean;
		expanded: number;
	}>({
		section: false,
		expanded: 0,
	});
	const [activeComments, setActiveComments] = useState<ActivityComment[]>([
		featured_comment,
	]);
	const commentRef = useRef<HTMLElement>(null);

	useEffect(() => {
		setActiveComments(
			viewComments.expanded ? comments : [featured_comment]
		);
	}, [comments, featured_comment, viewComments.expanded]);

	const getComments = () =>
		dispatch({
			type: "GET_COMMENTS",
			data: {
				itemId: _id,
				pagination: { page: 1, rows_per_page: 5 },
				sort: {
					column_name: "created_at",
					order: "ASC",
				},
			},
		});

	const activityContent = (
		tier?: ActivityTier,
		badge?: ActivityBadge,
		challenge?: ActivityChallenge
	): React.ReactFragment | undefined => {
		if (tier)
			return (
				<Box component='span' display='inline'>
					<span>has just achieved</span>
					&nbsp;
					<span style={{ color: `#${tier.colour}` }}>
						{tier.label}
					</span>
				</Box>
			);
		if (badge)
			return (
				<Box component='span' display='inline'>
					received a new badge
				</Box>
			);
		if (challenge)
			return (
				<Box component='span' display='inline'>
					shared a challenge
				</Box>
			);
	};

	const handleLoadHistoric = () => {
		setViewComments((state) => ({
			...state,
			expanded: state.expanded + 1,
		}));
		dispatch({
			type: "GET_COMMENTS",
			data: {
				itemId: _id,
				pagination: {
					page: 1,
					rows_per_page: 8 * ++viewComments.expanded,
				},
				sort: {
					column_name: "created_at",
					order: "ASC",
				},
			},
		});
	};
	const handleLoadRecent = () => {
		dispatch({
			type: "GET_COMMENTS",
			data: {
				itemId: _id,
				pagination: {
					page: 1,
					rows_per_page: 8 * ++viewComments.expanded,
				},
				sort: {
					column_name: "created_at",
					order: "ASC",
				},
			},
		});
	};

	// const handleLoadClick = () => {
	// 	setViewComments(({ expanded, section }) => {
	// 		if (expanded) {
	// 			setActiveComments([featured_comment]);
	// 		} else {
	// 			getComments();
	// 		}
	// 		return { expanded: 0, section };
	// 	});
	// };
	const handleCommentSubmit = (comment: string) => {
		dispatch({
			type: "SUBMIT_COMMENT",
			data: { itemId: _id, payload: { comment } },
		}).then((res: { success: boolean }) => {
			if (res.success) {
				if (viewComments.expanded) {
					getComments();
				} else {
					dispatch({
						type: "REFRESH_NEWSFEED",
						data: {
							itemId: _id,
						},
					});
				}
			}
		});
	};
	const handleCommentInteractionClick = (commentId: number) => {
		dispatch({
			type: "COMMENT_INTERACTION",
			data: { itemId: commentId },
		}).then((res: { success: boolean }) => {
			if (res.success) {
				setActiveComments((state) =>
					state.map((comment) => ({
						...comment,
						like_count:
							comment.id === commentId
								? comment.like_count > 0
									? 0
									: 1
								: comment.like_count,
					}))
				);
			}
		});
	};
	const handleItemInteractionClick = () => {
		dispatch({
			type: "ITEM_INTERACTION",
			data: { itemId: _id },
		}).then((res: { success: boolean }) => {});
	};
	return (
		<ActivityCard
			avatar={<Avatar src={profile_img} />}
			name={name}
			activity={activityContent(
				activityTierChange,
				activityNewBadge,
				activityChallenge
			)}
			timestamp={timestamp ? new Date(timestamp) : undefined}
			onHeartClick={handleItemInteractionClick}
			onProfileClick={() =>
				setViewComments((state) => ({
					section: !state.section,
					expanded: 0,
				}))
			}
		>
			<React.Fragment>
				{activityNewBadge && (
					<BadgeCard
						style={{
							backgroundColor: theme.palette.secondary.main,
							color: theme.palette.secondary.contrastText,
						}}
						title={activityNewBadge.name}
						subtitle={activityNewBadge.description}
						avatar={
							<Avatar
								src={activityNewBadge.badge_img}
								style={{
									backgroundColor:
										theme.palette.secondary.main,
								}}
							/>
						}
					/>
				)}
				{activityChallenge && (
					<Paper
						style={{
							backgroundColor: theme.palette.secondary.main,
							color: theme.palette.secondary.contrastText,
						}}
					>
						<ChallengeCard
							lhs={{
								...activityChallenge.users[0],
								avatar: (
									<Avatar
										src={
											activityChallenge.users[0]
												.profile_img
										}
									/>
								),
							}}
							rhs={{
								...activityChallenge.users[1],
								avatar: (
									<Avatar
										src={
											activityChallenge.users[0]
												.profile_img
										}
									/>
								),
							}}
							timestamp={new Date(activityChallenge.timestamp)}
						/>
					</Paper>
				)}
				{viewComments.section && (
					<React.Fragment>
						<LiveComments
							inputStyle={{
								backgroundColor: theme.palette.secondary.main,
								color: theme.palette.secondary.contrastText,
							}}
							comments={activeComments
								.reverse()
								.map((comment) => ({
									id: comment.id,
									avatar: (
										<Avatar src={comment.profile_img} />
									),
									author: comment.name,
									content: comment.comment,
									action: (
										<IconButton
											onClick={() =>
												handleCommentInteractionClick(
													comment.id
												)
											}
										>
											{comment.like_count > 0 ? (
												<Favorite />
											) : (
												<FavoriteBorder />
											)}
										</IconButton>
									),
								}))}
							commentWrapperRef={commentRef}
							onComment={handleCommentSubmit}
							loadHistoric={handleLoadHistoric}
							loadRecent={handleLoadRecent}
						></LiveComments>
						{/* <Button fullWidth onClick={handleLoadClick}>
							{viewComments.expanded
								? "Read less..."
								: "Read more..."}
						</Button> */}
					</React.Fragment>
				)}
			</React.Fragment>
		</ActivityCard>
	);
};

export default NewsFeedItem;
