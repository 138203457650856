import {
	createStyles,
	InputBase,
	makeStyles,
	Select,
	SelectProps,
	Theme,
	withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

const BootstrapInput = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"label + &": {
				marginTop: theme.spacing(3),
			},
			borderRadius: theme.spacing(1),
		},
		input: {
			borderRadius: "inherit",
			position: "relative",
			backgroundColor: theme.palette.background.paper,
			// border: "1px solid #ced4da",
			fontSize: 14,
			padding: "10px 26px 10px 12px",
			transition: theme.transitions.create([
				"border-color",
				"box-shadow",
			]),
			// Use the system font instead of the default Roboto font.
			"&:focus": {
				borderRadius: "inherit",
				borderColor: "#80bdff",
				boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
			},
		},
	})
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: theme.spacing(1),
			minWidth: 120,
		},
		icon: {
			right: theme.spacing(1),
			top: "50%",
			transform: "translateY(-50%)",
		},
	})
);

const DropdownSelect = (props: SelectProps) => {
	const classes = useStyles();
	return (
		<Select
			{...props}
			input={<BootstrapInput />}
			IconComponent={ExpandMore}
			className={classes.root}
			classes={{ icon: classes.icon }}
		/>
	);
};

export default DropdownSelect;
