import { Button, Grid } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { Options } from "material-table";
import React, { useEffect, useState } from "react";
import GroupedHeaderTable from "../../../components/GroupedHeaderTable/GroupedHeaderTable";
import SectionCard from "../../../components/SectionCard/SectionCard";
import TabGroup from "../../../components/TabGroup/TabGroup";
import { t_SurveyPeriod } from "../../Surveys/Surveys.types";
import { SurveySummaryColumns } from "../PerceptionReport.api";
import { getSurveySummaryRecords } from "./PerceptionSurveySummery.api";

interface SurveySummaryProps {
	survey: "mbr" | "fcr";
	period: t_SurveyPeriod;
}

type SurveyRecord = {
	survey_n: number;
	score_avg: number;
	lsa_n: number;
	approved_ratio: number;
	appeals_n: number;
	capture_ratio: number;
};

const TableOptions: Options<any> = {
	padding: "dense",
	toolbar: false,
};
const PerceptionSurveySummary: React.FC<SurveySummaryProps> = ({
	survey,
	period,
}) => {
	const [tabValue, setTabValue] = useState(0);
	const [surveyRecords, setSurveyRecords] = useState<{
		survey: SurveyRecord[];
		service_team: Array<{ service_team: string } & t_SurveyPeriod>;
		team: Array<{ team: string } & t_SurveyPeriod>;
		job_title: Array<{ job_title: string } & t_SurveyPeriod>;
	}>({
		survey: [],
		service_team: [],
		team: [],
		job_title: [],
	});

	useEffect(() => {
		let isActive = true;
		getSurveySummaryRecords({ survey, period }).then(
			(data) => isActive && setSurveyRecords(data)
		);

		return () => {
			isActive = false;
		};
	}, [period]);
	return (
		<SectionCard
			title={survey.toUpperCase()}
			titleProps={{ variant: "subtitle1" }}
			primaryAction={
				<TabGroup
					tabValue={tabValue}
					tabChange={(_, value) => setTabValue(value)}
					tabList={[
						{ _id: 0, label: "Summary" },
						{ _id: 1, label: "Details" },
					]}
				/>
			}
			secondaryAction={
				<Button variant='text' endIcon={<OpenInNew />}>
					Report
				</Button>
			}
			fullWidth
		>
			<Grid container spacing={0}>
				{tabValue == 0 && (
					<Grid item sm={12}>
						<SectionCard
							title={`Summary`}
							titleProps={{ variant: "subtitle2" }}
							fullWidth
						>
							<GroupedHeaderTable
								columns={SurveySummaryColumns}
								data={surveyRecords.survey}
								options={{
									...TableOptions,
									search: false,
									paging: false,
									sorting: false,
								}}
							/>
						</SectionCard>
					</Grid>
				)}
				{tabValue == 1 && (
					<>
						<Grid item sm={12}>
							<SectionCard
								title={"Service Team"}
								titleProps={{ variant: "subtitle2" }}
								fullWidth
							>
								<GroupedHeaderTable
									columns={[
										{
											header: "Service Team",
											field: "service_team",
										},
										...SurveySummaryColumns,
									]}
									data={surveyRecords.service_team}
									options={{
										...TableOptions,
									}}
								/>
							</SectionCard>
						</Grid>
						<Grid item sm={12}>
							<SectionCard
								title={"Team"}
								titleProps={{ variant: "subtitle2" }}
								fullWidth
							>
								<GroupedHeaderTable
									columns={[
										{
											header: "Team",
											field: "team",
										},
										...SurveySummaryColumns,
									]}
									data={surveyRecords.team}
									options={{
										...TableOptions,
									}}
								/>
							</SectionCard>
						</Grid>
						<Grid item sm={12}>
							<SectionCard
								title={"Job Title"}
								titleProps={{ variant: "subtitle2" }}
								fullWidth
							>
								<GroupedHeaderTable
									columns={[
										{
											header: "Job Title",
											field: "job_title",
										},
										...SurveySummaryColumns,
									]}
									data={surveyRecords.job_title}
									options={{
										...TableOptions,
									}}
								/>
							</SectionCard>
						</Grid>
					</>
				)}
			</Grid>
		</SectionCard>
	);
};

export default PerceptionSurveySummary;
