import { createStyles, makeStyles, Theme } from "@material-ui/core";

const performanceStyles = makeStyles((theme: Theme) =>
	createStyles({
		dateSelection: {
			marginLeft: "auto",
			display: "flex",
			justifyContent: "space-evenly",
			gap: 10,
		},
		dateToggleButton: {
			borderRadius: theme.spacing(1),
		},
		dateRangeWrapper: {
			position: "absolute",
			right: theme.spacing(1),
			zIndex: theme.zIndex.modal,
		},
		capitalize: {
			textTransform: "capitalize",
		},
	})
);

export default performanceStyles;
