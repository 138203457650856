import { createStyles, makeStyles, Theme } from "@material-ui/core";

const groupedHeaderTableStyle = makeStyles((theme: Theme) =>
	createStyles({
		groupContainer: {
			width: "100%",
			display: "inline-flex",
			alignItems: "flex-start",
			flexDirection: "column",
		},
		toggleButton: {
			border: "none",
			borderRadius: theme.spacing(0.5),
			padding: theme.spacing(0.5),
			paddingTop: 0,
			paddingBottom: 0,
		},
		activeButton: {
			backgroundColor: `${theme.palette.info.main} !important`,
			color: `${theme.palette.info.contrastText} !important`,
		},
	})
);

export { groupedHeaderTableStyle };
