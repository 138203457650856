import {
	Checkbox,
	FormControlLabel,
	List,
	ListItem,
	StepButton,
	TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import Stepper from "@material-ui/core/Stepper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useUserState } from "../../../globalContext/userObject.ctx";
import { MetricLsa } from "../LsaDialogContent";
import {
	getLsaActions,
	getLsaGrouping,
	getLsaPrimaryCauses,
	getLsaPrimaryTypes,
	getLsaResponsibleScopes,
	getLsaSecondaryCauses,
	getLsaSecondaryTypes,
	getLsaStreams,
	saveLowScoreAnalysis,
} from "./LsaStepper.api";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
			justifyContent: "flex-end",
			display: "flex",
			gap: "15px",
		},
		resetContainer: {
			padding: theme.spacing(3),
		},
		formControl: {
			width: 300,
		},
		autoComplete: {
			width: 300,
		},
	})
);

interface LsaForm {
	lsa_id?: number;
	landing: boolean;
	stream: any;
	scope: any;
	action: any;
	group: any;
	primary_type: any;
	primary_cause: any;
	secondary_type: any;
	secondary_cause: any;
}
const LsaFormDefault = {
	landing: false,
	stream: null,
	scope: null,
	action: null,
	group: null,
	primary_type: null,
	primary_cause: null,
	secondary_type: null,
	secondary_cause: null,
};

const LsaStepper: React.FC<{
	details: LsaForm;
	lsa: MetricLsa;
	onClose: () => void;
}> = ({ details, lsa, onClose }) => {
	/** STATES */
	const classes = useStyles();
	const userState = useUserState();
	const [activeStep, setActiveStep] = React.useState(0);
	const [independent, setIndependent] = useState({
		streams: [] as any[],
		scopes: [] as any[],
		actions: [] as any[],
	});
	const [dependant, setDependant] = useState({
		group: [] as any[],
		primary_type: [] as any[],
		primary_cause: [] as any[],
		secondary_type: [] as any[],
		secondary_cause: [] as any[],
	});
	const [selectLoading, setSelectLoading] = useState(false);
	const [isEditing, setEditing] = useState(false);
	const [formEdited, setFormEdited] = useState(false);

	const [lsaForm, setLsaForm] = useState<LsaForm>(details);

	/** ON RENDER */
	const jobLevel = userState.profile?.job_level ?? 1;
	const inputProps = {
		disabled: jobLevel !== 3,
		required: jobLevel === 3,
	};
	const selectProps = {
		className: classes.autoComplete,
		loading: selectLoading,
		getOptionLabel: (option: any) => option.name,
		getOptionDisabled: (option: any) => !option.active,
	};

	/** METHODS */
	const handleSave = () => {
		if (formEdited) {
			console.log({ lsaForm, lsa });
			const lsa_id = lsaForm.lsa_id;

			if (lsa_id) {
				saveLowScoreAnalysis({ lsa_id, ...lsaForm }).then((res) =>
					console.log(res)
				);
			}
		}
		// setEditing(false);
	};

	const handleClose = () => {
		onClose();
	};

	const handleClear = () => {
		setLsaForm((state) => ({ ...LsaFormDefault, lsa_id: state.lsa_id }));
		setActiveStep(0);
	};

	const handleReset = () => {
		setLsaForm(details);
	};

	const handleStep = (index: number) => {
		setActiveStep(index);
	};

	/** @MM Convert to using key's to force re-render: https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value */
	const resetDependents = (name: string) => {
		switch (name) {
			case "stream":
				return {
					group: null,
					primary_type: null,
					primary_cause: null,
					secondary_type: null,
					secondary_cause: null,
				};
			case "group":
				return {
					primary_cause: null,
				};
			case "primary_type":
				return {
					secondary_type: null,
				};
			case "primary_cause":
				return {
					secondary_cause: null,
				};
			default:
				return {};
		}
	};
	const handleSelectChange = (name: string, value: any) => {
		setLsaForm((state) => {
			const input = Object.entries(state).find(([index, _]) => {
				return index === name;
			});
			if (input) {
				const [_, current] = input;

				if (current !== value) {
					!formEdited && setFormEdited(true);
					const reset = resetDependents(name);
					return {
						...state,
						...reset,
						[name]: value,
					};
				}
			}
			return state;
		});
	};
	const handleLoad = (name: string) => {
		setSelectLoading(true);
		(async () => {
			let options: any[] | null = null;
			switch (name) {
				case "group":
					options = await getLsaGrouping({
						stream_id: lsaForm.stream?.stream_id,
					});
					break;
				case "primary_type":
					options = await getLsaPrimaryTypes({
						stream_id: lsaForm.stream?.stream_id,
					});
					break;
				case "primary_cause":
					options = await getLsaPrimaryCauses({
						stream_id: lsaForm.stream?.stream_id,
						group_id: lsaForm.group?.group_id,
					});
					break;
				case "secondary_type":
					options = await getLsaSecondaryTypes({
						stream_id: lsaForm.stream?.stream_id,
						primary_query_id:
							lsaForm.primary_type?.primary_query_id,
					});
					break;
				case "secondary_cause":
					options = await getLsaSecondaryCauses({
						stream_id: lsaForm.stream?.stream_id,
						primary_cause_id:
							lsaForm.primary_cause?.primary_cause_id,
					});
					break;
				default:
					break;
			}
			if (options) {
				setDependant((state) => ({ ...state, [name]: options }));
			}
		})()
			.catch((error) => console.error(error))
			.finally(() => setSelectLoading(false));
	};

	/** HOOKS */
	useEffect(() => {
		let isActive = true;
		Promise.all([
			getLsaStreams(),
			getLsaResponsibleScopes(),
			getLsaActions(),
		])
			.then((data) => {
				if (!isActive || data.length !== 3) return;
				setIndependent({
					streams: data[0],
					scopes: data[1],
					actions: data[2],
				});
			})
			.catch((error) => console.error(error));
		return () => {
			isActive = false;
		};
	}, [setIndependent]);

	return (
		<div className={classes.root}>
			{isEditing ? (
				<Stepper
					activeStep={activeStep}
					orientation='vertical'
					nonLinear
				>
					<Step completed={lsaForm.stream}>
						<StepButton
							onClick={() =>
								handleStep(0)
							} /* completed={completed[index]} */
						>
							Stream
						</StepButton>
						<StepContent>
							<Autocomplete
								{...selectProps}
								value={lsaForm.stream}
								options={independent.streams}
								onChange={(_, value) =>
									handleSelectChange("stream", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										{...inputProps}
										label='Select Stream'
										variant='outlined'
									/>
								)}
							/>
						</StepContent>
					</Step>
					<Step
						completed={
							lsaForm.primary_type && lsaForm.secondary_type
						}
					>
						<StepButton
							onClick={() =>
								handleStep(1)
							} /* completed={completed[index]} */
						>
							Query Types
						</StepButton>
						{/* <StepLabel>Query Types</StepLabel> */}
						<StepContent>
							<Autocomplete
								{...selectProps}
								value={lsaForm.primary_type}
								options={dependant.primary_type}
								onFocus={() => handleLoad("primary_type")}
								onChange={(_, value) =>
									handleSelectChange("primary_type", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Primary Query Type'
										variant='outlined'
									/>
								)}
							/>
							<Autocomplete
								{...selectProps}
								value={lsaForm.secondary_type}
								options={dependant.secondary_type}
								onFocus={() => handleLoad("secondary_type")}
								onChange={(_, value) =>
									handleSelectChange("secondary_type", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Secondary Query Type'
										variant='outlined'
									/>
								)}
							/>
						</StepContent>
					</Step>
					<Step
						completed={
							lsaForm.group &&
							lsaForm.primary_cause &&
							lsaForm.secondary_cause
						}
					>
						<StepButton
							onClick={() =>
								handleStep(2)
							} /* completed={completed[index]} */
						>
							Low Score
						</StepButton>
						<StepContent>
							<Autocomplete
								{...selectProps}
								value={lsaForm.group}
								options={dependant.group}
								onFocus={() => handleLoad("group")}
								onChange={(_, value) =>
									handleSelectChange("group", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Low Score Grouping'
										variant='outlined'
									/>
								)}
							/>
							<Autocomplete
								{...selectProps}
								value={lsaForm.primary_cause}
								options={dependant.primary_cause}
								onFocus={() => handleLoad("primary_cause")}
								onChange={(_, value) =>
									handleSelectChange("primary_cause", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Primary Low Score Cause'
										variant='outlined'
									/>
								)}
							/>
							<Autocomplete
								{...selectProps}
								value={lsaForm.secondary_cause}
								options={dependant.secondary_cause}
								onFocus={() => handleLoad("secondary_cause")}
								onChange={(_, value) =>
									handleSelectChange("secondary_cause", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Secondary Low Score Cause'
										variant='outlined'
									/>
								)}
							/>
						</StepContent>
					</Step>
					<Step completed={lsaForm.scope}>
						<StepButton
							onClick={() =>
								handleStep(3)
							} /* completed={completed[index]} */
						>
							Responsible Department
						</StepButton>
						<StepContent>
							<Autocomplete
								{...selectProps}
								value={lsaForm.scope}
								options={independent.scopes}
								onChange={(_, value) =>
									handleSelectChange("scope", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Select Department'
										variant='outlined'
									/>
								)}
							/>
						</StepContent>
					</Step>
					<Step completed={lsaForm.action}>
						<StepButton
							onClick={() =>
								handleStep(4)
							} /* completed={completed[index]} */
						>
							Action Taken
						</StepButton>
						<StepContent>
							<Autocomplete
								{...selectProps}
								value={lsaForm.action}
								options={independent.actions}
								onChange={(_, value) =>
									handleSelectChange("action", value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Select Action'
										variant='outlined'
									/>
								)}
							/>
							<FormControlLabel
								control={
									<Checkbox
										name='landing'
										checked={lsaForm.landing}
										onChange={(_, checked) =>
											handleSelectChange(
												"landing",
												checked
											)
										}
									/>
								}
								label='Soft Landing'
							/>
						</StepContent>
					</Step>
				</Stepper>
			) : (
				<List>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Stream'
							value={lsaForm.stream?.name || "Not Completed"}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Primary Query Type'
							defaultValue={
								lsaForm.primary_type?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Secondary Query Type'
							defaultValue={
								lsaForm.secondary_type?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Low Score Grouping'
							defaultValue={
								lsaForm.group?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Primary Low Score Cause'
							defaultValue={
								lsaForm.primary_cause?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Secondary Low Score Cause'
							defaultValue={
								lsaForm.secondary_cause?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Responsible Department'
							defaultValue={
								lsaForm.scope?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<TextField
							className={classes.formControl}
							label='Action Taken'
							defaultValue={
								lsaForm.action?.name || "Not Completed"
							}
							InputProps={{
								readOnly: true,
							}}
						/>
					</ListItem>
					<ListItem>
						<FormControlLabel
							control={
								<Checkbox
									name='landing'
									checked={lsaForm.landing}
									disabled
								/>
							}
							label='Soft Landing'
						/>
					</ListItem>
				</List>
			)}

			<div className={classes.actionsContainer}>
				{isEditing ? (
					<>
						<Button
							onClick={handleClear}
							className={classes.button}
						>
							Clear
						</Button>
						<Button
							disabled={!formEdited}
							onClick={handleReset}
							className={classes.button}
						>
							Reset
						</Button>
						<Button
							variant='contained'
							color='primary'
							onClick={handleSave}
							className={classes.button}
							disabled={!formEdited}
						>
							Save
						</Button>
					</>
				) : (
					!inputProps.disabled && (
						<>
							<Button
								onClick={handleClose}
								className={classes.button}
							>
								Close
							</Button>
							<Button
								variant='contained'
								color='primary'
								onClick={() => setEditing(true)}
								className={classes.button}
							>
								Edit
							</Button>
						</>
					)
				)}
			</div>
			{/* {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          
        </Paper>
      )} */}
		</div>
	);
};

export { LsaFormDefault };
export default LsaStepper;
