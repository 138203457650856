import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	createStyles,
	List,
	ListItem,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import BadgeCard from "../../components/BadgeCard/BadgeCard";
import ChallengeCard from "../../components/ChallengeCard/ChallengeCard";
import ProgressList from "../../components/ProgressList/ProgressList";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import {
	useUserDispatch,
	useUserState,
} from "../../globalContext/userObject.ctx";
import { getUserBadges, getUserChallengeHistory } from "./profileSummary.api";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		profileCard: {
			width: "100%",
		},
		challengeCard: {
			width: "100%",
		},
		largeAvatar: {
			height: theme.spacing(12),
			width: theme.spacing(12),
		},
		tabCard: {
			width: "100%",
		},
		tabContent: {
			height: "100%",
			width: "100%",
			overflow: "scroll",
		},
		logoutButton: {
			padding: theme.spacing(1, 2),
			marginLeft: "auto",
		},
	})
);

const ProfileSummaryCategories = [
	{
		_id: "your_profile",
		label: "Profile",
	},
	{
		_id: "your_badges",
		label: "Badges",
	},
];
type Challenge = {
	_id: any;
	users: UserDetails[];
	winner_index: number;
	user_index: number;
	timestamp: number;
	active: number;
	health: number;
};
type Badge = {
	_id: string;
	badge_img: string;
	name: string;
	description: string;
};

type UserDetails = {
	name: string;
	job_title: string;
	tier: {
		colour: string;
		label: string;
	};
	profile_img: string;
	location: string;
	progress: number;
};
const ProfileSummary: React.FC = () => {
	const classes = useStyles();

	const user = useUserState();
	const userDispatch = useUserDispatch();
	const history = useHistory();
	const [tabValue, setTabValue] = useState<number>(0);
	const [userChallenges, setUserChallenges] = useState<Challenge[]>();
	const [userBadges, setUserBadges] = useState<Badge[]>([]);

	const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabValue(newValue);
	};

	const handleLogout = () => {
		userDispatch({ type: "LOGOUT" });
		history.push("/login");
	};

	useEffect(() => {
		let isActive = true;
		if (user.profile) {
			switch (tabValue) {
				case 0:
					getUserChallengeHistory().then((data: any) => {
						const challenges: Challenge[] = data.map(
							(challenge: any) => ({
								...challenge,
								_id: challenge.id,
								users: challenge.users.map((user: any) => ({
									...user,
									location: user.region,
									progress: 0.5,
								})),
							})
						);
						isActive && setUserChallenges(challenges);
					});
					break;
				case 1:
					getUserBadges().then((data: any[]) => {
						isActive &&
							setUserBadges(
								data.map((badge) => ({
									...badge,
									_id: badge.id,
								}))
							);
					});
					break;
				default:
					break;
			}
		}

		return () => {
			isActive = false;
		};
	}, [tabValue, user.profile]);

	return (
		<SectionCard
			title='Your Profile'
			fullWidth
			primaryAction={
				<TabGroup
					tabValue={tabValue}
					tabChange={tabChange}
					tabList={ProfileSummaryCategories}
				/>
			}
			bottomActions={
				<Button className={classes.logoutButton} onClick={handleLogout}>
					Logout
				</Button>
			}
			className={classes.tabCard}
		>
			<List className={classes.tabContent}>
				{tabValue <= 0 && (
					<>
						<ListItem>
							<Card className={classes.profileCard}>
								<CardHeader
									title={
										<Typography variant='h5'>
											{user.profile?.name}
										</Typography>
									}
									subheader={
										<React.Fragment>
											<Typography variant='subtitle2'>
												{user.profile?.job_title}
											</Typography>
											<Typography variant='subtitle1'>
												{user.profile?.tier.label}
											</Typography>
										</React.Fragment>
									}
									avatar={
										<Avatar
											src={user.profile?.profile_img}
											className={classes.largeAvatar}
										/>
									}
								/>
								<CardContent>
									<ProgressList
										items={user.metric_values.map(
											(metric) => ({
												...metric,
												progress:
													(metric.value /
														metric.details.target) *
													100,
												label: metric.name,
												colour:
													metric.value < 100
														? metric.value < 30
															? "red"
															: "yellow"
														: "green",
											})
										)}
										baseline={100}
										total={150}
									/>
								</CardContent>
							</Card>
						</ListItem>
						<ListItem>
							<List>
								<ListItem>
									<Typography variant='subtitle1'>
										Challenge History
									</Typography>
								</ListItem>
								{userChallenges?.map((challenge) => (
									<ListItem
										disableGutters
										key={challenge._id}
									>
										<Card>
											<CardContent>
												<ChallengeCard
													className={
														classes.challengeCard
													}
													timestamp={
														new Date(
															challenge.timestamp
														)
													}
													lhs={{
														...challenge.users[0],
														avatar: (
															<Avatar
																src={
																	challenge
																		.users[0]
																		.profile_img
																}
															/>
														),
													}}
													rhs={{
														...challenge.users[1],
														avatar: (
															<Avatar
																src={
																	challenge
																		.users[1]
																		.profile_img
																}
															/>
														),
													}}
												/>
											</CardContent>
										</Card>
									</ListItem>
								))}
							</List>
						</ListItem>
					</>
				)}
				{tabValue === 1 &&
					userBadges.map((badge) => (
						<ListItem key={badge._id} disableGutters>
							<BadgeCard
								avatar={<Avatar src={badge.badge_img} />}
								title={badge.name}
								subtitle={badge.description}
							/>
						</ListItem>
					))}
			</List>
		</SectionCard>
	);
};

export default ProfileSummary;
