import {
	Avatar,
	Button,
	Card,
	CardContent,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BadgeCardProps } from "../../components/BadgeCard/BadgeCard";
import BadgeList from "../../components/BadgeList/BadgeList";
import { userEndpoints } from "../../misc/endpoints";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		action: {
			color: theme.palette.info.main,
			"& > *": { margin: theme.spacing(0) },
		},
		badgeAvatar: {
			width: 80,
			height: 80,
		},
	})
);

const ProfileBadges = () => {
	const classes = useStyles();

	const [badges, setBadges] = useState<BadgeCardProps[]>([]);

	const handleViewClick = (event: React.ChangeEvent<{}>) => {};

	useEffect(() => {
		userEndpoints.getUserBadges().then((data) =>
			setBadges(
				data.map((badge) => ({
					avatar: (
						<Avatar
							className={classes.badgeAvatar}
							src={badge.badge_img}
						/>
					),
					title: badge.name,
					subtitle: badge.description,
				}))
			)
		);
	}, [setBadges, classes.badgeAvatar]);

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} container justify='space-between'>
				<Typography variant='h5'>Your Badges</Typography>
				<Button className={classes.action} onClick={handleViewClick}>
					View All
				</Button>
			</Grid>
			<Grid item sm={12}>
				<Card>
					<CardContent>
						<BadgeList badges={badges} justify='flex-start' />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default ProfileBadges;
