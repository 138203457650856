import { Column } from "material-table";
import { httpClient } from "../../../../globalApi/httpClient.api";
import { UserProfile } from "../../../../globalContext/userObject.ctx";
import { MiscBody } from "../../../NewsFeed/newsFeed.api";
import { t_MetricPerformance } from "../../Performance.types";

type t_TeamMember = {
	user: UserProfile;
	metrics: t_MetricPerformance[];
};

const MemberMetricColumns = (for_metrics: string[]): Column<t_TeamMember>[] =>
	for_metrics.map((metric) => ({
		title: metric,
		field: `metrics.${metric}.value`,
		emptyValue: "-",
	}));
const getTeamPerformance = async (payload: MiscBody) => {
	const { ...misc } = payload;
	let body = {};

	if (misc) body = { ...body, ...misc };

	const response = await httpClient.post("/metrics/summary/team", body);
	return response.data.data.team_metrics;
	// return response.data.data.team_metrics?.map((row: any) => ({
	// 	...row,
	// 	user: {
	// 		...row.user,
	// 		_id: row.user.id,
	// 	},
	// }));
};

export type { t_TeamMember };
export { getTeamPerformance, MemberMetricColumns };
