import { Grid, Typography } from "@material-ui/core";
import { ArrowDropUp } from "@material-ui/icons";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";

interface UserMetricHighlightsProps {
	comparison: string;
	selectedDate: Date;
	viewMetric: any;
}
const UserMetricHighlights: React.FC<UserMetricHighlightsProps> = ({
	comparison,
	selectedDate,
	viewMetric,
}) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<SectionCard
					title={`${comparison}'s Growth`}
					titleProps={{
						variant: "h6",
					}}
					hasBackground
				>
					<Grid
						container
						spacing={1}
						justify='center'
						alignItems='center'
					>
						<ArrowDropUp fontSize='large' />
						<Typography variant='h4'>
							{Math.round(Math.random() * 10)}
						</Typography>
					</Grid>
				</SectionCard>
			</Grid>
			<Grid item xs={6}>
				<SectionCard
					title={`${viewMetric.name}'s Progress`}
					titleProps={{
						variant: "h6",
					}}
					hasBackground
				>
					<Grid
						container
						spacing={1}
						justify='center'
						alignItems='center'
					>
						<Typography variant='h4'>
							{`${viewMetric.value}/${viewMetric.details?.target}`}
						</Typography>
					</Grid>
				</SectionCard>
			</Grid>
			<Grid item xs={12}>
				<SectionCard
					title={`${viewMetric.name}'s Best ${comparison}`}
					titleProps={{
						variant: "h6",
					}}
					hasBackground
				>
					<Grid
						container
						spacing={1}
						justify='center'
						alignItems='center'
					>
						<Typography variant='h5'>
							{selectedDate?.toDateString()}
						</Typography>
					</Grid>
				</SectionCard>
			</Grid>
		</Grid>
	);
};

export default UserMetricHighlights;
