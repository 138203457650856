import { Palette } from "@material-ui/core/styles/createPalette";
import { Overrides } from "@material-ui/core/styles/overrides";

const customOverrides = (palette: Palette): Overrides => {
	const scrollbarBG = palette.background.default;
	const thumbBG = palette.grey[900];
	return {
		MuiCssBaseline: {
			"@global": {
				"*": {
					scrollbarWidth: "thin",
					scrollbarColor: `${thumbBG} ${scrollbarBG}`,
					// /* Works on Chrome, Edge, and Safari */
					"&::-webkit-scrollbar": {
						width: "8px",
						height: "8px",
					},

					"&::-webkit-scrollbar-track": {
						background: `${scrollbarBG}`,
						borderRadius: 11,
					},
					"&::-webkit-scrollbar-corner": {
						display: "none",
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: `${thumbBG}`,
						borderRadius: "6px",
						border: `1px solid ${scrollbarBG}`,
					},
					"&::-webkit-scrollbar-button": {
						display: "none",
					},
				},
				body: {
					overflow: "hidden",
				},
			},
		},
		MuiIconButton: {
			root: {
				padding: 4,
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: 8,
			},
			input: {
				padding: "15px 12px",
			},
		},
		MuiStepIcon: {
			completed: {
				color: `${palette.info.main} !important`,
			},
		},
		MuiTab: {
			root: {
				borderRadius: 8,
				"&.Mui-selected": {
					backgroundColor: palette.info.main,
					color: palette.info.contrastText,
				},
				width: "max-content",
				padding: "8px 12px",
				minWidth: "0 !important",
				minHeight: 42,
			},
		},
	};
};

export default customOverrides;
