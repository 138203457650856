import { httpClient } from "../../globalApi/httpClient.api";
import { MiscBody } from "../NewsFeed/newsFeed.api";

const getLeaderBoardData = async (payload: { category_id: any } & MiscBody) => {
	const { category_id, ...misc } = payload;
	const body = {
		category_id,
		...misc,
	};

	const response = await httpClient.post("/leaderboard", body);
	return response.data.data.leaderboard?.map((user: any) => ({
		...user,
		_id: user.id,
	}));
};

const getLeaderBoardCategories = async (): Promise<
	{
		label: string;
		_id: any;
	}[]
> => {
	const body = {
		section: "leaderboard",
	};

	const response = await httpClient.post("/categories", body);
	return response.data.data.categories?.map(
		(category: { id: number; Name: string }) => ({
			_id: category.id,
			label: category.Name,
		})
	);
};

export { getLeaderBoardData, getLeaderBoardCategories };
