import {
	ArgumentAxis,
	BarSeries,
	Chart,
	LineSeries,
	Title,
	ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Grid, Paper } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useEffect, useState } from "react";
import BUMaterialTable from "../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import { getUsageRecords, getUsageStats } from "./UsageReport.api";

type UsageRecord = {
	name: any;
	entity_no: any;
	skill: any;
	service_team: any;
	manager_name: any;
	login_count: any;
	rank: any;
	wr: any;
};

const UsageTable: React.FC<{ date: Date | null }> = ({ date }) => {
	const [records, setRecords] = useState<UsageRecord[]>([]);

	useEffect(() => {
		if (date !== null) {
			const from_date = new Date(
				date.getFullYear(),
				date.getMonth(),
				1
			).toISOString();
			const to_date = new Date(
				date.getFullYear(),
				date.getMonth() + 1,
				0
			).toISOString();
			getUsageRecords({ from_date, to_date }).then((data) => {
				setRecords(data);
			});
		}
	}, [date]);

	return (
		<BUMaterialTable
			title='Individual Usage Report'
			columns={[
				{
					title: "Name",
					field: "name",
					render: (rowData) =>
						`${rowData.name} (${rowData.entity_no})`,
				},
				{ title: "Skill", field: "skill" },
				{
					title: "Service Team",
					field: "service_team",
				},
				{
					title: "Manager Name",
					field: "manager_name",
				},
				{
					title: "Logins",
					field: "login_count",
					type: "numeric",
				},
				{
					title: "Rank",
					field: "rank",
					type: "numeric",
				},
				{
					title: "Win Rate",
					field: "wr",
					type: "numeric",
					render: (rowData) => `${rowData.wr}%`,
				},
			]}
			data={records}
			options={{
				filtering: true,
			}}
		/>
	);
};

type UsageStats = {
	by_month: { label: string; value: number }[];
	by_service_team: { label: string; value: number }[];
	by_job_title: { label: string; value: number }[];
};

const UsageGraphs: React.FC<{ date: Date | null }> = ({ date }) => {
	const [byMonth, setByMonth] = useState<{ label: string; value: number }[]>(
		[]
	);
	const [byServiceTeam, setByServiceTeam] = useState<
		{ label: string; value: number }[]
	>([]);
	const [byJobTitle, setByJobTitle] = useState<
		{ label: string; value: number }[]
	>([]);

	useEffect(() => {
		if (date !== null) {
			const from_date = new Date(
				date.getFullYear(),
				date.getMonth(),
				1
			).toISOString();
			const to_date = new Date(
				date.getFullYear(),
				date.getMonth() + 1,
				0
			).toISOString();
			getUsageStats({ from_date, to_date }).then((data: UsageStats) => {
				setByMonth([...data.by_month]);
				setByServiceTeam([...data.by_service_team]);
				setByJobTitle([...data.by_job_title]);
			});
		}
	}, [date]);

	return (
		<Grid container spacing={2}>
			<Grid item sm={12}>
				<SectionCard
					title='Unique User Logins'
					titleProps={{ variant: "subtitle1" }}
				>
					<Paper>
						<Chart data={byMonth}>
							<Title text='Unique Users per Month' />
							<BarSeries
								valueField='value'
								argumentField='label'
							/>
							<LineSeries
								valueField='value'
								argumentField='label'
							/>
							<ValueAxis />
							<ArgumentAxis />
						</Chart>
					</Paper>
				</SectionCard>
			</Grid>
			<Grid item sm={6}>
				<SectionCard
					title='Unique Users per Service Team'
					titleProps={{ variant: "subtitle1" }}
				>
					<Paper>
						<Chart data={byServiceTeam} rotated>
							<Title text='Unique Users per Service Team' />
							<BarSeries
								valueField='value'
								argumentField='label'
							/>
							<ValueAxis />
							<ArgumentAxis />
						</Chart>
					</Paper>
				</SectionCard>
			</Grid>
			<Grid item sm={6}>
				<SectionCard
					title='Unique Users per Job Title'
					titleProps={{ variant: "subtitle1" }}
				>
					<Paper>
						<Chart data={byJobTitle} rotated>
							<Title text='Unique Users per Job Title' />
							<BarSeries
								valueField='value'
								argumentField='label'
							/>
							<ValueAxis />
							<ArgumentAxis />
						</Chart>
					</Paper>
				</SectionCard>
			</Grid>
		</Grid>
	);
};

const UsageReport: React.FC = () => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(
		new Date(Date.now())
	);
	const [tabValue, setTabValue] = useState(0);
	const tabList = [
		{ _id: 0, label: "Dashboard" },
		{ _id: 1, label: "Details" },
	];

	const handleDateChange = (date: MaterialUiPickersDate) => {
		setSelectedDate(date);
	};

	return (
		<SectionCard
			title='Usage Report'
			primaryAction={
				<TabGroup
					tabValue={tabValue}
					tabList={tabList}
					tabChange={(_, value) => setTabValue(value)}
				/>
			}
			secondaryAction={
				<DatePicker
					variant='inline'
					openTo='month'
					views={["year", "month"]}
					label='Month and Year'
					value={selectedDate}
					onChange={handleDateChange}
				/>
			}
			fullWidth={tabValue === 1}
			scrolling
		>
			{tabValue === 0 && <UsageGraphs date={selectedDate} />}
			{tabValue === 1 && <UsageTable date={selectedDate} />}
		</SectionCard>
	);
};

export default UsageReport;
