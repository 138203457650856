import { httpClient } from "../../../globalApi/httpClient.api";

const getIncentiveMetrics = async (payload: {
	incentiveCategoryID: number;
}) => {
	const response = await httpClient.post("/admin/incentive/metrics", payload);
	return response.data.data.metrics;
};

export { getIncentiveMetrics };
