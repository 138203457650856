import {
	Avatar,
	Button,
	CardHeader,
	createStyles,
	Divider,
	Grid,
	makeStyles,
	TableCell,
	Theme,
	Typography,
	useTheme,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import "date-fns";
import React, { useState } from "react";
import DataTable from "../../../../components/DataTable/DataTable";
import DataTableRow from "../../../../components/DataTable/DataTableRow";
import UserMetricTrends from "../../../../pages/Home/Performance/UserMetricTrends/UserMetricTrends";
import { TABLE_COLUMNS } from "../../Performance.api";
import { PerformanceContextConsumer } from "../../Performance.ctx";
import MetricPerformance from "../MetricPerformance/MetricPerformance";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paddedAvatar: {
			width: theme.spacing(5),
			height: theme.spacing(5),
		},
		metricRow: {
			// "& td:hover": {
			// 	border: `2px solid ${theme.palette.info.main}`,
			// },
		},
		metricCell: {
			cursor: "pointer",
		},
		activeMetric: {
			backgroundColor: `${theme.palette.info.main} !important`,
			boxShadow: theme.shadows[2],
			borderRadius: theme.spacing(2),
		},
	})
);

interface UserPerformanceProps {}

const UserPerformance: React.FC<UserPerformanceProps> = () => {
	const theme = useTheme();
	const classes = useStyles();
	const [viewMetric, setViewMetric] = useState<any>(null);

	const handleMetricClicked = (metric: any) => {
		if (metric) {
			setViewMetric((state: { id?: any }) =>
				state?.id == metric?.id ? null : metric
			);
			// setRecords([]);
		}
	};

	const handleMetricClear = () => {
		setViewMetric(null);
	};

	return (
		<PerformanceContextConsumer>
			{([{ viewUser }]) => (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12} container>
							<DataTable
								columns={[
									{
										label: "Name",
										name: "Name",
									},
									...TABLE_COLUMNS,
								]}
							>
								{viewUser ? (
									<DataTableRow className={classes.metricRow}>
										<TableCell>
											<CardHeader
												title={viewUser.user.name}
												style={{
													padding: theme.spacing(2),
												}}
												avatar={
													<Avatar
														className={
															classes.paddedAvatar
														}
														src={
															viewUser.user
																.profile_img
														}
													/>
												}
											/>
										</TableCell>
										{TABLE_COLUMNS.map(
											(column: any, index: number) =>
												viewUser.metrics[
													column.name
												] ? (
													<TableCell
														key={index}
														onClick={() =>
															handleMetricClicked(
																viewUser
																	.metrics[
																	column.name
																]
															)
														}
														className={clsx(
															classes.metricCell,
															{
																[classes.activeMetric]:
																	viewMetric?.id ===
																	viewUser
																		.metrics[
																		column
																			.name
																	].id,
															}
														)}
													>
														<Grid
															container
															spacing={1}
															justify='flex-start'
															alignItems='center'
														>
															{viewUser.metrics[
																column.name
															].value >
																viewUser
																	.metrics[
																	column.name
																].details
																	.comparison && (
																<ArrowDropUp />
															)}
															{viewUser.metrics[
																column.name
															].value <
																viewUser
																	.metrics[
																	column.name
																].details
																	.comparison && (
																<ArrowDropDown />
															)}
															<Typography>
																{
																	viewUser
																		.metrics[
																		column
																			.name
																	].value
																}
															</Typography>
														</Grid>
													</TableCell>
												) : (
													<TableCell key={index}>
														-
													</TableCell>
												)
										)}
									</DataTableRow>
								) : (
									<DataTableRow>
										<Skeleton width='100%' height={60} />
									</DataTableRow>
								)}
							</DataTable>
						</Grid>
						<Grid item xs={12}>
							<Grid
								container
								spacing={0}
								justify='space-between'
								alignItems='center'
							>
								<Grid item xs>
									<Divider />
								</Grid>
								{viewMetric && (
									<Button onClick={handleMetricClear}>
										Clear Selection
									</Button>
								)}
							</Grid>
						</Grid>
					</Grid>
					{viewMetric ? (
						<MetricPerformance metric={viewMetric} />
					) : (
						<Grid container spacing={2}>
							<Grid item xs={8}>
								{viewUser && (
									<UserMetricTrends viewUser={viewUser} />
								)}
							</Grid>
							<Grid item xs={4}>
								<Grid container spacing={2}>
									<Grid item xs={6}></Grid>
								</Grid>
							</Grid>
						</Grid>
					)}
				</>
			)}
		</PerformanceContextConsumer>
	);
};

export default UserPerformance;
