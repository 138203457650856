import {
	Avatar,
	Button,
	ButtonGroup,
	CardHeader,
	FormControl,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	List,
	ListItem,
} from "@material-ui/core";
import { Cancel, Delete, Edit, Search } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import SectionCard from "../../components/SectionCard/SectionCard";
import { useUserDispatch } from "../../globalContext/userObject.ctx";
import { getUserBy } from "./MaintainUser.api";
import maintainUserStyles from "./MaintainUser.style";
import UserDetails from "./UserDetails/UserDetails";

const MaintainUser: React.FC = () => {
	const classes = maintainUserStyles();
	const history = useHistory();
	const userObjectDispatch = useUserDispatch();
	const [userSearch, setUserSearch] = useState("");
	const [users, setUsers] = useState<any[]>([]);
	const [selectedUser, setSelectedUser] = useState<any>(null);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserSearch(event.target.value);
	};

	const handleSearch = () => {
		if (userSearch.length > 0) {
			getUserBy({ search: userSearch }).then((data) => {
				setUsers(data);
			});
		}
	};

	const masqueradeAs = () => {
		userObjectDispatch({
			type: "REQUEST_MASQUERADE",
			data: { entity_no: selectedUser.entity_no },
		});
		history.push("/dashboard");
	};

	return (
		<SectionCard title='Maintain User'>
			<Grid container spacing={2} className={classes.grid}>
				<Grid item sm={5} className={classes.grid}>
					<SectionCard
						title='Search'
						titleProps={{ variant: "h6" }}
						primaryAction={
							<FormControl>
								<Input
									value={userSearch}
									onChange={handleSearchChange}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton onClick={handleSearch}>
												<Search />
											</IconButton>
										</InputAdornment>
									}
								></Input>
							</FormControl>
						}
						scrolling
					>
						<List>
							{users.map((user) => (
								<ListItem
									key={`user-${user.id}`}
									button
									onClick={() => setSelectedUser(user)}
									selected={user.id === selectedUser?.id}
								>
									<CardHeader
										avatar={
											<Avatar src={user.profile_img} />
										}
										title={user.name}
										subheader={`${user.job_title} - ${user.region}`}
									/>
								</ListItem>
							))}
						</List>
					</SectionCard>
				</Grid>
				{selectedUser && (
					<Grid item sm={7} className={classes.grid}>
						<SectionCard
							title={selectedUser.name}
							titleProps={{ variant: "h6" }}
							primaryAction={
								<Button onClick={() => masqueradeAs()}>
									Masquerade
								</Button>
							}
							secondaryAction={
								<ButtonGroup>
									<IconButton>
										<Edit />
									</IconButton>
									<IconButton>
										<Delete />
									</IconButton>
									<IconButton>
										<Cancel />
									</IconButton>
								</ButtonGroup>
							}
							scrolling
						>
							<UserDetails entity_no={selectedUser.entity_no} />
						</SectionCard>
					</Grid>
				)}
			</Grid>
		</SectionCard>
	);
};

export default MaintainUser;
