import { Grid } from "@material-ui/core";
import React from "react";
import ChallengeHistory from "../../../sections/ChallengeHistory/ChallengeHistory";
import CurrentChallenge from "../../../sections/CurrentChallenge/CurrentChallenge";

const Challenge: React.FC = () => {
	return (
		<Grid container spacing={2}>
			<Grid item sm={7}>
				<CurrentChallenge />
			</Grid>
			<Grid item sm={5}>
				<ChallengeHistory />
			</Grid>
		</Grid>
	);
};

export default Challenge;
