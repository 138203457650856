import { Typography, useTheme } from "@material-ui/core";
import React from "react";
import {
	Bar,
	CartesianGrid,
	ComposedChart,
	Legend,
	LegendPayload,
	LegendValueFormatter,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

export type LineBarTrendData = {
	bar: number;
	line: number;
	interval?: string | undefined;
};
export interface LineBarTrendProps {
	height?: number | undefined;
	barSize?: number | undefined;
	data: LineBarTrendData[];
	legend?: {
		bar: string;
		line: string;
		[name: string]: string;
	};
	[propName: string]: any;
}

const LineBarTrend = (props: LineBarTrendProps) => {
	const { height = 200, barSize = 10, data, legend } = props;
	const theme = useTheme();

	const tickStyle = {
		tick: { fill: theme.palette.primary.contrastText, fontSize: 10 },
		stroke: "none",
	};

	const legendFormatter: LegendValueFormatter = (
		value?: LegendPayload["value"],
		entry?: LegendPayload | undefined,
		i?: number | undefined
	) => {
		if (!legend) return;
		const label = legend[value];
		if (label && entry) {
			const { color } = entry;
			return (
				<Typography variant='caption' style={{ color: color }}>
					{label}
				</Typography>
			);
		}
	};

	return (
		<ResponsiveContainer width='100%' height={height}>
			<ComposedChart data={data}>
				{legend && (
					<Legend
						verticalAlign='top'
						align='left'
						iconType='rect'
						wrapperStyle={{
							padding: theme.spacing(0, 4, 2),
						}}
						iconSize={10}
						formatter={legendFormatter}
					/>
				)}
				<CartesianGrid stroke={theme.palette.secondary.main} />
				<XAxis dataKey='interval' {...tickStyle} />
				<YAxis yAxisId={0} orientation='left' {...tickStyle} />
				<YAxis yAxisId={1} orientation='right' {...tickStyle} />
				<Tooltip />
				<Bar
					dataKey='bar'
					barSize={barSize}
					fill='#2b2b2b'
					radius={barSize}
					yAxisId={0}
				/>
				<Line
					type='monotone'
					dataKey='line'
					stroke='#a4b911'
					dot={false}
					yAxisId={1}
				/>
			</ComposedChart>
		</ResponsiveContainer>
	);
};

export default LineBarTrend;
