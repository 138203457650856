import {
	Collapse,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import navItemStyles from "./NavItem.styles";

interface NavItemProps {
	route?: string;
	icon?: React.ReactNode;
	label: string;
	open: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
	route,
	icon,
	label,
	open,
	children,
}) => {
	const classes = navItemStyles();

	const anchorRef = useRef<HTMLElement>();
	const [subMenuOpen, setSubMenuOpen] = useState(false);

	const handleRouteClick = (event: any) => {
		if (!open && children) {
			event.preventDefault();
			setSubMenuOpen((state) => !state);
		}
	};
	const handleRouteOpen = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setSubMenuOpen((state) => !state);
	};
	return (
		<>
			<NavLink
				to={route}
				className={classes.listItem}
				activeClassName={classes.listItemActive}
				onClick={handleRouteClick}
			>
				<ListItem button innerRef={anchorRef}>
					<ListItemIcon className={classes.listItemIcon}>
						{icon}
					</ListItemIcon>
					<ListItemText
						primary={label}
						className={
							open
								? classes.listItemText
								: classes.listItemTextHidden
						}
					/>
					{open && children && (
						<IconButton onClick={handleRouteOpen}>
							{subMenuOpen ? <ExpandLess /> : <ExpandMore />}
						</IconButton>
					)}
				</ListItem>
			</NavLink>
			{children &&
				(open ? (
					<Collapse in={subMenuOpen} timeout='auto' unmountOnExit>
						{children}
					</Collapse>
				) : (
					<Menu
						open={subMenuOpen}
						anchorEl={anchorRef.current}
						onClose={() => setSubMenuOpen(false)}
					>
						{children}
					</Menu>
				))}
		</>
	);
};

export type { NavItemProps };
export { NavItem };
