import { httpClient } from "../../globalApi/httpClient.api";
import { t_SurveyRecord } from "../Surveys/Surveys.types";

const getLsaRecord = async (payload: {
	lsa_data_id: t_SurveyRecord["LsaDataID"];
}) => {
	const response = await httpClient.post("/lsa", payload);
	return response.data.data.lsa;
};

export { getLsaRecord };
