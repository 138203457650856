import React from "react";
import { UserStateContext } from "../../globalContext/userObject.ctx";
import ReportRoutes from "./ReportRoutes/ReportRoutes";
import SettingsRoutes from "./SettingsRoutes/SettingsRoutes";

const AdminRoutes: React.FC<{ open: boolean }> = ({ open, children }) => {
	return (
		<UserStateContext.Consumer>
			{(user) => (
				<>
					<ReportRoutes open={open} />
					<SettingsRoutes open={open} />
				</>
			)}
		</UserStateContext.Consumer>
	);
};

export default AdminRoutes;
