import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import TextCard from "../../components/TextCard/TextCard";
import { userEndpoints } from "../../misc/endpoints";

export type UserDetails = {
	name: string;
	profile_img: string;
	position: string;
	office: {
		_id: string;
		label: string;
	};
	summary: [
		{ _id: string; value: number; label: string },
		{ _id: string; value: number; label: string },
		{ _id: string; value: number; label: string }
	];
	bio: string;
};

const ProfileBio = () => {
	const [editable, setEditable] = useState(false);
	const [user, setUser] = useState<UserDetails>();

	const handleUserEdit = (old: string, updated: string) => {
		if (user)
			setUser({
				...user,
				bio: updated,
			});
	};
	const handleEditableToggle = () => {
		setEditable(!editable);
	};

	useEffect(() => {
		userEndpoints.getUserProfileBasic().then((data) => {
			setUser(data);
		});
	}, [setUser]);

	return (
		<Grid container spacing={2}>
			<Grid item sm={12}>
				<Typography variant='h5'>Your Profile</Typography>
			</Grid>

			{user && (
				<React.Fragment>
					<Grid item sm={12}>
						<ProfileCard
							avatar={{
								src: user.profile_img,
								label: user.name,
							}}
							user={{
								name: user.name,
								position: user.position,
								location: user.office.label,
								summary: user.summary,
							}}
						/>
					</Grid>
					<Grid item sm={12}>
						<TextCard
							title='Professional Bio'
							editable={editable}
							content={user.bio}
							onEdit={handleUserEdit}
							toggleEdit={handleEditableToggle}
						/>
					</Grid>
				</React.Fragment>
			)}
		</Grid>
	);
};

export default ProfileBio;
