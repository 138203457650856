import {
	Backdrop,
	Drawer,
	IconButton,
	List,
	ListItemText,
	useTheme,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import ViewList from "@material-ui/icons/ViewList";
import { Column } from "material-table";
import React, { createRef, useEffect, useReducer, useState } from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../components/SectionCard/SectionCard";
import SectionedDialog from "../../../components/SectionedDialog/SectionedDialog";
import AppealRecord from "../../Appeals/components/AppealRecord/AppealRecord";
import LsaRecord from "../../LowScoreAnalysis/components/LsaRecord/LsaRecord";
import { SurveyReports } from "../../PerceptionReport/PerceptionReport";
import { t_SurveyPeriod } from "../../Surveys/Surveys.types";
import AgentSurveyRecords from "../../Surveys/tables/AgentSurveyRecords/AgentSurveyRecords";
import { actions_AgentSurveyRecord } from "../../Surveys/tables/AgentSurveyRecords/AgentSurveyRecords.api";
import {
	AgentColumns,
	getTeamLeaderSummaries,
	getTeamMemberSummaries,
	TeamLeaderColumns,
	TeamMember,
} from "./TeamLeaderSummary.api";
import teamLeaderSummaryStyles from "./TeamLeaderSummary.style";

type t_DialogState = {
	lsa_data_id?: number;
	appeal_data_id?: number;
	comment?: string;
};

const dialogDispatcher = (
	state: t_DialogState,
	{ action, rowData }: actions_AgentSurveyRecord
): t_DialogState => {
	if (!rowData) return {};

	switch (action) {
		case "lsa":
			return {
				lsa_data_id: rowData.LsaDataID,
			};
		case "appeal":
			return {
				appeal_data_id: rowData.AppealDataID,
			};
		case "comment":
			return {
				comment: rowData.Comments,
			};
	}
	return {};
};

interface TeamLeaderSummaryProps {
	period: t_SurveyPeriod;
	report: SurveyReports;
	columns: (base: Column<any>[]) => Column<any>[];
}

const TeamLeaderSummary: React.FC<TeamLeaderSummaryProps> = ({
	period,
	report,
	columns,
}) => {
	const theme = useTheme();
	const classes = teamLeaderSummaryStyles();
	const tableRef = createRef<any>();
	const dialogRef = createRef<any>();
	const [viewAgent, setViewAgent] = useState<TeamMember>();
	const [dialogState, dialogDispatch] = useReducer(dialogDispatcher, {});

	const handleAgentView = (agent: TeamMember) => {
		setViewAgent(agent);
	};

	useEffect(() => {
		tableRef.current && tableRef.current.onQueryChange();
	}, [period]);
	return (
		<SectionCard
			title='Team Leader Details'
			titleProps={{ variant: "subtitle1" }}
			fullWidth
		>
			<BUMaterialTable
				key={`${report}-team-leader-table`}
				tableRef={tableRef}
				columns={columns(TeamLeaderColumns)}
				data={(query) =>
					getTeamLeaderSummaries({ query, report, period })
				}
				options={{
					padding: "dense",
					toolbar: false,
					pageSize: 10,
				}}
				detailPanel={(rowData) => (
					<div className={classes.agentTableContainer}>
						<BUMaterialTable
							columns={columns(AgentColumns)}
							data={(query) =>
								getTeamMemberSummaries({
									query,
									report,
									period,
									teamLeader: rowData as TeamMember,
								})
							}
							localization={{
								header: {
									actions: "View",
								},
							}}
							options={{
								toolbar: false,
								padding: "dense",
							}}
							actions={[
								{
									icon: ViewList,
									tooltip: "View Agent Records",
									onClick: (_, rowData) =>
										handleAgentView(rowData as TeamMember),
								},
							]}
						/>
					</div>
				)}
			/>
			<SectionedDialog
				innerRef={dialogRef}
				open={Boolean(viewAgent)}
				onClose={() => {
					setViewAgent(undefined);
					dialogDispatch({});
				}}
				fullWidth
				maxWidth='lg'
				title={`${viewAgent?.DisplayName} - ${
					report.length > 3 ? report : report.toUpperCase()
				} Records`}
			>
				<Drawer
					anchor='right'
					variant='persistent'
					PaperProps={{ style: { position: "absolute" } }}
					BackdropProps={{ style: { position: "absolute" } }}
					ModalProps={{
						container: dialogRef.current,
						style: { position: "absolute" },
					}}
					open={Boolean(
						dialogState.lsa_data_id !== undefined ||
							dialogState.appeal_data_id !== undefined ||
							dialogState.comment !== undefined
					)}
					onClose={() => dialogDispatch({})}
				>
					<SectionCard
						title={
							(dialogState.lsa_data_id !== undefined &&
								"LSA Details") ||
							(dialogState.appeal_data_id !== undefined &&
								"Appeal Details") ||
							(dialogState.comment !== undefined && "Comment") ||
							""
						}
						titleProps={{ variant: "h6" }}
						secondaryAction={
							<IconButton onClick={() => dialogDispatch({})}>
								<ArrowForward />
							</IconButton>
						}
						scrolling
					>
						{dialogState.lsa_data_id !== undefined && (
							<LsaRecord lsa_data_id={dialogState.lsa_data_id} />
						)}
						{dialogState.appeal_data_id !== undefined && (
							<AppealRecord
								appeal_data_id={dialogState.appeal_data_id}
							/>
						)}
						{dialogState.comment !== undefined && (
							<List>
								<ListItemText
									style={{
										width: 400,
										overflowWrap: "break-word",
									}}
								>
									{dialogState.comment}
								</ListItemText>
							</List>
						)}
					</SectionCard>
				</Drawer>
				<Backdrop
					open={Boolean(
						dialogState.lsa_data_id !== undefined ||
							dialogState.appeal_data_id !== undefined ||
							dialogState.comment !== undefined
					)}
					onClick={() => dialogDispatch({})}
					style={{ zIndex: theme.zIndex.drawer - 1 }}
				/>
				{viewAgent && (
					<AgentSurveyRecords
						period={period}
						survey={report}
						entity_no={viewAgent.EntityNo}
						dispatch={dialogDispatch}
					/>
				)}
			</SectionedDialog>
		</SectionCard>
	);
};

export default TeamLeaderSummary;
