import { httpClient } from "../../globalApi/httpClient.api";

const getUserProfile = async () => {
	const response = await httpClient.get("/profile");
	return response.data.data.profile;
};
const getUserChallengeHistory = async () => {
	const response = await httpClient.get("/challenge/history");
	return response.data.data.challenge_history;
};

const getUserBadges = async () => {
	const response = await httpClient.get("/profile/badges");
	return response.data.data.badges;
};

const getUserAttributes = async () => {
	const response = await httpClient.post("/metrics/summary/me");
	return response.data.data.metric_values.map((metric: any) => ({
		id: metric.id,
		label: metric.name,
		value: metric.value,
		progress: (metric.value / metric.details.target) * 100,
		target: metric.details.target,
	}));
};

export { getUserProfile, getUserChallengeHistory, getUserBadges, getUserAttributes };
