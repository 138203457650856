import {
	createStyles,
	Fab,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import React from "react";

export interface PillProps {
	id: any;
	name: string;
	value: any;
	[propName: string]: any;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: theme.spacing(5),
			boxShadow: "none",
			minWidth: 150,
		},
		pillValue: {
			color: theme.palette.info.main,
		},
	})
);
const Pill = (props: PillProps) => {
	const { id, name, value, ...other } = props;
	const classes = useStyles();

	return (
		<Fab
			variant='extended'
			color='secondary'
			className={classes.root}
			{...other}
		>
			<Grid container spacing={2} justify='space-between' wrap='nowrap'>
				<Grid item sm>
					<Typography variant='overline'>{name}</Typography>
				</Grid>
				<Grid item sm>
					<Typography
						className={classes.pillValue}
						variant='overline'
						align='right'
					>
						{value}
					</Typography>
				</Grid>
			</Grid>
		</Fab>
	);
};

export default Pill;
