import {
	createStyles,
	List,
	ListItem,
	makeStyles,
	TextField,
	Theme,
} from "@material-ui/core";
import React from "react";
import { MetricType } from "../../pages/Home/Performance/Performance";
import { MetricRecord } from "../../pages/Home/Performance/UserMetricRecords/UserMetricRecords";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			width: 300,
		},
	})
);

interface ViewMetricProps {
	metric: MetricType;
	record: MetricRecord;
}

const ViewMetric: React.FC<ViewMetricProps> = ({ metric, record }) => {
	const classes = useStyles();
	return (
		<List>
			<ListItem>
				<TextField
					className={classes.formControl}
					label='Name'
					value={metric.name}
					InputProps={{
						readOnly: true,
					}}
				/>
			</ListItem>
			<ListItem>
				<TextField
					className={classes.formControl}
					label='Survey Date'
					value={record.survey_date}
					InputProps={{
						readOnly: true,
					}}
				/>
			</ListItem>
			<ListItem>
				<TextField
					className={classes.formControl}
					label='Call Date'
					value={record.call_date_answered}
					InputProps={{
						readOnly: true,
					}}
				/>
			</ListItem>
			<ListItem>
				<TextField
					className={classes.formControl}
					label='Response ID'
					value={record.response_id}
					InputProps={{
						readOnly: true,
					}}
				/>
			</ListItem>
			<ListItem>
				<TextField
					className={classes.formControl}
					label='Survey Score'
					value={record.survey_date}
					InputProps={{
						readOnly: true,
					}}
				/>
				<TextField
					className={classes.formControl}
					label='Target'
					value={metric.details.target}
					InputProps={{
						readOnly: true,
					}}
				/>
			</ListItem>
		</List>
	);
};

export default ViewMetric;
