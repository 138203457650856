import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	MenuItem,
	TextField,
	Typography,
} from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import DropdownSelect from "../../../../components/DropdownSelect/DropdownSelect";
import { useStateCallback } from "../../../../misc/hooks";
import { MetricBand, MetricIncentive } from "../IncentiveDetails";
import {
	createIncentiveStructures,
	getMetrics,
} from "./IncentiveMetricDialog.api";

const EditMetricBandColumns = [
	{
		title: "From",
		field: "from",
		initialEditValue: 0,
	},
	{
		title: "To",
		field: "to",
		initialEditValue: 0,
	},
	{
		title: "Multiplier",
		field: "multiplier",
		initialEditValue: 0,
	},
];
interface IncentiveMetricDialogProps {
	category_id: number;
	metric: MetricIncentive;
	onClose: () => void;
}

const IncentiveMetricDialog: React.FC<IncentiveMetricDialogProps> = ({
	category_id,
	metric,
	onClose,
}) => {
	const [editMetric, setEditMetric] = useState({
		metric_id: metric.metric_id,
		game_metric: metric.game_metric,
		incentive_metric: metric.incentive_metric,
		from_date:
			metric.metric_bands[0]?.from_date ??
			new Date(Date.now()).toDateString(),
	});
	const [metrics, setMetrics] = useState<{ id: number; name: string }[]>([]);

	const [metricBands, setMetricBands] = useStateCallback([
		...(metric.metric_bands[0]?.metric_bands ?? []),
	]);

	const handleSetEffectiveDate = (value?: string | null) => {
		const from_date = value ? new Date(value).toDateString() : "";
		setEditMetric((state) => ({
			...state,
			from_date,
		}));
	};
	const handleDetailsChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked?: boolean
	) => {
		if (event.target?.name) {
			const name = event.target.name;
			setEditMetric((state) => ({
				...state,
				[name]: Number(checked),
			}));
		}
	};
	const handleMetricChange = (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>
	) => {
		if (event.target?.name) {
			const name = event.target.name;
			setEditMetric((state) => ({
				...state,
				[name]: event.target.value,
			}));
		}
	};

	const handleNewBand = (newBand: MetricBand) => {
		return new Promise<void>((resolve) => {
			setMetricBands(
				(state: MetricBand[]) => {
					const band = {
						...newBand,
						id: state.length * -1,
						band_date: "",
						from_date: "",
						to_date: "",
					};
					return [...state, band];
				},
				() => resolve()
			);
		});
	};
	const handleBandEdit = (
		newData: MetricBand,
		oldData?: MetricBand | undefined
	) => {
		return new Promise<void>((resolve) => {
			setMetricBands(
				(state: MetricBand[]) => {
					const index = state.findIndex(
						(item) => item.id === newData.id
					);
					let newState = Array.from(state);
					newState[index] = { ...newData };
					return newState;
				},
				() => resolve()
			);
		});
	};
	const handleBandDelete = (oldData: MetricBand) => {
		return new Promise<void>((resolve) => {
			console.log({ metricBands });
			setMetricBands(
				(state: MetricBand[]) => {
					const index = state.findIndex(
						(item) => item.id === oldData.id
					);
					let newState = Array.from(state);
					newState.splice(index, 1);
					return newState;
				},
				() => resolve()
			);
		});
	};
	const handleMetricBandCreate = () => {
		createIncentiveStructures({
			...editMetric,
			category_id,
			metric_bands: metricBands,
		}).then((data) => console.log({ data }));
	};
	const handleGetMetrics = () => {
		getMetrics().then((data) => setMetrics(data));
	};
	return (
		<Dialog open={Boolean(editMetric)} onClose={() => onClose()}>
			<DialogTitle>Incentive Category Metric</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item sm={12}>
						<Typography>Details</Typography>
					</Grid>
					<Grid item sm={6}>
						{metric.metric_name !== "" ? (
							<TextField
								label='For Metric'
								value={metric.metric_name}
								fullWidth
							/>
						) : (
							<DropdownSelect
								value={editMetric.metric_id}
								onClick={handleGetMetrics}
								name='metric_id'
								label='Select Metric'
								onChange={handleMetricChange}
								fullWidth
							>
								{metrics.map((metric) => (
									<MenuItem value={metric.id}>
										{metric.name}
									</MenuItem>
								))}
							</DropdownSelect>
						)}
					</Grid>
					<Grid item sm={6}>
						<KeyboardDatePicker
							disableToolbar
							variant='inline'
							inputVariant='standard'
							format='dd/MM/yyyy'
							margin='none'
							id='date-picker-inline'
							label='Effective From Date'
							value={editMetric.from_date}
							onChange={(_, value) =>
								handleSetEffectiveDate(value)
							}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<FormControlLabel
							control={
								<Checkbox
									checked={Boolean(editMetric.game_metric)}
									onChange={handleDetailsChange}
									name='game_metric'
									id='game_metric'
								/>
							}
							label='Game Metric'
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={Boolean(
										editMetric.incentive_metric
									)}
									onChange={handleDetailsChange}
									name='incentive_metric'
									id='incentive_metric'
								/>
							}
							label='Incentive Metric'
						/>
					</Grid>
					<Grid item sm={12}>
						<BUMaterialTable
							title={"Metric Bands"}
							columns={EditMetricBandColumns}
							data={metricBands}
							editable={{
								onRowAdd: handleNewBand,
								onRowUpdate: handleBandEdit,
								onRowDelete: handleBandDelete,
							}}
							options={{
								search: false,
								pageSize: 10,
								padding: "dense",
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} startIcon={<Cancel />}>
					Cancel
				</Button>
				<Button startIcon={<Save />} onClick={handleMetricBandCreate}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default IncentiveMetricDialog;
