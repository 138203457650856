import {
	Card,
	CardContent,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DropdownSelect from "../../components/DropdownSelect/DropdownSelect";
import LineBarTrend, {
	LineBarTrendData,
} from "../../components/LineBarTrend/LineBarTrend";
import ProgressList, {
	ProgressListItem,
} from "../../components/ProgressList/ProgressList";
import { userEndpoints } from "../../misc/endpoints";
import { getDateString } from "../../misc/helpers";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardContent: {
			padding: theme.spacing(4, 0),
		},
		progressListContainer: {
			padding: theme.spacing(0, 2),
		},
	})
);

const ProfileStats = () => {
	const classes = useStyles();

	const [attributes, setAttributes] = useState<{
		total: number;
		values: ProgressListItem[];
	}>({ total: 0, values: [] });
	const [trendData, setTrendData] = useState<LineBarTrendData[]>([]);

	useEffect(() => {
		userEndpoints.getUserProfileTrendStats().then((data) => {
			setTrendData(
				data.map((item, index, self) => ({
					line: item.products_sold,
					bar: item.total_views,
					interval:
						index - 1 >= 0 &&
						new Date(self[index - 1].timestamp).getMonth() !==
							new Date(item.timestamp).getMonth()
							? getDateString({
									month: "full",
									year: "short",
									timestamp: item.timestamp,
							  })
							: undefined,
				}))
			);
		});
	}, [setTrendData]);

	useEffect(() => {
		userEndpoints.getUserProfileAttributes().then((data) => {
			setAttributes({
				total: data.total,
				values: data.values.map((attr) => ({
					...attr,
					colour:
						attr.value / attributes.total > 0.5
							? "green"
							: attr.value / attributes.total > 0.25
							? "yellow"
							: "red",
				})),
			});
		});
	}, [setAttributes, attributes.total]);
	return (
		<Grid container spacing={2}>
			<Grid item sm={6}>
				<Typography variant='h5'>Stats Breakdown</Typography>
			</Grid>
			<Grid item sm={6} container justify='flex-end'>
				<DropdownSelect value={0}>
					<option value={0}>Jan 2020</option>
				</DropdownSelect>
			</Grid>
			<Grid item sm={12}>
				<Card>
					<CardContent className={classes.cardContent}>
						<Grid container>
							<Grid item sm={12}>
								<LineBarTrend data={trendData} />
							</Grid>
							<Grid
								item
								sm={12}
								className={classes.progressListContainer}
							>
								<ProgressList
									items={attributes.values}
									total={attributes.total}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
export default ProfileStats;
