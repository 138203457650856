import { createStyles, makeStyles, Theme } from "@material-ui/core";

const userAttributesStyles = makeStyles((theme: Theme) =>
	createStyles({
		autocomplete: {
			width: 300,
		},
		formList: {
			height: "100%",
		},
		submit: {
			position: "absolute",
			bottom: 0,
			right: 0,
			backgroundColor: theme.palette.info.main,
			borderRadius: theme.spacing(0.5),
		},
	})
);

export default userAttributesStyles;
