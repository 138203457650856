import { Grid } from "@material-ui/core";
import React from "react";
import BadgeCard, { BadgeCardProps } from "../BadgeCard/BadgeCard";

type BadgeVariant = "block" | "mini" | undefined;
type BadgeListProps = {
	badges: BadgeCardProps[];
	variant?: BadgeVariant;
	[propName: string]: any;
};
const badgeVariantSize: { [key: string]: 1 | 2 } = {
	mini: 2,
	block: 2,
	default: 2,
};

const BadgeList = (props: BadgeListProps) => {
	const { badges, variant, ...other } = props;

	return (
		<Grid container spacing={1} {...other}>
			{badges.map((badge, index) => (
				<Grid
					item
					sm={badgeVariantSize[variant ?? "default"]}
					key={`${badge.title}-${index}`}
				>
					<BadgeCard
						{...badge}
						variant={variant === "mini" ? variant : "flat"}
					/>
				</Grid>
			))}
		</Grid>
	);
};

export default BadgeList;
