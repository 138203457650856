import React from "react";
import { useRouteMatch } from "react-router";
import { UserRolesContext } from "../../../authentication/RoleProvider/RoleProvider";
import ProtectedRoute from "../../../routers/ProtectedRoute";
import MaintainAppeals from "../../../sections/Appeals/components/MaintainAppeals/MaintainAppeals";
import MaintainIncentive from "../../../sections/MaintainIncentive/MaintainIncentive";
import MaintainUser from "../../../sections/MaintainUser/MaintainUser";

const Admin: React.FC = () => {
	const { path } = useRouteMatch();
	return (
		<>
			<ProtectedRoute path={`${path}/`} exact>
				Admin Home
			</ProtectedRoute>
			<UserRolesContext.Consumer>
				{({ survey_consultant }) =>
					survey_consultant && (
						<ProtectedRoute path={`${path}/incentive`}>
							<MaintainIncentive />
						</ProtectedRoute>
					)
				}
			</UserRolesContext.Consumer>
			<ProtectedRoute path={`${path}/user`}>
				<MaintainUser />
			</ProtectedRoute>
			<ProtectedRoute path={`${path}/appeals`}>
				<MaintainAppeals />
			</ProtectedRoute>
		</>
	);
};

export default Admin;
