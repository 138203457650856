import { Column, Query, QueryResult } from "material-table";
import { httpClient } from "../../../globalApi/httpClient.api";
import { convertQuery } from "../../../misc/helpers";
import { SurveyReports } from "../../PerceptionReport/PerceptionReport";
import { t_SurveyPeriod } from "../../Surveys/Surveys.types";

type TeamMember = {
	DisplayName: string;
	EntityNo: number;
	DiscoveryTeamID: number;
	LineManager: string;
	LineManagerEntityNo: string;
	ServiceTeam: string;
	Skill: string;
	Stream: string;
};

type WithSurvey = {
	AvgScore: number;
	Appeals: number;
	LSA: number;
};

type WithAppeals = {
	AppealTotal: number;
	AppealPending: number;
	AppealApproved: number;
	AppealDeclined: number;
};

type WithLSA = {
	SurveyType: string;
	LsaTotal: number;
	LsaPartial: number;
	LsaCompleted: number;
};

/** @MM Width auto is implemented to fix bug with material-table re-render; */
const TeamMemberBase: Column<any>[] = [
	{ title: "Line Manager", field: "LineManager" },
	{
		title: "Line Manager Entity No",
		field: "manager.EntityNo",
		width: "auto",
	},
	{ title: "Service Team", field: "ServiceTeam" },
	{ title: "Skill", field: "Skill" },
	{ title: "Stream", field: "Stream" },
];
const TeamLeaderColumns: Column<any>[] = [
	{ title: "Team Leader", field: "DisplayName" },
	{ title: "Team Leader Entity", field: "EntityNo" },
	...TeamMemberBase,
];
const AgentColumns: Column<any>[] = [
	{ title: "Agent Name", field: "DisplayName" },
	{ title: "Agent Entity", field: "EntityNo" },
	...TeamMemberBase,
];

const WithSurveyColumns = (
	base: Column<any>[]
): Column<TeamMember & WithSurvey>[] => [
	...base,
	{ title: "Score", field: "AvgScore" },
	{ title: "Appeals", field: "Appeals" },
	{ title: "LSA", field: "LSA" },
];

const WithAppealsColumns = (
	base: Column<any>[]
): Column<TeamMember & WithAppeals>[] => [
	...base,
	{ title: "Appeals", field: "AppealTotal" },
	{ title: "Pending", field: "AppealPending" },
	{ title: "Approved", field: "AppealApproved" },
	{ title: "Declined", field: "AppealDeclined" },
];

const WithLsaColumns = (
	base: Column<any>[]
): Column<TeamMember & WithLSA>[] => [
	{ title: "Survey", field: "SurveyType" },
	...base,
	{ title: "LSA", field: "LsaTotal" },
	{ title: "Partially", field: "LsaPartial" },
	{ title: "Completed", field: "LsaCompleted" },
];

const getTeamLeaderSummaries = (payload: {
	query: Query<any>;
	period: t_SurveyPeriod;
	report: SurveyReports;
}) =>
	new Promise<QueryResult<any>>((resolve, reject) => {
		const body = {
			for_report: payload.report,
			...payload.period,
			...convertQuery(payload.query),
		};
		httpClient
			.post("/reports/survey/team_leaders", body)
			.then(({ data }) => {
				resolve({
					data: data.data.team_leaders.data,
					page: data.pagination.page - 1,
					totalCount: data.total_results,
				});
			});
	});
const getTeamMemberSummaries = (payload: {
	query: Query<any>;
	period: t_SurveyPeriod;
	report: SurveyReports;
	teamLeader: TeamMember;
}) =>
	new Promise<QueryResult<any>>((resolve, reject) => {
		/** @MM We need to make a special case for lsa - mbr vs fcr (filter?) */
		const body = {
			for_report: payload.report,
			...payload.period,
			...convertQuery(payload.query),
			summary_for: "user",
			manager_entity: payload.teamLeader.EntityNo,
		};
		httpClient
			.post("/reports/survey/team_leaders", body)
			.then(({ data }) => {
				resolve({
					data: data.data.team_leaders.data,
					page: data.pagination.page - 1,
					totalCount: data.total_results,
				});
			});
	});

export type { TeamMember, WithSurvey, WithAppeals, WithLSA };
export {
	getTeamLeaderSummaries,
	getTeamMemberSummaries,
	TeamLeaderColumns,
	AgentColumns,
	WithSurveyColumns,
	WithAppealsColumns,
	WithLsaColumns,
};
