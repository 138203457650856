import {
    Box,
    Card,
    CardHeader,
    Grid,
    Hidden,
    IconButton,
    Link,
    Typography
} from "@material-ui/core";
import React, { ReactNode } from "react";

export interface BadgeCardProps {
	avatar: ReactNode;
	title: string;
	subtitle: string;
	variant?: "card" | "flat" | "mini" | undefined;
	subtitleClicked?: () => any;
	avatarClicked?: () => any;
	[propName: string]: any;
}

const BadgeCard = (props: BadgeCardProps) => {
	const {
		avatar,
		title,
		subtitle,
		variant,
		subtitleClicked,
		avatarClicked,
		...other
	} = props;
	const handleSubtitleClicked = () => {
		if (subtitleClicked) subtitleClicked();
	};
	const handleAvatarClicked = () => {
		if (avatarClicked) avatarClicked();
	};
	return (
		<Box>
			{!variant || (variant && variant === "card") ? (
				<Card {...other}>
					<CardHeader
						avatar={
							<IconButton onClick={handleAvatarClicked}>
								{avatar}
							</IconButton>
						}
						title={title}
						subheader={
							<Link
								component='button'
								variant='subtitle2'
								onClick={handleSubtitleClicked}
								color='inherit'
							>
								{subtitle}
							</Link>
						}
					/>
				</Card>
			) : (
				<Grid container justify='center'>
					<Grid
						item
						sm={12}
						container
						justify='center'
						// style={{ padding: theme.spacing(2) }}
					>
						<IconButton onClick={handleAvatarClicked}>
							{avatar}
						</IconButton>
					</Grid>
					<Hidden xsUp={variant === "mini"}>
						<Grid item sm={12} container justify='center'>
							<Typography variant='subtitle1'>{title}</Typography>
						</Grid>
						<Grid item sm={12} container justify='center'>
							<Link
								component='button'
								variant='subtitle2'
								onClick={handleSubtitleClicked}
								color='inherit'
							>
								{subtitle}
							</Link>
						</Grid>
					</Hidden>
				</Grid>
			)}
		</Box>
	);
};

export default BadgeCard;
