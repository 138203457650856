import {
	Box,
	createStyles,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import { InfoRounded } from "@material-ui/icons";
import React, { useState } from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import ProgressListInfo from "./ProgressListInfo";

export type ProgressListItem = {
	label: string;
	value: number;
	target?: number;
	progress?: number;
	colour: "green" | "yellow" | "red";
};
export type ProgressListProps = {
	items: ProgressListItem[];
	baseline?: number;
	total: number;
	variant?: "list" | "condensed";
	infoWindow?: boolean;
	rhs?: boolean | false;
	[propName: string]: any;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: "relative",
		},
		baseline: {
			height: "100%",
			top: 0,
			left: (props: { baseline: number; total: number }) =>
				`${(props.baseline / props.total) * 100}%`,
			backgroundColor: theme.palette.secondary.main,
			width: "3px",
		},
		infoWindowButton: {
			padding: 0,
			margin: theme.spacing(0, 1),
		},
		infoWindowIcon: {
			height: "0.7em",
			width: "0.7em",
		},
	})
);

const ProgressList: React.FC<ProgressListProps> = (
	props: ProgressListProps
) => {
	const {
		items,
		baseline = -1,
		total,
		variant = "list",
		infoWindow = false,
		rhs = false,
		...other
	} = props;
	const [infoWindowState, setInfoWindowState] = useState<boolean>(false);
	const classes = useStyles({
		baseline: !rhs ? baseline : total - baseline,
		total,
	});

	return (
		<Box className={classes.root} {...other}>
			{infoWindow && (
				<ProgressListInfo
					modalOpenState={infoWindowState}
					handleClose={() => setInfoWindowState(false)}
				/>
			)}
			<List>
				{items.map((item, index) => (
					<ListItem
						key={item.label + index}
						dense={variant === "condensed"}
					>
						<Grid container spacing={1} direction='column'>
							<Grid
								item
								container
								spacing={0}
								justify='space-between'
							>
								<Typography variant='body2' component='div'>
									{!rhs ? (
										<Box>
											{item.label}
											{infoWindow && (
												<IconButton
													aria-label='info'
													size='small'
													className={
														classes.infoWindowButton
													}
													onClick={() =>
														setInfoWindowState(true)
													}
												>
													<InfoRounded
														classes={{
															root:
																classes.infoWindowIcon,
														}}
													/>
												</IconButton>
											)}
										</Box>
									) : (
										<Box fontWeight='fontWeightBold'>
											{item.value} / {item.target}
										</Box>
									)}
								</Typography>
								<Typography variant='body2' component='div'>
									{!rhs ? (
										<Box fontWeight='fontWeightBold'>
											{item.value} / {item.target}
										</Box>
									) : (
										<Box>
											{infoWindow && (
												<IconButton
													aria-label='info'
													size='small'
													className={
														classes.infoWindowButton
													}
													onClick={() =>
														setInfoWindowState(true)
													}
												>
													<InfoRounded
														classes={{
															root:
																classes.infoWindowIcon,
														}}
													/>
												</IconButton>
											)}
											{item.label}
										</Box>
									)}
								</Typography>
							</Grid>
							<Grid item>
								<ProgressBar
									dir={!rhs ? "ltr" : "rtl"}
									colour={item.colour}
									value={item.progress ?? 0}
								/>
							</Grid>
						</Grid>
					</ListItem>
				))}
			</List>
			{baseline > 0 && (
				<Divider
					orientation='vertical'
					absolute
					className={classes.baseline}
				/>
			)}
		</Box>
	);
};

export default ProgressList;
