import { CssBaseline, ThemeProvider } from "@material-ui/core";
import React, { createContext, useState } from "react";
import Home from "./pages/Home/Home";
import { darkTheme, lightTheme } from "./theme/BULight";

type DispatchType = React.Dispatch<React.SetStateAction<boolean>>;
type StateType = [boolean, DispatchType];
const ThemeToggleSet = createContext<StateType>([false, () => {}]);

const themes = [darkTheme, lightTheme];

const getTheme = (mode: boolean) => {
	return themes[mode ? 0 : 1];
};

function App() {
	const [mode, setMode] = useState<boolean>(false);

	const theme = getTheme(mode);
	// const [theme, setTheme] = useState<Theme>(createMuiTheme(lightTheme));
	const _setMode: DispatchType = (setState) => {
		if (typeof setState !== "boolean") {
			const newMode = setState(mode);
			// setTheme(createMuiTheme(newMode ? darkTheme : lightTheme));
			setMode(newMode);
		}
	};
	return (
		<ThemeToggleSet.Provider value={[mode, _setMode]}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Home />
			</ThemeProvider>
		</ThemeToggleSet.Provider>
	);
}

export { ThemeToggleSet };
export default App;
