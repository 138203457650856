import {
	Grow,
	IconButton,
	makeStyles,
	TableCell,
	TableRow,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useState } from "react";

export type DataTableColumn = {
	label: string;
	value: any;
	align?: "left" | "center" | "right";
};
export type DataTableRowProps = {
	collapsible?: boolean | undefined;
	ExpandedContent?: React.FC<{ rowData: { [key: string]: any } }>;
	rowData?: { [key: string]: any };
	onCollapseToggle?: (index: number) => any;
	[propName: string]: any;
};
const useStyles = makeStyles({
	root: {},
	collapsible: {},
});
const DataTableRow = (props: DataTableRowProps) => {
	const {
		index,
		rowData = {},
		collapsible,
		children = [],
		onCollapseToggle,
		ExpandedContent,
		...other
	} = props;
	const classes = useStyles();

	const [expanded, setExpanded] = useState<boolean>(false);

	const handleCollapseToggle = () => {
		setExpanded(!expanded);
	};
	return (
		<React.Fragment>
			<TableRow className={classes.root} key={index} {...other}>
				{collapsible && (
					<TableCell>
						<IconButton
							aria-label='expand row'
							size='small'
							onClick={handleCollapseToggle}
						>
							{expanded ? (
								<KeyboardArrowUp />
							) : (
								<KeyboardArrowDown />
							)}
						</IconButton>
					</TableCell>
				)}
				{children}
			</TableRow>
			{expanded && (
				// @MM Manually control whether the component is rendered - issues with data table display if manipulated by styling
				// TODO - Correct to use styling or React.Memo
				<Grow in={expanded}>
					<TableRow>
						<TableCell colSpan={children.length + 1}>
							{ExpandedContent && (
								<ExpandedContent rowData={rowData} />
							)}
						</TableCell>
					</TableRow>
				</Grow>
			)}
		</React.Fragment>
	);
};
export default DataTableRow;
