import {
	Backdrop,
	createStyles,
	Grid,
	makeStyles,
	Paper,
	Slide,
	Theme,
} from "@material-ui/core";
import React, { ReactNode } from "react";

interface ContentSideBarProps {
	open: boolean;
	onClose: () => any;
	pull: "down" | "left" | "right" | "up" | undefined;
	children: ReactNode;
	[propName: string]: any;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},
		sideBarContent: {
			backgroundColor: theme.palette.secondary.main,
			position: "fixed",
			top: theme.mixins.toolbar.minHeight,
			right: (props) =>
				(props as ContentSideBarProps).pull === "left" ? 0 : "auto",
			left: (props) =>
				(props as ContentSideBarProps).pull === "right" ? 0 : "auto",
			zIndex: theme.zIndex.drawer,
			width: `${
				theme.breakpoints.down("md")
					? theme.breakpoints.values.md / 2
					: theme.breakpoints.values.lg / 3
			}px`,
			height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
			borderRadius: 0,
			padding: theme.spacing(4, 2),
			paddingBottom: 0,
		},
		backdrop: {
			zIndex: theme.zIndex.drawer - 1,
			color: "#fff",
		},
	})
);

const ContentSideBar = (props: ContentSideBarProps) => {
	const { children, open, onClose, pull, ...other } = props;
	const classes = useStyles(props);

	const handleDrawerClose = () => {
		onClose();
	};
	return (
		<React.Fragment>
			<Slide direction={pull} in={open} {...other}>
				<Grid
					className={classes.sideBarContent}
					component={Paper}
					container
				>
					{children}
				</Grid>
			</Slide>
			<Backdrop
				className={classes.backdrop}
				open={open}
				onClick={handleDrawerClose}
			></Backdrop>
		</React.Fragment>
	);
};

export default ContentSideBar;
