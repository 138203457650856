import React, { useEffect, useReducer } from "react";
import {
	getUserMetrics,
	getUserProfile,
	login,
} from "../globalApi/userObject.api";
// @MM - Type decelerations
type UserProfile = {
	id: number;
	entity_no: number;
	job_title: string;
	job_level: number;
	name: string;
	profile_img: string;
	region: string;
	tier: {
		label: string;
		colour: string;
	};
	admin_roles: any[];
};
type UserMetrics = {
	id: number;
	name: string;
	value: number;
	details: {
		target: number;
	};
}[];
type UserStatus = {
	loading?: boolean;
	error?: boolean;
	lastFetch?: number;
};
type UserObject = {
	entity_no?: any;
	masquerade?: boolean;
	profile?: UserProfile;
	metric_values: UserMetrics;
	status: UserStatus;
};
type InternalActions = {
	type: "UPDATE" | "CLEAR";
	data: {
		profile?: UserProfile;
		metric_values?: UserMetrics;
		status?: UserStatus;
	};
};
type ExternalActions = {
	type: "REQUEST_LOGIN" | "REFRESH_LOGIN" | "REQUEST_MASQUERADE" | "LOGOUT";
	data?: {
		entity_no: string;
	};
};
// @MM Initial State
const STATIC: {
	initUserObject: UserObject;
	initAuthActions: (actions: ExternalActions) => void;
} = {
	initUserObject: {
		profile: undefined,
		metric_values: [],
		status: {
			loading: true,
			error: false,
			lastFetch: undefined,
		},
	},
	initAuthActions: (actions: ExternalActions) => {},
};

const UserStateContext = React.createContext<UserObject>(STATIC.initUserObject);
const UserDispatchContext = React.createContext<
	React.Dispatch<ExternalActions>
>(STATIC.initAuthActions);

const userReducer: React.Reducer<UserObject, InternalActions> = (
	prevState,
	action
) => {
	return {
		UPDATE: {
			...prevState,
			...action.data,
			status: {
				...prevState.status,
				...action.data.status,
			},
		},
		CLEAR: STATIC.initUserObject,
	}[action.type];
};
const useUserState = () => {
	const context = React.useContext(UserStateContext);

	if (context === undefined)
		throw new Error("authState must be used inside a provider.");
	return context;
};
const useUserDispatch = (): React.Dispatch<ExternalActions> => {
	const context = React.useContext(UserDispatchContext);
	if (context === undefined)
		throw new Error("authState must be used inside a provider.");
	return context;
};
const UserProvider: React.FC = ({ children }) => {
	const [user, internalDispatch] = useReducer(
		userReducer,
		STATIC.initUserObject
	);

	const loadUser = async (update?: any) => {
		internalDispatch({
			type: "UPDATE",
			data: {
				status: {
					loading: true,
					error: false,
				},
			},
		});

		try {
			const profile = await getUserProfile();
			const metric_values = await getUserMetrics();

			internalDispatch({
				type: "UPDATE",
				data: {
					profile,
					metric_values,
					status: {
						loading: false,
						lastFetch: Date.now(),
					},
					...update,
				},
			});
		} catch {
			internalDispatch({
				type: "UPDATE",
				data: {
					status: {
						loading: false,
						error: true,
					},
				},
			});
		}
	};
	const loginUser = async (data?: { entity_no: string }, update?: any) => {
		if (!data?.entity_no) return false;
		const response = await login({
			data: { entity_no: data?.entity_no },
		});

		switch (response.status) {
			case "success":
				const { api_access_token, token_type } = response.data;
				localStorage.setItem("api_access_token", api_access_token);
				localStorage.setItem("token_type", token_type);

				await loadUser(update);
				break;
			case "error":
				internalDispatch({
					type: "UPDATE",
					data: {
						status: {
							loading: false,
							error: true,
						},
					},
				});
				return false;
			default:
				console.log("Something went wrong...");
		}
	};
	const externalDispatch: React.Dispatch<ExternalActions> = ({
		type,
		data,
	}): Promise<boolean> => {
		return {
			REQUEST_LOGIN: async () => {
				loginUser(data, { entity_no: data?.entity_no });

				return true;
			},
			REQUEST_MASQUERADE: async () => {
				loginUser(data, { masquerade: true });
				return true;
			},
			REFRESH_LOGIN: async () => {
				console.log({ user });
				console.log("here");
				if (user.entity_no) {
					loginUser(
						{ entity_no: user.entity_no },
						{ masquerade: false }
					);
				}
				return true;
			},
			LOGOUT: async () => {
				localStorage.removeItem("api_access_token");
				localStorage.removeItem("token_type");
				internalDispatch({
					type: "CLEAR",
					data: {},
				});
				return true;
			},
		}[type]();
	};

	useEffect(() => {
		loadUser();
		// return () => {
		//     cleanup
		// }
	}, []);

	return (
		<UserStateContext.Provider value={user}>
			<UserDispatchContext.Provider value={externalDispatch}>
				{children}
			</UserDispatchContext.Provider>
		</UserStateContext.Provider>
	);
};

export { UserProvider, UserStateContext, useUserState, useUserDispatch };
export type { UserObject, ExternalActions as UserActions, UserProfile };
