import { ReceiptOutlined } from "@material-ui/icons";
import React from "react";
import { NavItem } from "../../NavItem/NavItem";

const ReportRoutes: React.FC<{ open: boolean }> = ({ open, children }) => {
	return (
		<>
			<NavItem
				open={open}
				label='Reports'
				icon={<ReceiptOutlined />}
				route='/reports'
			>
				<NavItem
					open={true}
					label='Perception Reports'
					route='/reports/perception'
				>
					<NavItem
						open={true}
						label='MBR'
						route='/reports/perception/mbr'
					/>
					<NavItem
						open={true}
						label='FCR'
						route='/reports/perception/fcr'
					/>
					<NavItem
						open={true}
						label='Appeals'
						route='/reports/perception/appeals'
					/>
					<NavItem
						open={true}
						label='LSA'
						route='/reports/perception/lsa'
					/>
				</NavItem>
				<NavItem
					open={true}
					label='Usage Report'
					route='/reports/usage'
				/>
			</NavItem>
		</>
	);
};

export default ReportRoutes;
