import { Grid } from "@material-ui/core";
import React from "react";
import ProfileBadges from "../../../sections/ProfileBadges/ProfileBadges";
import ProfileBio from "../../../sections/ProfileBio/ProfileBio";
import ProfileStats from "../../../sections/ProfileStats/ProfileStats";

const Profile: React.FC = () => {
	return (
		<Grid container spacing={2}>
			<Grid item sm={12} container spacing={2} alignItems='stretch'>
				<Grid item sm={6}>
					<ProfileBio />
				</Grid>
				<Grid item sm={6}>
					<ProfileStats />
				</Grid>
			</Grid>
			<Grid item sm={12}>
				<ProfileBadges />
			</Grid>
		</Grid>
	);
};

export default Profile;
