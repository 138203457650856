import { createStyles, makeStyles, Theme } from "@material-ui/core";

const appealRecordStyles = makeStyles((theme: Theme) =>
	createStyles({
		textField: {
			width: "-webkit-fill-available",
		},
		listItem: {
			gap: 8,
		},
	})
);

export default appealRecordStyles;
