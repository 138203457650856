import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Pill, { PillProps } from "../Pill/Pill";

export interface PillListProps {
	attributes: PillProps[];
	[propName: string]: any;
}
const useStyles = makeStyles({
	root: {
		overflow: "hidden",
		"&:hover": {
			overflowX: "auto",
		},
	},
});
const PillList = (props: PillListProps) => {
	const { attributes } = props;
	const classes = useStyles();

	return (
		<Grid container spacing={2} wrap='nowrap' className={classes.root}>
			{attributes.map((attr, index) => (
				<Grid item key={index}>
					<Pill {...attr} />
				</Grid>
			))}
		</Grid>
	);
};

export default PillList;
