import { Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Column, MaterialTableProps } from "material-table";
import React, { useState } from "react";
import BUMaterialTable from "../BUMaterialTable/BUMaterialTable";
import { groupedHeaderTableStyle } from "./GroupedHeaderTable.style";

type GroupedHeaderTableProps<RowData extends object> = {
	columns: Array<
		Column<RowData> & {
			header?: string;
			render?: Column<RowData>["render"];
			columns?: Column<RowData>[];
		}
	>;
} & MaterialTableProps<RowData>;

const GroupedHeaderTable: React.FC<GroupedHeaderTableProps<any>> = ({
	columns,
	...tableProps
}) => {
	const classes = groupedHeaderTableStyle();
	const [activeColumns, setActiveColumns] = useState(columns.map(() => 0));

	const handleColumnSelect = (
		event: any,
		groupIndex: number,
		colIndex: number
	) => {
		event.stopPropagation();
		console.log({ event, groupIndex, colIndex });
		if (groupIndex !== null && colIndex != null) {
			setActiveColumns((state) => {
				let newState = Array.from(state);
				newState[groupIndex] = colIndex;
				return newState;
			});
		}
	};
	return (
		<BUMaterialTable
			{...tableProps}
			columns={columns.map((group, groupIndex) => ({
				...group,
				title: (
					<div className={classes.groupContainer}>
						<span>{group.header}</span>
						{group.columns && group.columns.length > 1 && (
							<ToggleButtonGroup
								value={activeColumns[groupIndex]}
								exclusive
								size='small'
								onChange={(e, value) =>
									handleColumnSelect(e, groupIndex, value)
								}
							>
								{group.columns.map((column, colIndex) => (
									<ToggleButton
										key={`${group.header}-${column.title}`}
										classes={{
											root: classes.toggleButton,
											selected: classes.activeButton,
										}}
										value={colIndex}
									>
										<Typography variant='caption'>
											{column.title}
										</Typography>
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						)}
					</div>
				),
				field: group.columns
					? group.columns[activeColumns[groupIndex]].field
					: group.field,
				render: group.render,
			}))}
		/>
	);
};

export type { GroupedHeaderTableProps };
export default GroupedHeaderTable;
