import { GroupedHeaderTableProps } from "../../components/GroupedHeaderTable/GroupedHeaderTable";
import { httpClient } from "../../globalApi/httpClient.api";

const SurveySummaryColumns: GroupedHeaderTableProps<any>["columns"] = [
	{
		header: "Perception",
		render: (rowData) =>
			`${Number(rowData.score_avg).toFixed(2)} (${rowData.survey_n})`,
		columns: [
			{
				title: "Result",
				field: "score_avg",
			},
			{ title: "n", field: "survey_n" },
		],
	},
	{
		header: "Appeals",
		render: (rowData) =>
			`${Math.round(Number(rowData.approved_ratio) * 100)}% (${
				rowData.appeals_n
			})`,
		columns: [
			{
				title: "Approved",
				field: "approved_ratio",
			},
			{
				title: "n",
				field: "appeals_n",
			},
		],
	},
	{
		header: "LSA",
		render: (rowData) =>
			`${Math.round(Number(rowData.capture_ratio) * 100)}% (${
				rowData.lsa_n
			})`,
		columns: [
			{
				title: "Captured",
				field: "capture_ratio",
			},
			{ title: "n", field: "lsa_n" },
		],
	},
];

const getSurveyPeriods = async (payload: {
	for_month: string;
	surveys: string[];
}) => {
	const response = await httpClient.post("/reports/survey/periods", payload);
	return response.data.data.survey_periods;
};

export { SurveySummaryColumns, getSurveyPeriods };
