import { httpClient } from "../../globalApi/httpClient.api";

const getIncentiveCategories = async () => {
	const response = await httpClient.get("/admin/incentive/categories");
	return response.data.data.categories;
};

const createIncentiveCategory = async (payload: { name: string }) => {
	const response = await httpClient.post(
		"/admin/incentive/categories/create",
		payload
	);
	return response.data.data.category;
};

const updateIncentiveCategory = async (payload: {
	category_id: number;
	name: string;
}) => {
	const response = await httpClient.post(
		"/admin/incentive/categories/update",
		payload
	);
	return response.data.data.category;
};

const deactivateIncentiveCategory = async (payload: {
	category_id: number;
}) => {
	const response = await httpClient.post(
		"/admin/incentive/categories/deactivate",
		payload
	);
	return response.data.data.result;
};

export {
	getIncentiveCategories,
	createIncentiveCategory,
	updateIncentiveCategory,
	deactivateIncentiveCategory,
};
