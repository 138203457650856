import {
	Box,
	CardActions,
	CardHeader,
	createStyles,
	Divider,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import React from "react";
import ProgressBar from "../ProgressBar/ProgressBar";

export type Challenger = {
	avatar: any;
	name: string;
	tier: { colour?: string; label: string } | undefined;
	location: string;
	progress: number;
	children?: any;
};
export interface ChallengeCardProps {
	timestamp: Date;
	lhs: Challenger;
	rhs: Challenger;
	winner?: "lhs" | "rhs" | undefined;
	classes?: {
		root?: string;
	};
	[propName: string]: any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rtlAvatar: {
			marginRight: 0,
			marginLeft: theme.spacing(2),
		},
	})
);

const ChallengeCard = (props: ChallengeCardProps) => {
	const { timestamp, lhs, rhs } = props;
	const innerClasses = useStyles();

	return (
		<Grid container spacing={1} justify='space-between'>
			<Grid item sm={12} container justify='center'>
				<Typography variant='caption'>
					{`${timestamp.getDate()}/${timestamp.getMonth()}/${timestamp.getFullYear()}`}
				</Typography>
			</Grid>
			<Grid item xs>
				<CardHeader
					avatar={lhs.avatar}
					title={<Typography display='inline'>{lhs.name}</Typography>}
					subheader={
						<Box>
							<Typography>{lhs.tier?.label}</Typography>
							<Typography>{lhs.location}</Typography>
						</Box>
					}
				></CardHeader>
				<CardActions>
					<ProgressBar colour={"green"} value={lhs.progress * 100} />
				</CardActions>
				{lhs.children}
			</Grid>
			<Divider orientation='vertical' light flexItem />
			<Grid item xs container spacing={0} direction='column'>
				<Grid item xs>
					<CardHeader
						dir='rtl'
						avatar={rhs.avatar}
						title={
							<Typography display='inline'>{rhs.name}</Typography>
						}
						subheader={
							<Box>
								<Typography>{rhs.tier?.label}</Typography>
								<Typography>{rhs.location}</Typography>
							</Box>
						}
						classes={{
							avatar: innerClasses.rtlAvatar,
						}}
					></CardHeader>
				</Grid>
				<Grid item xs>
					<CardActions>
						<ProgressBar
							dir='rtl'
							value={rhs.progress * 100}
							colour='red'
						/>
					</CardActions>
				</Grid>
				<Grid item xs container>
					{rhs.children}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ChallengeCard;
