import {
	Avatar,
	Badge,
	Card,
	CardContent,
	CardHeader,
	createStyles,
	Grid,
	IconButton,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import { Edit, Room } from "@material-ui/icons";
import React from "react";
import SummaryBadges from "../SummaryBadges/SummaryBadges";

export interface ProfileCardProps {
	avatar: {
		src?: string;
		label: string;
	};
	user: {
		name: string;
		position: string;
		location: string;
		summary: [
			{ _id: string; value: number; label: string },
			{ _id: string; value: number; label: string },
			{ _id: string; value: number; label: string }
		];
	};
	[propName: string]: any;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		hiddenInput: {
			display: "none",
		},
		profilePicture: {
			height: 160,
			width: 160,
		},
		editIcon: {
			color: theme.palette.info.main,
			backgroundColor: theme.palette.secondary.main,
		},
		profileBadges: {
			marginTop: theme.spacing(2),
		},
	})
);

const ProfileCard = (props: ProfileCardProps) => {
	const { avatar, user } = props;
	const classes = useStyles();

	return (
		<Card>
			<CardContent>
				<Grid container spacing={2} alignItems='center'>
					<Grid
						item
						sm={4}
						container
						justify='center'
						alignItems='center'
					>
						<Badge
							overlap='circle'
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							badgeContent={
								<React.Fragment>
									<input
										accept='image/*'
										className={classes.hiddenInput}
										id='icon-button-file'
										type='file'
									/>
									<label htmlFor='icon-button-file'>
										<IconButton
											size='small'
											aria-label='change avatar'
											component='span'
											className={classes.editIcon}
										>
											<Edit fontSize='inherit' />
										</IconButton>
									</label>
								</React.Fragment>
							}
						>
							<Avatar
								aria-label='profile picture'
								className={classes.profilePicture}
								src={avatar.src}
							>
								{!avatar.src && avatar.label}
							</Avatar>
						</Badge>
					</Grid>
					<Grid item sm={8} container spacing={2} direction='column'>
						<CardHeader
							title={
								<React.Fragment>
									<Typography variant='h6'>
										{user.name}
									</Typography>

									<Typography variant='subtitle1'>
										{user.position}
									</Typography>
									<Typography variant='subtitle2'>
										<Room
											fontSize='inherit'
											color='action'
										/>{" "}
										{user.location}
									</Typography>
								</React.Fragment>
							}
							subheader={
								<React.Fragment>
									<SummaryBadges
										className={classes.profileBadges}
										justify='flex-start'
										badges={[
											{
												label: user.summary[0].label,
												value: user.summary[0].value.toString(),
											},
											{
												label: user.summary[1].label,
												value: user.summary[1].value.toString(),
											},
											{
												label: user.summary[2].label,
												value: user.summary[2].value.toString(),
											},
										]}
									/>
								</React.Fragment>
							}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ProfileCard;
