import { createStyles, makeStyles, Theme } from "@material-ui/core";

const sectionedDialogStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialog: {
			background: "none",
			overflowX: "hidden",
		},
		dialogContent: {
			padding: "8px 0 0 0",
			overflowX: "hidden",
			overflowY: "auto",
		},
		dialogHeader: {
			backgroundColor: theme.palette.background.paper,
			textTransform: "capitalize",
		},
		dialogPaper: {
			height: "100%",
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(2),
		},
	})
);

export default sectionedDialogStyles;
