import { createStyles, makeStyles, Theme } from "@material-ui/core";

const teamPerformanceStyles = makeStyles((theme: Theme) =>
	createStyles({
		autoComplete: {
			width: 300,
		},
	})
);

export default teamPerformanceStyles;
