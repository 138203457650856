import {
	Card,
	CardContent,
	CardHeader,
	createStyles,
	FormControl,
	makeStyles,
	MenuItem,
	Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DropdownSelect from "../../../../components/DropdownSelect/DropdownSelect";
import LineBarTrend, {
	LineBarTrendData,
} from "../../../../components/LineBarTrend/LineBarTrend";
import { reportEndpoints } from "../../../../misc/endpoints";
import { getDateString } from "../../../../misc/helpers";

type PerformanceTrend = {
	products_sold: number;
	total_views: number;
	timestamp: number;
};
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		timeCategory: {
			minWidth: 120,
			boxShadow: theme.shadows[2],
			borderRadius: theme.shape.borderRadius,
		},
		noPadding: {
			padding: 0,
		},
	})
);

const PerformanceTrend = () => {
	const classes = useStyles();

	const [trendData, setTrendData] = useState<LineBarTrendData[]>([]);
	const [timeCategories, setTimeCategories] = useState<number[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<number>(0);

	useEffect(() => {
		reportEndpoints
			.getPerformanceOverTime()
			.then((data: PerformanceTrend[]) => {
				setTrendData(
					data.map((item, index, self) => ({
						line: item.products_sold,
						bar: item.total_views,
						interval:
							index - 1 >= 0 &&
							new Date(self[index - 1].timestamp).getMonth() !==
								new Date(item.timestamp).getMonth()
								? getDateString({
										month: "full",
										year: "short",
										timestamp: item.timestamp,
								  })
								: undefined,
					}))
				);
				setTimeCategories(
					data
						.map((item) => item.timestamp)
						.filter(
							(value, index, self) =>
								self.indexOf(value) === index
						)
				);
			});
	}, [setTrendData]);

	const onCategorySelect = (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>,
		child: React.ReactNode
	) => {
		if (typeof event.target.value === "number")
			setSelectedCategory(event.target.value);
	};
	return (
		<Card>
			<CardHeader
				title='Trends'
				action={
					<FormControl className={classes.timeCategory}>
						<DropdownSelect
							value={selectedCategory > 0 ? selectedCategory : ""}
							onChange={onCategorySelect}
						>
							<MenuItem value=''>Select Date</MenuItem>
							{timeCategories.map((category) => (
								<MenuItem key={category} value={category}>
									{getDateString({
										timestamp: category,
										month: "full",
										year: "short",
									})}
								</MenuItem>
							))}
						</DropdownSelect>
					</FormControl>
				}
			/>
			<CardContent className={classes.noPadding}>
				<LineBarTrend
					data={trendData}
					legend={{ bar: "Products sold", line: "Total views" }}
				/>
			</CardContent>
		</Card>
	);
};

export default PerformanceTrend;
