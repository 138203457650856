import { Grid, CircularProgress } from "@material-ui/core";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { UserStateContext } from "../globalContext/userObject.ctx";

const ProtectedRoute: React.FC<RouteProps> = ({ children, ...props }) => {
	return (
		<UserStateContext.Consumer>
			{({ status, profile }) => (
				<Route
					{...props}
					render={({ location }) =>
						status.loading ? (
							<Grid
								container
								justify='center'
								alignItems='center'
							>
								<CircularProgress />
							</Grid>
						) : status.error || !profile ? (
							<Redirect
								to={{
									pathname: "/login",
									state: { from: location },
								}}
							/>
						) : (
							children
						)
					}
				></Route>
			)}
		</UserStateContext.Consumer>
	);
};

export default ProtectedRoute;
