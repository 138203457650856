import {
	Avatar,
	CardHeader,
	CircularProgress,
	Grid,
	List,
	ListItem,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getUserDetails } from "./UserDetails.api";

type DetailedUser = {
	entity_no: any;
	name: any;
	job_title: any;
	profile_img: any;
	region: any;
	job_level: any;
	team: any;
	incentive_category: any;
	skill: any;
	division: any;
	company: any;
	manager: any;
	service_team: any;
	agent_skill: any;
	department: any;
	discovery_team: any;
	cost_center: any;
	roles: any[];
};

const UserDetails: React.FC<{ entity_no: number }> = ({ entity_no }) => {
	const [editUser, setEditUser] = useState<DetailedUser | null>(null);

	useEffect(() => {
		getUserDetails({ entity_no }).then((data) => {
			setEditUser(data);
		});
	}, [entity_no]);

	return (
		<Grid container spacing={2}>
			{editUser ? (
				<>
					<Grid item sm={6}>
						<CardHeader
							title={editUser.name}
							subheader={editUser.entity_no}
							avatar={<Avatar srcSet={editUser.profile_img} />}
						/>
					</Grid>

					<Grid item sm={6}>
						<List>
							{editUser.roles.map((role) => (
								<ListItem key={role}>{role}</ListItem>
							))}
						</List>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.job_title || "Not defined."}
							label='Job Title'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}></Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.region || "Not defined."}
							label='Region'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.job_level || "Not defined."}
							label='Job Level'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.team || "Not defined."}
							label='Team'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={
								editUser.incentive_category || "Not defined."
							}
							label='Incentive Category'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.skill || "Not defined."}
							label='Skill'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.division || "Not defined."}
							label='Division'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.company || "Not defined."}
							label='Company'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.manager || "Not defined."}
							label='Manager'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.service_team || "Not defined."}
							label='Service Team'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.agent_skill || "Not defined."}
							label='Agent Skill'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.department || "Not defined."}
							label='Department'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.discovery_team || "Not defined."}
							label='Discovery Team'
							fullWidth
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={editUser.cost_center || "Not defined."}
							label='Cost Center'
							fullWidth
						/>
					</Grid>
				</>
			) : (
				<CircularProgress />
			)}
		</Grid>
	);
};

export default UserDetails;
