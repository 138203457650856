import {
	Avatar,
	Card,
	CardContent,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BadgeList from "../../components/BadgeList/BadgeList";
import ChallengeCard from "../../components/ChallengeCard/ChallengeCard";
import ProgressList from "../../components/ProgressList/ProgressList";
import SummaryBadges, {
	SummaryBadge,
} from "../../components/SummaryBadges/SummaryBadges";
import { challengeEndpoints } from "../../misc/endpoints";

export type DetailedChallengeUser = {
	name: string;
	profile_img: string;
	tier: {
		_id: string;
		label: string;
		colour: string;
	};
	office: {
		_id: string;
		label: string;
	};
	progress: number;
	summary: [
		{
			_id: string;
			label: string;
			value: number;
		},
		{
			_id: string;
			label: string;
			value: number;
		},
		{
			_id: string;
			label: string;
			value: number;
		}
	];
	metrics: {
		_id: string;
		acronym: string;
		label: string;
		value: number;
	}[];
	badges: {
		_id: string;
		name: string;
		description: string;
		badge_img: string;
	}[];
};

export type DetailedChallenge = {
	user: DetailedChallengeUser;
	challenger: DetailedChallengeUser;
	timestamp: number;
	metric_baseline: number;
	metric_total: number;
};

const challengerContentStyle = makeStyles((theme: Theme) =>
	createStyles({
		badgeList: {
			paddingLeft: theme.spacing(2),
		},
	})
);
const ChallengerContent = (props: {
	challenger: DetailedChallengeUser;
	side: "lhs" | "rhs";
	metric_baseline: number;
	metric_total: number;
}) => {
	const { challenger, side, metric_baseline, metric_total } = props;
	const classes = challengerContentStyle();

	return (
		<Grid item xs container spacing={2} direction='column'>
			<Grid item sm>
				<SummaryBadges
					justify='center'
					badges={
						challenger.summary.map(
							(item): SummaryBadge => ({
								...item,
								value: item.value.toString(),
							})
						) as [SummaryBadge, SummaryBadge, SummaryBadge]
					}
				/>
			</Grid>
			<Grid item sm>
				<ProgressList
					rhs={side === "rhs"}
					items={challenger.metrics.map((attr) => ({
						...attr,
						colour:
							attr.value > metric_baseline
								? "green"
								: attr.value / metric_baseline > 0.5
								? "yellow"
								: "red",
					}))}
					total={metric_total}
					baseline={metric_baseline}
				/>
			</Grid>
			<Grid item sm container spacing={2} direction='column'>
				<Typography variant='subtitle1'>Badges</Typography>
				<BadgeList
					{...(side === "rhs" && { className: classes.badgeList })}
					badges={challenger.badges.map((badge) => ({
						avatar: <Avatar src={badge.badge_img} />,
						title: badge.name,
						subtitle: badge.description,
					}))}
					variant='mini'
				/>
			</Grid>
		</Grid>
	);
};

const CurrentChallenge = () => {
	const [
		detailedChallenge,
		setDetailedChallenge,
	] = useState<DetailedChallenge>();

	useEffect(() => {
		challengeEndpoints.getCurrentChallenge().then((data) => {
			setDetailedChallenge(data);
		});
		// getCurrentChallenge().then((data) => {
		// 	setDetailedChallenge(data);
		// });
	}, []);

	return (
		<Grid container spacing={2}>
			<Grid item sm={12}>
				<Typography variant='h5'>
					Current Challenge Breakdown
				</Typography>
			</Grid>
			<Grid item sm={12}>
				<Card>
					<CardContent>
						{detailedChallenge && (
							<ChallengeCard
								timestamp={
									new Date(detailedChallenge.timestamp)
								}
								lhs={{
									...detailedChallenge.user,
									avatar: (
										<Avatar
											src={
												detailedChallenge.user
													.profile_img
											}
										/>
									),
									location:
										detailedChallenge.user.office.label,
									children: (
										<ChallengerContent
											challenger={detailedChallenge.user}
											side='lhs'
											metric_baseline={
												detailedChallenge.metric_baseline
											}
											metric_total={
												detailedChallenge.metric_total
											}
										/>
									),
								}}
								rhs={{
									...detailedChallenge.challenger,
									avatar: (
										<Avatar
											src={
												detailedChallenge.challenger
													.profile_img
											}
										/>
									),
									location:
										detailedChallenge.challenger.office
											.label,
									children: (
										<ChallengerContent
											challenger={detailedChallenge.user}
											side='rhs'
											metric_baseline={
												detailedChallenge.metric_baseline
											}
											metric_total={
												detailedChallenge.metric_total
											}
										/>
									),
								}}
							/>
						)}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
export default CurrentChallenge;
