import { createStyles, makeStyles, Theme } from "@material-ui/core";

const maintainUserStyles = makeStyles((theme: Theme) =>
	createStyles({
		userSearchContainer: {
			width: "100%",
			display: "flex",
			alignItems: "baseline",
			justifyContent: "space-between",
		},
		grid: {
			height: "100%",
		},
	})
);

export default maintainUserStyles;
