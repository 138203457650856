import {
	CircularProgress,
	createStyles,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Theme,
} from "@material-ui/core";
import { Replay } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import { MetricType } from "../../pages/Home/Performance/Performance";
import { MetricRecord } from "../../pages/Home/Performance/UserMetricRecords/UserMetricRecords";
import ViewMetric from "../ViewMetric/ViewMetric";
import LsaComments, { LsaPostComment } from "./LsaComments/LsaComments";
import { getLsaDetails } from "./LsaDialogContent.api";
import LsaStepper from "./LsaStepper/LsaStepper";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		lsaDialog: {
			background: "none",
		},
		lsaDialogContent: {
			padding: "8px 0 0 0",
			overflow: "hidden",
		},
		lsaDialogHeader: {
			backgroundColor: theme.palette.background.paper,
		},
		lsaDialogPaper: {
			height: "100%",
		},
		hiddenComment: {
			visibility: "hidden",
		},
	})
);
type MetricLsa = {
	data_id: number;
	lsa_data_id: number;
	status_id: number;
	parameter: number;
	hasLSA: boolean;
	completed_by_agent: string;
	completed_by_team_leader: string;
};
interface LsaDialogProps {
	lsa: MetricLsa;
	record: MetricRecord;
	viewMetric: MetricType;
	onClose: () => void;
}
const LsaDialogContent: React.FC<LsaDialogProps> = ({
	lsa,
	viewMetric,
	record,
	onClose,
}) => {
	const classes = useStyles();
	const [details, setDetails] = useState<any>(null);
	const [lastUpdate, setLastUpdate] = useState<Date>(new Date(Date.now()));
	const [tabValue, setTabValue] = useState(0);

	const tabList = [
		{ _id: 0, label: "Survey Details" },
		{ _id: 1, label: "Comments" },
	];

	useEffect(() => {
		let isActive = true;
		console.log({ lsa });
		getLsaDetails({ lsa_data_id: lsa.lsa_data_id }).then((data) => {
			console.log(isActive);
			isActive && setDetails(data);
		});

		return () => {
			isActive = false;
		};
	}, []);
	return (
		<>
			<DialogTitle
				id='form-dialog-title'
				className={classes.lsaDialogHeader}
			>
				Low Score Analysis
				{/* <Button onClick={handleDialogClose} color='secondary'>
							Cancel
						</Button> */}
				{/* <Button onClick={handleDialogSave} color='default'>
							Save
						</Button> */}
			</DialogTitle>
			<DialogContent
				classes={{
					root: classes.lsaDialogContent,
				}}
			>
				<Grid container spacing={2}>
					<Grid item sm={5}>
						<Paper classes={{ root: classes.lsaDialogPaper }}>
							{details ? (
								<LsaStepper
									details={details}
									lsa={lsa}
									onClose={onClose}
								/>
							) : (
								<CircularProgress />
							)}
						</Paper>
					</Grid>
					<Grid item sm={7}>
						<SectionCard
							title='Details'
							hasBackground
							primaryAction={
								<TabGroup
									tabList={tabList}
									tabChange={(_, value) =>
										setTabValue(value || 0)
									}
									tabValue={tabValue}
								/>
							}
							bottomActions={
								<LsaPostComment
									className={
										tabValue == 1
											? undefined
											: classes.hiddenComment
									}
									{...details}
									onSuccess={() =>
										setLastUpdate(new Date(Date.now()))
									}
								/>
							}
							secondaryAction={
								tabValue == 1 && (
									<IconButton
										onClick={() =>
											setLastUpdate(new Date(Date.now()))
										}
									>
										<Replay />
									</IconButton>
								)
							}
						>
							{tabValue == 0 && (
								<ViewMetric
									metric={viewMetric}
									record={record}
								/>
							)}
							{tabValue == 1 &&
								(details ? (
									<LsaComments
										{...details}
										lastUpdate={lastUpdate}
									/>
								) : (
									<CircularProgress />
								))}
						</SectionCard>
					</Grid>
				</Grid>
			</DialogContent>
		</>
	);
};

export type { MetricLsa };
export default LsaDialogContent;
