import {
	AppBar,
	Box,
	Button,
	Divider,
	FormControlLabel,
	Grid,
	Menu,
	MenuItem,
	Switch,
	Toolbar,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
	AccountCircle,
	ArrowBack,
	Cancel,
	ContactSupportOutlined,
	HomeOutlined,
	Menu as MenuIcon,
} from "@material-ui/icons";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ThemeToggleSet } from "../../App";
import ContentSideBar from "../../components/ContentSideBar/ContentSideBar";
import PillList from "../../components/PillList/PillList";
import {
	UserStateContext,
	useUserDispatch,
} from "../../globalContext/userObject.ctx";
import AdminRoutes from "../../navigation/AdminRoutes/AdminRoutes";
import { GeneralRoutes } from "../../navigation/GeneralRoutes/GeneralRoutes";
import { NavItem } from "../../navigation/NavItem/NavItem";
import ProfileSummary from "../ProfileSummary/ProfileSummary";

interface SideBarProps {
	actionChange?: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => any;
	logo?: string;
	containerref?: React.RefObject<HTMLDivElement>;
	[propName: string]: any;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		sideBar: {
			height: "100%",
			minWidth: theme.spacing(8),
		},
		sideMenu: {
			height: "100%",
			minWidth: theme.spacing(36),
		},
		appBarControls: {
			// width: theme.spacing(36),
		},
		appBarContent: {
			borderLeft: `1px solid ${theme.palette.divider}`,
		},
		appBarActions: {
			marginLeft: "auto",
			borderLeft: `1px solid ${theme.palette.divider}`,
		},
		listItem: {
			display: "flex",
			columnGap: theme.spacing(2),
			color: theme.palette.primary.contrastText,
			textDecoration: "none",
			"&:hover": {
				background: theme.palette.info.main,
				color: theme.palette.secondary.main,
			},
		},
		listItemActive: {
			backgroundColor: theme.palette.info.main + "!important",
			color: theme.palette.secondary.main,
			"&:hover": {
				background: theme.palette.info.main,
				color: theme.palette.secondary.main,
			},
		},
		listItemIcon: {
			margin: "0 auto",
			minWidth: "auto",
			color: "inherit",
		},
		listItemText: {
			paddingLeft: 12,
		},
		listItemTextHidden: {
			maxWidth: 0,
			overflow: "hidden",
		},
		themeToggle: {
			paddingLeft: 0,
			paddingRight: 0,
			display: "flex",
		},
		switchLabel: {
			display: "flex",
			columnGap: theme.spacing(2),
			margin: "0",
		},
		drawerActions: {
			marginTop: "auto",
		},
		brandContainer: {
			width: "100%",
			paddingBottom: "100%",
			position: "relative",
			"&::before": {
				content: '""',
				position: "absolute",
				height: "80%",
				width: "80%",
				background: "lightgrey",
				margin: "0 10%",
				borderRadius: "100%",
			},
		},
		navLogo: {},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			display: "flex",
			flexDirection: "row",
		},
		menuOpen: {
			marginLeft: -8,
			marginRight: -8,
		},
		menuClose: {
			marginLeft: "auto",
		},
	})
);

const NavSideBar = ({
	actionChange,
	menuList,
	logo,
	...other
}: SideBarProps) => {
	const classes = useStyles();
	const userDispatch = useUserDispatch();

	const [menuRef, setMenuRef] = useState<null | HTMLElement>(null);
	const [menuItems, setMenuItems] = useState<
		{ label: string; route: string }[]
	>([]);
	const [open, setOpen] = useState(false);
	const [adminCollapse, setAdminCollapse] = useState(false);
	const [sidePanelOpen, setOpenSidePanel] = useState(false);
	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleMenuToggle = () => {
		setOpen((state) => !state);
	};
	const toggleSidePanelOpen = () => {
		setOpenSidePanel(!sidePanelOpen);
	};
	const handleSidePanelClose = () => {
		setOpenSidePanel(false);
	};

	return (
		<React.Fragment>
			<Menu
				open={Boolean(menuRef)}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
					setMenuItems([]);
				}}
			>
				{menuItems.map((item) => (
					<NavLink
						key={item.label}
						to={item.route}
						className={classes.listItem}
						activeClassName={classes.listItemActive}
						onClick={() => {
							setMenuRef(null);
							setMenuItems([]);
						}}
					>
						<MenuItem>{item.label}</MenuItem>
					</NavLink>
				))}
			</Menu>
			<AppBar
				position='sticky'
				color='primary'
				className={classes.appBar}
			>
				<Toolbar className={classes.appBarControls}>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						onClick={handleMenuToggle}
						className={classes.menuOpen}
					>
						<MenuIcon />
					</IconButton>
				</Toolbar>
				<Toolbar className={classes.appBarContent}>
					<UserStateContext.Consumer>
						{(user) => <PillList attributes={user.metric_values} />}
					</UserStateContext.Consumer>
				</Toolbar>
				<Toolbar className={classes.appBarActions}>
					<UserStateContext.Consumer>
						{(user) =>
							user.masquerade && (
								<Button
									onClick={() =>
										userDispatch({ type: "REFRESH_LOGIN" })
									}
									startIcon={<Cancel />}
								>
									Stop Masquerade
								</Button>
							)
						}
					</UserStateContext.Consumer>
					<IconButton
						aria-label='Profile'
						color='inherit'
						onClick={toggleSidePanelOpen}
					>
						<AccountCircle />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Drawer
				variant={open ? "temporary" : "permanent"}
				open={true}
				onClose={handleDrawerClose}
				{...other}
			>
				<Grid
					container
					direction='column'
					justify='flex-start'
					alignContent='center'
					className={open ? classes.sideMenu : classes.sideBar}
					ref={other.containerref}
				>
					<Grid
						item
						container
						direction='column'
						justify='space-evenly'
					>
						<Grid item>
							<Box component='div' className={classes.toolbar}>
								<IconButton
									color='inherit'
									aria-label='open drawer'
									onClick={handleMenuToggle}
									className={classes.menuClose}
								>
									<ArrowBack />
								</IconButton>
							</Box>
						</Grid>
					</Grid>
					<Grid item>
						<List>
							<NavItem
								open={open}
								label='Dashboard'
								icon={<HomeOutlined />}
								route='/dashboard'
							/>
						</List>
						<GeneralRoutes open={open} />
						<Divider variant={open ? "fullWidth" : "middle"} />
						<List>
							<AdminRoutes open={open} />
							<NavItem
								open={open}
								label='Help'
								icon={<ContactSupportOutlined />}
								route='/help'
							/>
						</List>
					</Grid>
					<Grid item style={{ marginTop: "auto" }}>
						<Divider variant={open ? "fullWidth" : "middle"} />
						<List>
							<ListItem
								className={classes.themeToggle}
								style={{
									justifyContent: open
										? "center"
										: "space-evenly",
								}}
							>
								<ThemeToggleSet.Consumer>
									{([mode, setMode]) =>
										open ? (
											<FormControlLabel
												className={classes.switchLabel}
												label='Dark Mode'
												labelPlacement='start'
												control={
													<Switch
														size='small'
														checked={mode}
														onChange={() =>
															setMode(
																(state) =>
																	!state
															)
														}
													/>
												}
											></FormControlLabel>
										) : (
											<Switch
												size='small'
												checked={mode}
												onChange={() =>
													setMode((state) => !state)
												}
											/>
										)
									}
								</ThemeToggleSet.Consumer>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Drawer>
			<UserStateContext.Consumer>
				{({ profile }) => (
					<ContentSideBar
						open={Boolean(profile) && sidePanelOpen}
						pull='left'
						onClose={handleSidePanelClose}
					>
						<ProfileSummary />
					</ContentSideBar>
				)}
			</UserStateContext.Consumer>
		</React.Fragment>
	);
};

export default NavSideBar;
