import { Divider, List } from "@material-ui/core";
import {
	BarChartOutlined,
	ChatBubbleOutline,
	PermIdentityOutlined,
} from "@material-ui/icons";
import React from "react";
import { NavItem } from "../NavItem/NavItem";

const GeneralRoutes: React.FC<{ open: boolean }> = ({ open, children }) => {
	return (
		<>
			<Divider variant={open ? "fullWidth" : "middle"} />
			<List>
				<NavItem
					open={open}
					label='Performance'
					icon={<BarChartOutlined />}
					route='/performance'
				/>
				<NavItem
					open={open}
					label='Profile'
					icon={<PermIdentityOutlined />}
					route='/profile'
				/>
				<NavItem
					open={open}
					label='Challenge'
					icon={<ChatBubbleOutline />}
					route='/challenge'
				/>
			</List>
		</>
	);
};

export { GeneralRoutes };
