import {
	AppBar,
	createStyles,
	makeStyles,
	Tab,
	Tabs,
	Theme,
} from "@material-ui/core";
import React from "react";

export interface TabGroupProps {
	tabValue: number;
	tabChange: (event: React.ChangeEvent<{}>, newValue: number) => any;
	tabList: { _id: any; label: string }[];
	[propName: string]: any;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: theme.spacing(1),
			width: "min-content",
		},
		tabs: {
			borderRadius: theme.spacing(1),
			height: "100%",
			alignItems: "center",
		},
		tab: {
			// borderRadius: theme.spacing(1),
			// "&.Mui-selected": {
			// 	backgroundColor: theme.palette.info.main,
			// 	color: theme.palette.info.contrastText,
			// },
			// width: "max-content",
			// padding: "8px 12px",
			// minWidth: 0,
		},
		tabIndicator: {
			display: "none",
		},
	})
);
const TabGroup: React.FC<TabGroupProps> = ({
	tabValue,
	tabChange,
	tabList,
	children,
}) => {
	const classes = useStyles();

	return (
		<AppBar
			className={classes.root}
			color='secondary'
			position='relative'
			elevation={0}
		>
			<Tabs
				classes={{ root: classes.tabs }}
				value={tabValue}
				onChange={tabChange}
				aria-label='Tab group.'
				TabIndicatorProps={{ className: classes.tabIndicator }}
			>
				{tabList ? (
					tabList.map((tab, index) => (
						<Tab
							key={tab._id}
							// className={classes.tab}
							classes={{
								root: classes.tab,
							}}
							label={tab.label}
						/>
					))
				) : (
					<Tab className={classes.tab} label='We Running Empty' />
				)}
				{children}
			</Tabs>
		</AppBar>
	);
};

export default TabGroup;
