import {
	Button,
	Grid,
	Input,
	List,
	ListItem,
	makeStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import environment from "../../../globalApi/env.api";
import { rawHttpClient } from "../../../globalApi/httpClient.api";
import {
	useUserDispatch,
	useUserState,
} from "../../../globalContext/userObject.ctx";

const useStyles = makeStyles({
	loginContainer: {
		height: "100vh",
	},
});
const Login = () => {
	const classes = useStyles();
	const userState = useUserState();
	const dispatch = useUserDispatch();
	const history = useHistory();
	const location = useLocation();
	const inputRef = useRef<HTMLInputElement>(null);

	const [users, setUsers] = useState<{ entity_no: string; name: string }[]>(
		[]
	);

	useEffect(() => {
		rawHttpClient
			.get(
				`${environment.base_url}/${environment.api_version}/user/available`,
				{
					headers: {
						"Content-type": "application/json",
						Accept: "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data) setUsers(res.data);
			});
	}, [inputRef]);
	useEffect(() => {
		if (!userState.status.loading && !userState.status.error) {
			let { from } = location.state || { from: { pathname: "/" } };
			history.replace(from);
		}
	}, [userState, history, location.state]);
	const handleLoginClick = (entity: any) => {
		if (entity) {
			dispatch({ type: "REQUEST_LOGIN", data: { entity_no: entity } });
		}
	};
	return (
		<Grid
			className={classes.loginContainer}
			container
			justify='center'
			alignContent='center'
		>
			<List>
				{users.map((user) => (
					<ListItem
						key={user.entity_no}
						button
						onClick={() => handleLoginClick(user.entity_no)}
					>
						{user.name}
					</ListItem>
				))}
			</List>
			<Grid item xs={12} container justify='center'>
				<Input inputRef={inputRef} />
			</Grid>
			<Grid item xs={12} container justify='center'>
				<Button
					onClick={() => handleLoginClick(inputRef.current?.value)}
				>
					Login
				</Button>
			</Grid>
		</Grid>
	);
};
export default Login;
