import { createStyles, makeStyles, Theme } from "@material-ui/core";

const navItemStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			display: "flex",
			columnGap: theme.spacing(2),
			color: theme.palette.primary.contrastText,
			textDecoration: "none",
			"&:hover": {
				background: theme.palette.info.main,
				color: theme.palette.secondary.main,
			},
		},
		listItemActive: {
			backgroundColor: theme.palette.info.main + "!important",
			color: theme.palette.secondary.main,
			"&:hover": {
				background: theme.palette.info.main,
				color: theme.palette.secondary.main,
			},
		},
		listItemIcon: {
			margin: "0 auto",
			minWidth: "auto",
			color: "inherit",
		},
		listItemText: {
			paddingLeft: 12,
		},
		listItemTextHidden: {
			maxWidth: 0,
			overflow: "hidden",
		},
	})
);

export default navItemStyles;
