import { createStyles, makeStyles, Theme } from "@material-ui/core";

const teamMembersStyles = makeStyles((theme: Theme) =>
	createStyles({
		condensedAvatar: {
			width: theme.spacing(3),
			height: theme.spacing(3),
		},
		paddedAvatar: {
			width: theme.spacing(5),
			height: theme.spacing(5),
		},
	})
);

export default teamMembersStyles;
