import {
	Avatar,
	Chip,
	IconButton,
	TextField,
	useTheme,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { UserStateContext } from "../../../globalContext/userObject.ctx";
import { getComments, postComment } from "./LsaComments.api";

const LsaPostComment: React.FC<{
	lsa_id: number;
	onSuccess: () => any;
	className?: string;
}> = ({ lsa_id, className, onSuccess }) => {
	const inputRef = useRef<any>(null);
	const handleSend = () => {
		const comment = inputRef.current?.value;
		postComment({ lsa_id, comment }).then(() => {
			onSuccess();
		});
	};
	return (
		<>
			<TextField
				className={className}
				inputRef={inputRef}
				placeholder='Message'
				variant='outlined'
				style={{
					width: "-webkit-fill-available",
				}}
			/>
			<IconButton onClick={handleSend} className={className}>
				<Send />
			</IconButton>
		</>
	);
};
const LsaComments: React.FC<{ lsa_id: number; lastUpdate: Date }> = ({
	lsa_id,
	lastUpdate,
}) => {
	/** @TODO Disallow comments functionality if LSA is new and hasn't been saved (no lsa_id) */
	const [comments, setComments] = useState<any[]>([]);
	const theme = useTheme();

	useEffect(() => {
		let isActive = true;
		getComments({ lsa_id }).then((data) => {
			isActive && setComments(data);
		});

		return () => {
			isActive = false;
		};
	}, [setComments, lastUpdate]);

	return (
		<
			// style={{
			// 	height: "inherit",
			// }}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column-reverse",
					gap: "12px",
				}}
			>
				<UserStateContext.Consumer>
					{({ profile }) =>
						comments.map((comment) => (
							<div
								key={comment.feedback_id}
								style={{
									display: "flex",
									justifyContent:
										profile?.id === comment.user.id
											? "flex-start"
											: "flex-end",
								}}
							>
								<Chip
									style={{
										maxWidth: "70%",
										backgroundColor:
											profile?.id === comment.user.id
												? theme.palette.info.main
												: undefined,
										color:
											profile?.id === comment.user.id
												? theme.palette.info
														.contrastText
												: undefined,
									}}
									label={comment.comment}
									avatar={
										<Avatar
											src={comment.user.profile_img}
										/>
									}
								/>
							</div>
						))
					}
				</UserStateContext.Consumer>
			</div>
		</>
	);
};

export { LsaPostComment };
export default LsaComments;
