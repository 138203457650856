import { createStyles, makeStyles, Theme } from "@material-ui/core";

const agentSurveyRecordsStyles = makeStyles((theme: Theme) =>
	createStyles({
		agentTableContainer: {
			padding: theme.spacing(2, 0),
			backgroundColor: theme.palette.secondary.main,
		},
	})
);

export default agentSurveyRecordsStyles;
