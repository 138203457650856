import { httpClient } from "../../globalApi/httpClient.api";

const getAppeal = async (payload: { appeal_data_id: number }) => {
	const response = await httpClient.post("/appeal", payload);
	return response.data.data.appeal;
};
const getCategories = async (payload: { metric_id: number }) => {
	const response = await httpClient.post("/appeal/categories", payload);
	return response.data.data.categories;
};
const getRemovalLevels = async () => {
	const response = await httpClient.post("/appeal/levels");
	return response.data.data.levels;
};

export { getAppeal, getCategories, getRemovalLevels };
