import { httpClient } from "../../globalApi/httpClient.api";

const getUsageRecords = async (payload: {
	from_date: string;
	to_date: string;
}) => {
	const response = await httpClient.post("/reports/usage/records", payload);
	return response.data.data.usages;
};

const getUsageStats = async (payload: {
	from_date: string;
	to_date: string;
}) => {
	const response = await httpClient.post("/reports/usage/stats", payload);
	return response.data.data.stats;
};

export { getUsageRecords, getUsageStats };
