import {
	Card,
	CardContent,
	CardHeader,
	IconButton,
	InputBase,
	TextField,
} from "@material-ui/core";
import { Edit, Save } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";

interface TextCardProps {
	title: string;
	content: string;
	editable?: boolean;
	toggleEdit?: () => any;
	onEdit?: (current: string, updated: string) => any;
	[propName: string]: any;
}

const TextCard = (props: TextCardProps) => {
	const { title, content, editable, toggleEdit, onEdit, ...other } = props;
	const [textContent, setTextContent] = useState(content);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleTextContentChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setTextContent(event.currentTarget.value);
	};
	const handleActionClick = () => {
		if (editable && onEdit) onEdit(content, textContent);
		if (toggleEdit) toggleEdit();
	};

	useEffect(() => {
		if (editable) inputRef.current?.focus();
	}, [editable]);
	useEffect(() => {
		setTextContent(content);
	}, [content]);
	return (
		<Card {...other}>
			<CardHeader
				title={title}
				action={
					<IconButton
						aria-label='Edit content.'
						onClick={handleActionClick}
					>
						{editable ? <Save /> : <Edit />}
					</IconButton>
				}
			/>
			<CardContent>
				{editable ? (
					<TextField
						fullWidth
						multiline
						rows={5}
						value={textContent}
						inputRef={inputRef}
						onChange={handleTextContentChange}
						color='secondary'
						variant='outlined'
					/>
				) : (
					<InputBase
						fullWidth
						multiline
						readOnly={true}
						rows={5}
						value={textContent}
					></InputBase>
				)}
			</CardContent>
		</Card>
	);
};

export default TextCard;
