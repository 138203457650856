import { Query } from "material-table";

type DateFormatOption = "short" | "full" | undefined;
interface getDateStringParams {
	timestamp: number;
	day?: DateFormatOption;
	month?: DateFormatOption;
	year?: DateFormatOption;
}
export const getDateString = (params: getDateStringParams): string => {
	const Days = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday",
	];
	const Months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	const date = new Date(params.timestamp);
	let dateString = "";

	switch (params.day) {
		case "short":
			dateString += Days[date.getDay()].substr(0, 3);
			break;
		case "full":
			dateString += Days[date.getDay()];
			break;
		default:
			break;
	}
	switch (params.month) {
		case "short":
			dateString += " " + Months[date.getMonth()].substr(0, 3);
			break;
		case "full":
			dateString += " " + Months[date.getMonth()];
			break;
		default:
			break;
	}
	switch (params.year) {
		case "short":
			dateString += " " + date.getFullYear().toString().substr(2, 4);
			break;
		case "full":
			dateString += " " + date.getFullYear().toString();
			break;
		default:
			break;
	}
	return dateString;
};

export const groupBy = (arr: any[], key: string) => {
	return arr.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

export const convertQuery = (query: Query<any>) => {
	return {
		sort: query.orderBy
			? {
					column_name: String(query.orderBy?.field),
					order: query.orderDirection,
			  }
			: null,
		filter: query.filters.map((filter) => ({
			column_name: String(filter.column.field),
			search_term: filter.value,
		})),
		pagination: {
			page: query.page + 1,
			rows_per_page: query.pageSize,
		},
	};
};
