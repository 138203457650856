import { httpClient } from "../../../globalApi/httpClient.api";

const getLsaStreams = async () => {
	const response = await httpClient.get("/lsa/streams");
	return response.data.data.streams;
};
const getLsaResponsibleScopes = async () => {
	const response = await httpClient.get("/lsa/responsibleScopes");
	return response.data.data.scopes;
};
const getLsaActions = async () => {
	const response = await httpClient.get("/lsa/actions");
	return response.data.data.actions;
};
const getLsaGrouping = async (payload: { stream_id: number }) => {
	const response = await httpClient.post("/lsa/lowScoreGrouping", payload);
	return response.data.data.groups;
};
const getLsaPrimaryTypes = async (payload: {
	stream_id: number;
}): Promise<any[]> => {
	const response = await httpClient.post("/lsa/primaryQueryType", payload);
	return response.data.data.types;
};
const getLsaPrimaryCauses = async (payload: {
	stream_id: number;
	group_id: number;
}) => {
	const response = await httpClient.post(
		"/lsa/primaryLowScoreCause",
		payload
	);
	return response.data.data.causes;
};
const getLsaSecondaryTypes = async (payload: {
	stream_id: number;
	primary_query_id: number;
}) => {
	const response = await httpClient.post("/lsa/secondaryQueryType", payload);
	return response.data.data.types;
};
const getLsaSecondaryCauses = async (payload: {
	stream_id: number;
	primary_cause_id: number;
}) => {
	const response = await httpClient.post(
		"/lsa/secondaryLowScoreCause",
		payload
	);
	return response.data.data.causes;
};
const saveLowScoreAnalysis = async (payload: {
	lsa_id: number;
	landing: boolean;
	stream: { stream_id: string };
	scope: { scope_id: string };
	action: { action_id: string };
	group: { group_id: string };
	primary_type: { primary_query_id: string };
	primary_cause: { primary_cause_id: string };
	secondary_type: { secondary_query_id: string };
	secondary_cause: { secondary_cause_id: string };
}) => {
	const body = {
		lsa_id: payload.lsa_id,
		landing: payload.landing,
		stream: payload.stream.stream_id,
		scope: payload.scope.scope_id,
		action: payload.action.action_id,
		group: payload.group.group_id,
		primary_type: payload.primary_type.primary_query_id,
		primary_cause: payload.primary_cause.primary_cause_id,
		secondary_type: payload.secondary_type.secondary_query_id,
		secondary_cause: payload.secondary_cause.secondary_cause_id,
	};

	const response = await httpClient.post("/lsa/save", body);
	return response.data.data;
};

export {
	getLsaStreams,
	getLsaResponsibleScopes,
	getLsaActions,
	getLsaGrouping,
	getLsaPrimaryTypes,
	getLsaPrimaryCauses,
	getLsaSecondaryTypes,
	getLsaSecondaryCauses,
	saveLowScoreAnalysis,
};
