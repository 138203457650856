import { httpClient } from "../../globalApi/httpClient.api";
import { ActivityData } from "./NewsFeed";

type MiscBody = {
	pagination?: {
		page: number;
		rows_per_page: number;
	};
	sort?: {
		column_name: string;
		order: string;
	};
	filter?: [
		{
			column_name: string;
			search_term: string;
		}
	];
};
const getNewsFeedCategories = async (payload?: {}): Promise<
	{
		label: string;
		_id: any;
		[other: string]: any;
	}[]
> => {
	const data = {
		section: "newsfeed",
	};
	const response = await httpClient.post("/categories", data);
	return response.data.data.categories?.map(
		(category: { id: number; Name: string }) => ({
			_id: category.id,
			label: category.Name,
		})
	);
};
const getNewsFeedItemComments = async (
	payload: { itemId: string } & MiscBody
) => {
	const { itemId, ...misc } = payload;
	const data = {
		newsfeed_item_id: itemId,
		...misc,
	};
	const response = await httpClient.post("/newsfeed/comments", data);
	return response.data.data;
};

const getNewsFeedData = async (
	payload: { category_id: number } & MiscBody
): Promise<ActivityData[]> => {
	const { category_id, ...misc } = payload;
	const data = {
		category_id,
		...misc,
	};
	const response = await httpClient.post("/newsfeed", data);
	return response?.data.data.newsfeed?.map(
		(item: any): ActivityData => ({
			_id: item.id,
			name: item.name,
			profile_img: item.profile_img,
			featured_comment: item.featured_comment,
			activityTierChange: item.tier && {
				label: item.tier.Name,
				colour: item.tier.Colour,
			},
			comments: [],
		})
	);
};

const postNewsFeedComment = async (payload: {
	itemId: any;
	comment: string;
}) => {
	const { itemId, comment } = payload;
	const data = {
		newsfeed_item_id: itemId,
		comment,
	};
	const response = await httpClient.post("/newsfeed/comment/create", data);

	return response;
};

const postNewsFeedItemInteraction = async (payload: {
	body: { itemId: any; interactionId: any };
}) => {
	const { body } = payload;
	const data = {
		newsfeed_item_id: body.itemId,
		interaction_type_id: body.interactionId,
	};
	const response = await httpClient.post("/newsfeed/interaction", data);

	return response;
};

const postNewsFeedCommentInteraction = async (payload: {
	body: { itemId: any; interactionId: any };
}) => {
	const { body } = payload;
	const data = {
		newsfeed_comment_id: body.itemId,
		interaction_type_id: body.interactionId,
	};
	const response = await httpClient.post("/newsfeed/interaction", data);

	return response;
};

export type { MiscBody };
export {
	getNewsFeedCategories,
	getNewsFeedData,
	getNewsFeedItemComments,
	postNewsFeedComment,
	postNewsFeedItemInteraction,
	postNewsFeedCommentInteraction,
};
