import { format } from "date-fns";
import React, { createContext, useContext, useReducer } from "react";
import { UserProfile, useUserState } from "../../globalContext/userObject.ctx";
import { getUserMetrics } from "./Performance.api";
import { t_MetricPerformance } from "./Performance.types";

type t_PerformanceUser = {
	user: UserProfile;
	metrics: t_MetricPerformance[];
};

type t_PerformanceState = {
	viewUser?: t_PerformanceUser;
	dates: {
		start_date: string;
		end_date: string;
	};
};

type t_PerformanceActions =
	| {
			type: "refresh";
	  }
	| {
			type: "view";
			viewUser: t_PerformanceUser;
	  }
	| {
			type: "update_dates";
			dates: {
				start_date: Date;
				end_date: Date;
			};
	  };

const now = new Date(Date.now());
const init_date = {
	dates: {
		start_date: format(
			new Date(now.getFullYear(), now.getMonth(), 1),
			"yyyy/MM/dd"
		),
		end_date: format(
			new Date(now.getFullYear(), now.getMonth() + 1, 0),
			"yyyy/MM/dd"
		),
	},
};

const performanceContext = createContext<
	[t_PerformanceState, React.Dispatch<t_PerformanceActions>]
>([{ ...init_date }, () => {}]);

const usePerformanceContext = () => {
	const context = useContext(performanceContext);
	if (context === undefined) {
		throw "Performance Context must be used withing a provider.";
	}

	return context;
};

const PerformanceContextProvider: React.FC = ({ children }) => {
	const currentUser = useUserState();

	const performanceReducer = (
		state: t_PerformanceState,
		action: t_PerformanceActions
	): t_PerformanceState => {
		switch (action.type) {
			case "refresh":
				if (currentUser.profile !== undefined) {
					getUserMetrics({
						user_id: currentUser.profile?.id,
						comparison_date: state.dates?.start_date,
						effective_date: state.dates?.end_date,
					}).then((metrics) => {
						currentUser.profile &&
							dispatch({
								type: "view",
								viewUser: {
									user: currentUser.profile,
									metrics: metrics as t_MetricPerformance[],
								},
							});
					});
				}
				return {
					...state,
					viewUser: undefined,
				};
			case "view":
				return {
					...state,
					viewUser: action.viewUser,
				};
			case "update_dates":
				return {
					...state,
					dates: {
						start_date: format(
							action.dates.start_date,
							"yyyy/MM/dd"
						),
						end_date: format(action.dates.end_date, "yyyy/MM/dd"),
					},
				};
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(performanceReducer, { ...init_date });

	return (
		<performanceContext.Provider value={[state, dispatch]}>
			{children}
		</performanceContext.Provider>
	);
};

const PerformanceContextConsumer = performanceContext.Consumer;

export {
	PerformanceContextProvider,
	PerformanceContextConsumer,
	usePerformanceContext,
};
