import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useReducer, useState } from "react";
import SectionedDialog from "../../../../components/SectionedDialog/SectionedDialog";
import UserMetricHighlights from "../../../../pages/Home/Performance/UserMetricHighlights/UserMetricHighlights";
import UserMetricRecords from "../../../../pages/Home/Performance/UserMetricRecords/UserMetricRecords";
import { SurveyReports } from "../../../PerceptionReport/PerceptionReport";
import { t_SurveyPeriod, t_SurveyRecord } from "../../../Surveys/Surveys.types";
import MetricSurveyRecords from "../../../Surveys/tables/MetricSurveyRecords/MetricSurveyRecords";
import { actions_MetricSurveyRecords } from "../../../Surveys/tables/MetricSurveyRecords/MetricSurveyRecords.api";
import { usePerformanceContext } from "../../Performance.ctx";
import { t_MetricPerformance } from "../../Performance.types";

type t_DialogState = {
	lsa?: {
		lsa_data_id: number;
		survey_record: t_SurveyRecord;
	};
	appeal?: {
		appeal_data_id: number;
		survey_record: t_SurveyRecord;
	};
};

const dialogDispatcher = (
	state: t_DialogState,
	{ action, rowData }: actions_MetricSurveyRecords
): t_DialogState => {
	if (!rowData) return {};

	switch (action) {
		case "lsa":
			return {
				lsa: {
					lsa_data_id: rowData.LsaDataID,
					survey_record: rowData,
				},
			};
		case "appeal":
			return {
				appeal: {
					appeal_data_id: rowData.AppealDataID,
					survey_record: rowData,
				},
			};
	}
	return {};
};

interface Props {
	metric: t_MetricPerformance;
}

const MetricPerformance: React.FC<Props> = ({ metric }) => {
	const [{ viewUser, dates }] = usePerformanceContext();
	const [dialogState, dialogDispatch] = useReducer(dialogDispatcher, {});
	const [period, setPeriod] = useState<t_SurveyPeriod>();

	useEffect(() => {
		if (dates) {
			setPeriod({
				start_date: /* dates.start_date */ "2000-1-1",
				end_date: dates.end_date,
				appeal_cutoff: dates.end_date,
				last_capture: dates.end_date,
			});
		}
	}, [dates]);

	return (
		<>
			<Grid container spacing={2}>
				{viewUser ? (
					<>
						<Grid item xs={8}>
							{metric.id === 3 || metric.id === 4 ? (
								<MetricSurveyRecords
									survey={
										metric.name.toLowerCase() as SurveyReports
									}
									period={period}
									entity_no={viewUser.user.entity_no}
									dispatch={dialogDispatch}
								/>
							) : (
								<UserMetricRecords viewMetric={metric} />
							)}
						</Grid>
						<Grid item xs={4}>
							<UserMetricHighlights
								viewMetric={metric}
								comparison={"Day"}
								selectedDate={new Date(Date.now())}
							/>
						</Grid>
					</>
				) : (
					<>
						<Grid item sm={6}>
							<Skeleton width={"100%"} height={300} />
						</Grid>
						<Grid item sm={6}>
							<Skeleton width={"100%"} height={300} />
						</Grid>
					</>
				)}
			</Grid>
			<SectionedDialog
				open={Boolean(dialogState.lsa || dialogState.appeal)}
				onClose={() => dialogDispatch({})}
			>
				<Grid container spacing={2}>
					<Grid item sm={6}></Grid>
					{/* Confirmation dialog for appeal / lsa */}

					{/* View appeal / lsa */}
				</Grid>
			</SectionedDialog>
		</>
	);
};

export default MetricPerformance;
