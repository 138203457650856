import { SettingsOutlined } from "@material-ui/icons";
import React from "react";
import { UserRolesContext } from "../../../authentication/RoleProvider/RoleProvider";
import { NavItem } from "../../NavItem/NavItem";

const SettingsRoutes: React.FC<{ open: boolean }> = ({ open, children }) => {
	return (
		<>
			<NavItem
				open={open}
				label='Admin Settings'
				icon={<SettingsOutlined />}
				route='/admin'
			>
				<NavItem
					open={true}
					label='Maintain User'
					route='/admin/user'
				/>
				<UserRolesContext.Consumer>
					{({ survey_consultant }) =>
						survey_consultant && (
							<NavItem
								open={true}
								label='Maintain Incentive'
								route='/admin/incentive'
							/>
						)
					}
				</UserRolesContext.Consumer>
				<NavItem
					open={true}
					label='Maintain Appeals'
					route='/admin/appeals'
				/>
			</NavItem>
		</>
	);
};

export default SettingsRoutes;
