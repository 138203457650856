import { createStyles, makeStyles, Theme } from "@material-ui/core";

const lsaRecordStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			width: 300,
		},
	})
);

export default lsaRecordStyles;
