import {
	Badge,
	createStyles,
	Dialog,
	Divider,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	TableCell,
	TableRow,
	Theme,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import DataTable from "../../../../components/DataTable/DataTable";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import AppealDialogContent from "../../../../sections/AppealDialogContent/AppealDialogContent";
import LsaDialogContent from "../../../../sections/LsaDialogContent/LsaDialogContent";
import { getMetricDetails } from "../../../../sections/Performance/Performance.api";
import { MetricType } from "../Performance";
import { ActionColumn, MetricColumns } from "./UserMetricRecords.api";

const menuStyles = makeStyles((theme: Theme) =>
	createStyles({
		badge: {
			backgroundColor: theme.palette.info.main,
		},
		warningSpan: {
			backgroundColor: "#d09a01",
		},
	})
);
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		lsaDialog: {
			background: "none",
		},
	})
);
interface UserMetricRecordsProps {
	viewMetric: MetricType;
}
interface RecordMenuProps {
	lsa?: {
		hasLSA: boolean;
		lsa_data_id?: number;
		status_id: number;
	};
	handleLsaOpen: () => void;
	appeal: any;
	handleAppealOpen: () => void;
}
const RecordMenu: React.FC<RecordMenuProps> = ({
	lsa,
	handleLsaOpen,
	appeal,
	handleAppealOpen,
}) => {
	const classes = menuStyles();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		console.log(lsa);
	};

	const handleClose = () => {
		setAnchorEl(null);
		return true;
	};
	return (
		<div>
			<IconButton
				aria-label='more'
				aria-controls='long-menu'
				aria-haspopup='true'
				onClick={handleClick}
				disabled={!lsa?.hasLSA && !appeal}
			>
				<Badge
					classes={{
						badge: classes.badge,
					}}
					badgeContent={
						(lsa?.hasLSA && (!lsa.lsa_data_id || lsa.status_id == 2)
							? 1
							: 0) +
						(appeal && appeal.status == 1 && !appeal.hasExpired
							? 1
							: 0)
					}
				>
					<MoreVert />
				</Badge>
			</IconButton>
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{lsa?.hasLSA && (
					<MenuItem onClick={() => handleClose() && handleLsaOpen()}>
						LSA
						<Divider orientation='vertical' />
						{!lsa.lsa_data_id
							? "Not Completed"
							: (
									<>
										{lsa.status_id === 2 && (
											<span
												className={classes.warningSpan}
											>
												Partially Completed
											</span>
										)}
										{lsa.status_id === 3 && "Completed"}
									</>
							  ) ?? "Error"}
					</MenuItem>
				)}
				{appeal && (
					<MenuItem
						onClick={() => handleClose() && handleAppealOpen()}
					>
						Appeal
						<Divider orientation='vertical' />
						{appeal.status_id == 1
							? appeal.hasExpired
								? "Expired"
								: "Pending Approval"
							: (
									<>
										{appeal.status_id === 2 && "Approved"}
										{appeal.status_id === 3 && "Declined"}
									</>
							  ) ?? "TODO: limit by metric survey date"}
					</MenuItem>
				)}
			</Menu>
		</div>
	);
};
type MetricRecord = {
	call_date_answered: string;
	call_id: number;
	response_id: number;
	survey_date: string;
	survey_score: number;
};
const UserMetricRecords: React.FC<UserMetricRecordsProps> = ({
	viewMetric,
}) => {
	const classes = useStyles();
	const [records, setRecords] = useState<any[]>([]);
	const [dialogState, dialogSetState] = useState<{
		lsa: any;
		appeal: any;
		record: any;
		open: boolean;
	}>({
		open: false,
		lsa: null,
		appeal: null,
		record: null,
	});
	const [loading, setLoading] = useState<boolean>(true);

	const metricColumns = MetricColumns[viewMetric.id] || [];
	const metricActions = viewMetric.id === 3 || viewMetric.id === 4;

	const handleLsaSelected = (record: any) => {
		const lsa = record["low_score_analysis"];
		dialogSetState({
			appeal: null,
			lsa,
			record,
			open: true,
		});
	};

	const handleAppealSelected = (record: any) => {
		console.log({ record });
		const appeal = {
			...record["appeal"],
			metric_id: viewMetric.id,
		};
		dialogSetState({
			appeal,
			lsa: null,
			record,
			open: true,
		});
	};

	const handleDialogClose = () => {
		dialogSetState({
			open: false,
			lsa: null,
			appeal: null,
			record: null,
		});
	};

	useEffect(() => {
		let isActive = true;

		if (viewMetric !== null) {
			setLoading(true);
			setRecords([]);
			getMetricDetails({
				metricId: viewMetric.id,
				pagination: {
					page: 0,
					rows_per_page: 5,
				},
			})
				.then((data) => {
					if (isActive) setRecords(data);
					console.log({ data });
				})
				.catch((e) => console.error(e))
				.finally(() => setLoading(false));
		}
		return () => {
			isActive = false;
		};
	}, [viewMetric]);
	return (
		<SectionCard
			title={`${viewMetric?.name} Records`}
			titleProps={{
				variant: "h6",
			}}
			fullWidth
		>
			<DataTable
				columns={
					metricActions
						? metricColumns.concat(ActionColumn)
						: metricColumns
				}
			>
				{loading ? (
					<TableRow>
						<TableCell
							colSpan={
								metricColumns.length + (metricActions ? 1 : 0)
							}
						>
							<Skeleton
								animation='wave'
								height={10}
								width='80%'
								style={{ marginBottom: 6 }}
							/>
						</TableCell>
					</TableRow>
				) : records && records.length > 0 ? (
					records.map((record: any, index: number) => (
						<TableRow key={`record_${index}_${viewMetric.id}`}>
							{metricColumns.map((column) => (
								<TableCell
									key={`${column.name}_${index}_${viewMetric.id}`}
								>
									{record[column.name]}
								</TableCell>
							))}
							{metricActions && (
								<TableCell
									key={`action_${index}_${viewMetric.id}`}
								>
									<RecordMenu
										lsa={record["low_score_analysis"]}
										appeal={record["appeal"]}
										handleLsaOpen={() =>
											handleLsaSelected(record)
										}
										handleAppealOpen={() =>
											handleAppealSelected(record)
										}
									/>
								</TableCell>
							)}
						</TableRow>
					))
				) : (
					<TableRow>
						<TableCell colSpan={3}>No records found.</TableCell>
					</TableRow>
				)}
			</DataTable>
			<Dialog
				open={dialogState.open}
				onClose={handleDialogClose}
				aria-labelledby='form-dialog-title'
				fullWidth
				maxWidth='md'
				classes={{
					paper: classes.lsaDialog,
				}}
			>
				{dialogState.lsa && (
					<LsaDialogContent
						lsa={dialogState.lsa}
						record={dialogState.record}
						viewMetric={viewMetric}
						onClose={() =>
							dialogSetState((state) => ({
								...state,
								open: false,
							}))
						}
					/>
				)}
				{dialogState.appeal && (
					<AppealDialogContent
						viewMetric={viewMetric}
						appeal={dialogState.appeal}
						record={dialogState.record}
						onClose={() =>
							dialogSetState((state) => ({
								...state,
								open: false,
							}))
						}
					/>
				)}
			</Dialog>
		</SectionCard>
	);
};

export type { MetricRecord };
export default UserMetricRecords;
