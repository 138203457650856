import React, { createRef, useEffect } from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import { UserProfile } from "../../../../globalContext/userObject.ctx";
import { SurveyReports } from "../../../PerceptionReport/PerceptionReport";
import AppealButton from "../../components/AppealButton/AppealButton";
import LsaButton from "../../components/LsaButton/LsaButton";
import { getSurveyRecords, SurveyColumns } from "../../Surveys.api";
import { t_SurveyPeriod } from "../../Surveys.types";
import { actions_MetricSurveyRecords } from "./MetricSurveyRecords.api";

interface Props {
	survey: SurveyReports;
	period?: t_SurveyPeriod;
	entity_no: UserProfile["entity_no"];
	dispatch: React.Dispatch<actions_MetricSurveyRecords>;
}
const MetricSurveyRecords: React.FC<Props> = ({
	survey,
	period,
	entity_no,
	dispatch,
}) => {
	const tableRef = createRef<any>();
	useEffect(() => {
		tableRef.current && tableRef.current.onQueryChange();
	}, [survey, entity_no, period]);
	return (
		<BUMaterialTable
			tableRef={tableRef}
			columns={SurveyColumns}
			isLoading={!period}
			data={(query) =>
				getSurveyRecords({
					query,
					report: survey,
					entity_no,
					period,
				})
			}
			localization={{
				header: {
					actions: "Details",
				},
			}}
			options={{
				toolbar: false,
				padding: "dense",
				pageSize: 10,
				actionsColumnIndex: -1,
			}}
			actions={[
				(rowData) => ({
					icon: () => (
						<LsaButton lsa_status={rowData["lsa.StatusID"]} />
					),
					onClick: () => dispatch({ action: "lsa", rowData }),
					tooltip:
						rowData["lsa.StatusText"] ?? "No low score analysis.",
					disabled: rowData["lsa.StatusID"] === null,
				}),
				(rowData) => ({
					icon: () => (
						<AppealButton
							appeal_status={rowData["appeal.StatusID"]}
						/>
					),
					onClick: () => dispatch({ action: "appeal", rowData }),
					tooltip: rowData["appeal.StatusText"] ?? "No appeal.",
					disabled: rowData["appeal.StatusID"] === null,
				}),
			]}
		/>
	);
};

export default MetricSurveyRecords;
