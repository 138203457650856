import { List, ListItem, TextField } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { getAppeal } from "../../Appeals.api";
import { t_AppealDetailedRecord, t_AppealRecord } from "../../Appeals.types";
import appealRecordStyles from "./AppealRecord.style";

interface Props {
	appeal_data_id: t_AppealRecord["AppealDataID"];
}

const AppealRecord: React.FC<Props> = ({ appeal_data_id }) => {
	const classes = appealRecordStyles();
	const [appeal, setAppeal] = useState<t_AppealDetailedRecord>();

	useEffect(() => {
		var isActive = true;
		getAppeal({ appeal_data_id }).then((data) => {
			isActive && setAppeal(data);
		});

		return () => {
			isActive = false;
		};
	}, [appeal_data_id]);

	return (
		<List>
			{appeal ? (
				<>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Appeal ID'
							value={appeal.appeal_id}
						/>
						<TextField
							className={classes.textField}
							label='Appeal Date'
							value={appeal.appeal_date}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Appealed By'
							value={appeal.appealed_by?.name}
						/>
						<TextField
							className={classes.textField}
							label='Appeal Category'
							value={appeal.category?.name}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Removal Level'
							value={appeal.level.name}
						/>
						<TextField
							className={classes.textField}
							label='Transfer To'
							value={appeal.transfer_to}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Decision Date'
							value={appeal.decision_date}
						/>
						<TextField
							className={classes.textField}
							label='Decision By'
							value={appeal.decision_by?.name}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Decision Comment'
							value={appeal.decision_comment}
						/>
						<TextField
							className={classes.textField}
							label='Response ID'
							value={appeal.response_id}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField
							className={classes.textField}
							label='Cost Center'
							value={appeal.cost_center?.name}
						/>
						<TextField
							className={classes.textField}
							label='Expiry Date'
							value={appeal.expire_date}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<TextField label='Comment' value={appeal.comment} />
					</ListItem>
				</>
			) : (
				<>
					<ListItem>
						<Skeleton width={154} height={60} />
						<Skeleton width={154} height={60} />
					</ListItem>
					<ListItem>
						<Skeleton width={154} height={60} />
						<Skeleton width={154} height={60} />
					</ListItem>
					<ListItem>
						<Skeleton width={154} height={60} />
						<Skeleton width={154} height={60} />
					</ListItem>
					<ListItem>
						<Skeleton width={154} height={60} />
						<Skeleton width={154} height={60} />
					</ListItem>
					<ListItem>
						<Skeleton width={154} height={60} />
						<Skeleton width={154} height={60} />
					</ListItem>
				</>
			)}
		</List>
	);
};

export default AppealRecord;
