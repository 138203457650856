import { httpClient } from "../../globalApi/httpClient.api";
import { MiscBody } from "../NewsFeed/newsFeed.api";

const TABLE_COLUMNS = [
	// {
	// 	label: "Points",
	// 	name: "Points",
	// },
	// {
	// 	label: "Tier",
	// 	name: "Tier",
	// },
	{
		label: "AHT",
		name: "AHT",
	},
	{
		label: "DPMO",
		name: "DPMO",
	},
	{
		label: "FCR",
		name: "FCR",
	},
	{
		label: "MBR",
		name: "MBR",
	},
	{
		label: "Production",
		name: "Production",
	},
	{
		label: "Attendance",
		name: "Attendance",
	},
	{
		label: "Engagement",
		name: "Engagement",
	},
];

type HistoricData = {
	effective_date?: string;
	comparison_date?: string;
};
const getMetricDetails = async (
	payload: { metricId: number } & HistoricData & MiscBody
) => {
	const { metricId, ...misc } = payload;
	const body = {
		metric_id: metricId,
		...misc,
	};

	const response = await httpClient.post("/metrics/details", body);
	return response.data.data.records;
};

const getUserMetrics = async (
	payload: { user_id: number } & HistoricData & MiscBody
) => {
	const response = await httpClient.post("/metrics/summary/user", payload);
	const metrics = response.data.data.metric_values;
	var result: { [key: string]: any } = {};
	for (var i = 0; i < metrics.length; i++)
		result[metrics[i].name] = metrics[i];
	return result;
};

export { getMetricDetails, getUserMetrics, TABLE_COLUMNS };
