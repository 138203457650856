import { httpClient } from "../globalApi/httpClient.api";
import { DetailedChallenge } from "../sections/CurrentChallenge/CurrentChallenge";
import { ActivityComment, ActivityData } from "../sections/NewsFeed/NewsFeed";
import { UserDetails } from "../sections/ProfileBio/ProfileBio";
import {
    ActivityCommentsData,
    ChallengeHistoryData,
    CurrentChallengeData,
    LeaderBoardData,
    LeaderBoardTabs,
    OfficePointsData,
    PerformanceData,
    ReportDateOptions,
    ReportGraphData,
    ReportItems,
    ReportTableData,
    UserProfileAttributesData,
    UserProfileBadgesData,
    UserProfileData
} from "./TestData";

// @MM Hardcoded token for demo purposes;
const ACCESS_TOKEN =
	"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzI1NzQyMDcxZDcxNGFmMTk0YThkMDIyNmJlYzM1ZjU5NWE3OGRlYTc5ZDE0MTg5N2IxNTMwMmRmNGIzY2JlYTIxZTFjZjMyOWRiOWUxNjIiLCJpYXQiOjE2MTE5MDQxNTksIm5iZiI6MTYxMTkwNDE1OSwiZXhwIjoxNjQzNDQwMTU5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.HC0SRpR9r2LvHegmfkz6wxElDiTJ3oE3O8igAsVpuN_vjGe7sAUzZ4Amw1Av_iLJoEW9Gdl1FdtkjT7y-qdPxwyZEBZbUELp9OdK6j36OL3_jWlNjSVJ80Q5V-MhjpUmapQx4UdBMMQCbsfsv4fZczMt-I5Jn0neHpGSYSnbCrB6htX0J4zPZXJQW71tgpUMlX5Ktsf4TZEm7eJC3b9_P9woJ0Jot5eugCR1VAcV7aZ54lTk7-9q_AHFKixMC7UVVQOQ8y643L3PSL0h9pvLCZoJnZKqcqdiFtHut7aEOjvTVMHzmLvfwQWZ817C0kmGaCavnpckyJ6JGHX8SfcJnuV6-a1UUTV_f9vvT1NBQnz5-3NMn3WzIHAM2lWAU-ZL_UEEjz8CBfJm6CU99-hCqUjSh_xQ1nIGLcdi0Jxt4okZjVXvqCNExXFHzIG39rszntcnSzYR5tpxyAcq5f5GBLiUv30l9jwgumwXXxlwowmFAW4G8Clj1euUZgc1El4HqFp25qFQwdBWSTMpD81zsRBysNBL41jj0dWQMxCcXei0J_IsD981Yh0GPdX_fWBAz2UlLajbyAvq1AjvgQTwkGV_1KWUSNK6t2W9XY4XI-sm8vkrRfGEvRNsQPyBlRqaAa4LqojCOuOUacNIjmNfTFSCQ15ggWqWijvUFUVgCeM";
const API_URL = "https://api.discovery.fusebox-staging.co.za/api";
const API_VERSION = "v1";
const requestOptions = {
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
		Authorization: `Bearer ${ACCESS_TOKEN}`,
	},
};
export const leaderBoardEndpoints = {
	getLeaderBoardCategories: (): Promise<
		{ label: string; _id: any; [other: string]: any }[]
	> => {
		return Promise.resolve(LeaderBoardTabs);
	},
	getLeaderBoardData: (category: string): Promise<any[]> => {
		return Promise.resolve(LeaderBoardData);
	},
};
export const activityEndpoints = {
	getNewsFeedCategories: async (): Promise<
		{
			label: string;
			_id: any;
			[other: string]: any;
		}[]
	> => {
		const options = {
			section: "newsfeed",
		};
		const response = await httpClient.post(
			`${API_URL}/${API_VERSION}/categories`,
			options,
			requestOptions
		);
		return response.data.data.categories?.map(
			(category: { id: number; Name: string }) => ({
				_id: category.id,
				label: category.Name,
			})
		);
	},
	/*
        "id": 1,
        "name": "Dom Vigliotti",
        "profile_img": "http://api.discovery.fusebox-staging.co.za/storage/profile_pictures/defualt_profile.png",
        "type": "tier",
        "tier": {
            "Name": "Bronze",
            "Colour": "B08D57"
        },
        "like_count": 1,
        "comment_count": 1,
        "featured_comment": {
            "id": 1,
            "name": "Skanyiso Fanie",
            "profile_img": "http://api.discovery.fusebox-staging.co.za/storage/profile_pictures/defualt_profile.png",
            "comment": "This is a sample comment",
            "created_at": "2021-01-28T14:28:38.030000Z",
            "like_count": 1
        }
    */
	getNewsFeedData: async (category: number): Promise<ActivityData[]> => {
		const options = {
			category_id: category,
		};
		const response = await httpClient.post(
			`${API_URL}/${API_VERSION}/newsfeed`,
			options,
			requestOptions
		);
		return response?.data.data.newsfeed?.map((item: any) => ({
			_id: item.id,
			name: item.name,
			profile_img: item.profile_img,
			timestamp: null,
			activityTierChange: item.tier && {
				label: item.tier.Name,
				colour: item.tier.Colour,
			},
			activityNewBadge: null,
			activityChallenge: null,
			comments: item.featured_comment && [
				{
					...item.featured_comment,
				},
			],
		}));
	},
	getActivityComments: (
		activityIds: any[]
	): Promise<{ _id: any; comments: ActivityComment[] }[]> => {
		return Promise.resolve(ActivityCommentsData);
	},
};
export const reportEndpoints = {
	getReportCategories: (
		reportId: string
	): Promise<{ _id: any; label: string }[]> => {
		return Promise.resolve([]);
	},
	getReports: (): Promise<{ title: string; [attr: string]: any }[]> => {
		return Promise.resolve(ReportItems);
	},
	getReportChartDateOptions: (
		reportId: string
	): Promise<{ timestamp: number }[]> => {
		return Promise.resolve(ReportDateOptions);
	},
	getReportChartGraphData: (
		reportId: string
	): Promise<{ category: string; value: number }[]> => {
		return Promise.resolve(ReportGraphData);
	},
	getReportTableData: (
		reportId: string
	): Promise<
		{
			_id: number;
			type: string;
			percentage: number;
			volume: number;
		}[]
	> => {
		return Promise.resolve(ReportTableData);
	},
	getPerOfficePoints: (): Promise<
		{
			_id: string;
			label: string;
			lat: number;
			lng: number;
			data: {
				_id: string;
				acronym: string;
				label: string;
				value: number;
			}[];
		}[]
	> => {
		return Promise.resolve(OfficePointsData);
	},
	getPerformanceOverTime: (): Promise<
		{ products_sold: number; total_views: number; timestamp: number }[]
	> => {
		return Promise.resolve(PerformanceData);
	},
};

export const performanceEndpoints = {
	// getTeamSummaryData: (): Promise<PerformanceSummaryData[]> => {
	// 	return Promise.resolve(TeamSummaryPerformance);
	// },
};

export const userEndpoints = {
	getUserProfileBasic: (): Promise<UserDetails> => {
		return Promise.resolve(UserProfileData);
	},
	getUserProfileTrendStats: (): Promise<
		{ products_sold: number; total_views: number; timestamp: number }[]
	> => {
		return Promise.resolve(PerformanceData);
	},
	getUserProfileAttributes: (): Promise<{
		total: number;
		values: { label: string; value: number }[];
	}> => {
		return Promise.resolve(UserProfileAttributesData);
	},
	getUserBadges: (): Promise<
		{ badge_img: string; name: string; description: string }[]
	> => {
		return Promise.resolve(UserProfileBadgesData);
	},
};

export const challengeEndpoints = {
	getCurrentChallenge: (): Promise<DetailedChallenge> => {
		return Promise.resolve(CurrentChallengeData);
	},
	getChallengeHistory: () => {
		return Promise.resolve(ChallengeHistoryData);
	},
};
